import React from 'react';
import { connect } from 'formik';

class ErrorFocus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showError: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.formik.errors === nextProps.formik.errors) return true;

    return false;
  }

  componentDidUpdate(prevProps) {
    const { isSubmitting, isValidating, errors } = prevProps.formik;
    const keys = Object.keys(errors);
    if (keys.length > 0 && isSubmitting && !isValidating) {
      const selector = `[id="${keys[0]}"]`;
      const errorElement = document.querySelector(selector);
      if (errorElement && errors[keys[0]] && errors[keys[0]].length > 0) {
        errorElement.focus();
      }
    }
  }

  render() {
    return null;
  }
}

export default connect(ErrorFocus);
