/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import './LoginScreen.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircle, faEye, faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
//import ShowAlert from '../../components/ShowAlert';
import { updateForgotPassword } from '../../api/authenticationAPI';
import { encryptPasswordString } from '../../utils/UtilityFunctions';
// import { BASE_PATH } from '../../app/constants';

const atLeasetDigit = new RegExp(/(?=.*\d)[\S]{1,}$/);
const atLeasetUpperCase = new RegExp(/(?=.*[A-Z])[\S]{1,}$/);
const atLeasetLowerCase = new RegExp(/(?=.*[a-z])[\S]{1,}$/);
// const atLeasetSpecial = new RegExp(/(?=.*[@$!%*?&])[\S]{1,}$/);
const atLeasetEight = new RegExp(/[\S]{8,}$/);
const fullPasswordRegex = new RegExp(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\S]{8,}$/);

const ResetPass = (props) => {

  const [loading, setLoading] = useState(false);
  //const [error, setError] = useState(null);
  //const [success, setSuccess] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordTooltip, setShowPasswordTooltip] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [formErrors, setFormErrors] = useState({});

  const {
    userId, mobileNo, otpVerificationId, handleProceed, setError, setSuccess
  } = props;

  const updatePassword = async (e) => {
    e.preventDefault();
    const formErr = {};
    if (!fullPasswordRegex.test(password)) {
      formErr.password = 'Invalid Password';
    }
    if (password !== confirmPassword) {
      formErr.confirmPassword = 'Passwords must match';
    }

    setFormErrors(formErr);

    if (Object.keys(formErr).length > 0) return;

    setLoading(true);
    const data = {
      otp_verification_id: otpVerificationId,
      user_id: userId,
      country_code: '+91',
      mobile_no: mobileNo,
      password: encryptPasswordString(password),
      password_confirmation: encryptPasswordString(confirmPassword),
    };
    try {
      const result = await updateForgotPassword(data);
      setSuccess(result.message);
      localStorage.removeItem('address_available'); //remove old value if exist
      if(result?.data?.address_available === false) {
        localStorage.setItem('address_available', false);
      } 
      handleProceed(result);
    } catch (err) {
      setError(err.message);
      console.error('Error in updating password: ', err);
    }
    setLoading(false);
  };

  return (
    <div>
      {/*= =start container== */}
      {/* <ShowAlert
        error={error}
        success={success}
        onClose={() => { setError(null); setSuccess(null); }}
      /> */}
      <div className="container clear-padd ma-0 position-center ng-scope bgclretds mt-5">

        <div className="col-xs-2 col-sm-2 col-sm-2 login-empty-container" />
        <div className="col-xs-8 col-lg-8 col-md-12 col-sm-12 login-container mt-28 vertical-center">
          <div className="row content content-sm justify-content-center">            
            <div>
              <div className="col-sm-12 col-xs-12 col-sm-12 col-md-12 removePadding formDiv">
              <h3 className="bold col-md-offset-4 form-heading pt-35">Forgot Password</h3>
                <form className="form-horizontal logforms" onSubmit={(e) => updatePassword(e)} autoComplete="off">
                  <div className="form-group">
                    <div className="col-xs-8 col-sm-12 col-md-12">
                      <p>Please enter your new Password</p>

                    </div>

                  </div>
                  <div className="form-group form-group2">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                      {showPassword ? (
                        <div className="input-group mb-3">
                          <input
                            type="input"
                            className="form-control lg-input inputplace inputplace1"
                            onFocus={() => setShowPasswordTooltip(true)}
                            onBlur={() => setShowPasswordTooltip(false)}
                            name="password"
                            placeholder="Enter Password"
                            required=""
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <div className="input-group-prepend eyeipbox" onClick={() => setShowPassword(false)} style={{ cursor: 'pointer' }}>
                            <FontAwesomeIcon icon={faEyeSlash} />
                          </div>
                        </div>
                      ) : (
                        <div className="input-group mb-3">
                          <input
                            type="password"
                            className="form-control lg-input inputplace inputplace1"
                            onFocus={() => setShowPasswordTooltip(true)}
                            onBlur={() => setShowPasswordTooltip(false)}
                            name="password"
                            placeholder="Enter Password"
                            required=""
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            autoComplete="new-password"
                          />
                          <div className="input-group-prepend eyeipbox" onClick={() => setShowPassword(true)} style={{ cursor: 'pointer' }}>
                            <FontAwesomeIcon icon={faEye} />
                          </div>
                        </div>
                      )}

                      {showPasswordTooltip && (
                      <div className="toolbox">
                        <div className="passlist">
                          <p className="passmust">Password Must</p>
                          <span>
                            <span className="passlisticon">
                              <FontAwesomeIcon
                                icon={faCircle}
                                color={atLeasetDigit.test(password) ? 'green' : ''}
                              />
                            </span>
                            Have One Number
                          </span>
                          <br />
                          <span>
                            <span className="passlisticon">
                              <FontAwesomeIcon
                                icon={faCircle}
                                color={atLeasetUpperCase.test(password) ? 'green' : ''}
                              />
                            </span>
                            Have One Uppercase Character
                          </span>
                          <br />
                          <span>
                            <span className="passlisticon">
                              <FontAwesomeIcon
                                icon={faCircle}
                                color={atLeasetLowerCase.test(password) ? 'green' : ''}
                              />
                            </span>
                            Have One Lowercase Character
                          </span>
                          {/* <br />
                          <span>
                            <span className="passlisticon">
                              <FontAwesomeIcon
                                icon={faCircle}
                                color={atLeasetSpecial.test(password) ? 'green' : ''}
                              />
                            </span>
                            Have One Special Character
                          </span> */}
                          <span>
                            <br />
                            <span className="passlisticon">
                              <FontAwesomeIcon
                                icon={faCircle}
                                color={atLeasetEight.test(password) ? 'green' : ''}
                              />
                            </span>
                            Have 8 Characters minimum
                          </span>

                        </div>
                      </div>
                      )}

                    </div>
                    {formErrors.password ? <span className="error_message">{formErrors.password}</span> : null}
                  </div>
                  <div className="form-group form-group2">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                      <div className="input-group mb-3">
                        <input
                          type="password"
                          className="form-control lg-input inputplace inputplace1"
                          name="password"
                          placeholder="Enter Confirm Password"
                          required=""
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          autoComplete="new-password"
                        />
                        {/* <div className="input-group-prepend eyeipbox">
                                <FontAwesomeIcon icon={faEye} />
                            </div> */}
                      </div>
                    </div>
                    {formErrors.confirmPassword ? <span className="error_message">{formErrors.confirmPassword}</span> : null}
                  </div>
                  <div className="form-group form-group2">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                      <Button
                        type="submit"
                        bsPrefix="form-control btn red-btn semi-bold btnFont"
                        name="Sign Up"
                        disabled={loading}
                      >
                        {loading ? (
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            {' '}
                            Loading...
                          </>
                        ) : 'Update Password'}
                      </Button>

                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-2 login-empty-container" />

      </div>

      {/*= =end container== */}
    </div>

  );
};

export default ResetPass;
