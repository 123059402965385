/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import mobile from '../../images/loginicon/mobile.svg';
import './LoginScreen.css';
//import ShowAlert from '../../components/ShowAlert';
import { verifyOTP } from '../../api/authenticationAPI';

const Verify = (props) => {
  const {
    mobile_no, otp_verification_id, handleProceed, resendOTP, setError
  } = props;
  const [otp, setOTP] = useState('');
  const [loading, setLoading] = useState(false);
  //const [error, setError] = useState('');
  //const [success, setSuccess] = useState('');

  const [timer, setTimer] = useState(120);

  const otpVerification = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      country_code: '+91',
      mobile_no_or_email:mobile_no,
      otp_verification_id,
      otp,
    };

    try {
      const result = await verifyOTP(data);
      handleProceed(result.data.otp_verification_id);
    } catch (err) {
      setError(err.message);
      console.error('Error in login: ', err);
    }
    setLoading(false);
  };

  const startTimer = () => {
    setTimer(120);
    let newTimer = 120;
    setInterval(() => {
      if (newTimer > 0) {
        newTimer -= 1;
        setTimer(newTimer);
      }
    }, 1000);
  };

  const otpResent = async (e) => {
    setLoading(true);
    await resendOTP(e);
    setLoading(false);
  };

  useEffect(() => {
    startTimer();
  }, [otp_verification_id]);

  return (
    <div>

      <div className="container clear-padd ma-0 position-center ng-scope bgclretds mt-5">

        <div className="col-xs-2 col-sm-2 col-sm-2 login-empty-container" />
        <div className="col-xs-8 col-lg-8 col-md-12 col-sm-12 login-container mt-28 vertical-center">
          <div className="row content content-sm justify-content-center">
            <h3 className="bold col-md-offset-4 form-heading pt-35">Verify your Mobile Number</h3>
            <div>
              <div className="col-sm-12 col-xs-12 col-sm-12 col-md-12 removePadding formDiv">

                <form className="form-horizontal logforms" onSubmit={(e) => otpVerification(e)} autoComplete="off">
                  <div className="form-group  ">
                    <div className=" center" style={{ display: 'flex', justifyContent: 'center' }}>
                      <span className="imgmobile">
                        <img className="" src={mobile} alt="mobile" width="80" height="80" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group  p-3top">
                    <div className="  center verpadd ">
                      <h6>An OTP (valid for next 2 mins.) has been sent to you on your Mobile number</h6>
                    </div>

                  </div>
                  <div className="form-group form-group2 p-3top">
                    <div className=" center ">
                      <h4>
                        +91 −
                        {mobile_no}
                      </h4>
                      <p><br /></p>
                    </div>
                  </div>
                  <div className="form-group form-group2">
                    <div className="col-xs-10 col-sm-10 col-md-10 col-md-offset-3">
                      <input
                        type="number"
                        className="form-control lg-input inputplace inputplace1 ng-pristine ng-empty ng-valid-email ng-invalid ng-invalid-required ng-valid-pattern ng-valid-maxlength ng-touched"
                        name="OTP"
                        placeholder="Enter OTP"
                        value={otp}
                        onChange={(e) => setOTP(e.target.value)}
                      />
                      {/* <ShowAlert
                        error={error}
                        success={success}
                        onClose={() => { setError(null); setSuccess(null); }}
                      /> */}
                    </div>
                  </div>
                  <div className="form-group mb-0 pb-0">
                    <div className="  center boxleftright ">
                      <div className="col-xs-12 col-sm-12 col-md-12">
                        <Button
                          type="submit"
                          bsPrefix="form-control btn red-btn semi-bold btnFont"
                          name="Forgot"
                          disabled={loading || !otp}
                        >
                          {loading ? (
                            <>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                              {' '}
                              Loading...
                            </>
                          ) : 'VERIFY OTP'}
                        </Button>
                        <div className=" p-3 col-md-12">
                          <span className="">
                          {
                            timer > 0 
                            ? <span className='circle'>{timer}</span>
                            : <Button variant="link" className=" " onClick={(e) => otpResent(e)} disabled={(timer > 0)}>Resend OTP</Button>
                          } 
                            {/* {' '}
                            <Button variant="link" className=" " onClick={(e) => otpResent(e)} disabled={(timer > 0)}>Resend OTP</Button>
                            {' '} */}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-2 login-empty-container" />

      </div>
    </div>

  );
};

export default Verify;
