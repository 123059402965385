import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { updateMatchRemarksV2 } from '../../../api/invoiceAPI';
import WindowWrapperFixWidth from '../../gstr9/sales/reports/WindowWrapperFixWidth';
import '../../2AReco/V2/invoiceReco/InvoiceReco.css';
import MultiInvoiceDetalPopupBooks from './MultiInvoiceDetalPopupBooks';
import MultiInvoiceDetalPopupGstr2A2B from './MultiInvoiceDetalPopupGstr2A2B';
import ShowAlert from '../../../components/ShowAlert';
import { throttle } from '../../../utils/UtilityFunctions';
import { decodeBase64 } from "../../../utils/UtilityFunctions";
import { selectHeaderData } from "../../header/headerSlice";


export default function ModelForMultiLinking(props) {
    const { onHide, invoiceList, type, gstrKey, books, portal, tabel_col, doc_type } = props;

    const headerData = useSelector(selectHeaderData);
    const { mobile_number, gst_number } = headerData;

    const location = useLocation();
    const params = queryString.parse(location.search);

    const [recoId, setRecoId] = useState('');
    const [invoiceData, setInvoiceData] = useState({});
    const [userComment, setUserComment] = useState('');
    const [initialComment, setInitialComment] = useState(''); // Added to track the initial comment
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [validationError, setValidationError] = useState('');
    const [matchStatus, setMatchStatus] = useState('');
    const [matchStatusColor, setMatchStatusColor] = useState('');
    const [modelName, setModelName] = useState("");

    useEffect(() => {
        const listData = invoiceList[0] ?? {};
        setInvoiceData(listData);
        setUserComment(listData.matchRemark ?? '');
        setInitialComment(listData.matchRemark ?? ''); // Track the initial comment for comparison
        setRecoId(listData?.reco_id);
        setMatchStatusText(listData?.matchStatus);
        setMatchStatusColorCode(listData?.matchStatus);
        //eslint-disable-next-line
    }, [invoiceList]);

    const saveUserComment = async (action) => {
        if (userComment.trim().length < 3) {
            setValidationError('Remark must be at least 3 characters long.');
            return;
        }
        setValidationError('');

        if (userComment === invoiceData.matchRemark) {
            //setValidationError('This remark has already been saved!');
            setValidationError('');
            return;
        } else {
            const response = await matchRemarksSave(action);

            if (response && response.success) {
                setSuccess(response.message);
                setInitialComment(userComment); // Update initial comment on successful save
            } else {
                setError(response.message);
            }
        }
    };

    const setMatchStatusText = (match_status) => {
        switch (match_status) {
            case 'PARTIAL_MATCHED':
                setMatchStatus('Partially Matched');
                break;
            case 'MATCHED':
                setMatchStatus('Matched');
                break;
            case 'IN_BOOKS_ONLY':
                setMatchStatus('In Books Only');
                break;
            case 'IN_2A_ONLY':
            case 'IN_2B_ONLY':
                setMatchStatus(`In ${gstrKey === 'gstr2a' ? '2A' : '2B'} Only`);
                break;
            default:
                setMatchStatus('');
                break;
        }
    };

    const setMatchStatusColorCode = (match_status) => {
        switch (match_status) {
            case 'MISMATCHED': setMatchStatusColor('#f6594f'); break;
            case 'MATCHED': setMatchStatusColor('#00c800'); break;
            case 'IN_BOOKS_ONLY': setMatchStatusColor('#c74d4d'); break;
            case 'IN_2B_ONLY':
            case 'IN_2A_ONLY': setMatchStatusColor('#33691e'); break;
            default: setMatchStatusColor('#33691e'); break;
        }
    };

    useEffect(() => {
        const key = `selectedSeller_${mobile_number}_${gst_number}`;
        const supplier = localStorage.getItem(key);
        if (supplier) {
            const supplierInfo = decodeBase64(supplier);
            const name = JSON.parse(supplierInfo);
            const modelheaderName = `${name.supplier_name} (${name.gst_number})`;
            setModelName(modelheaderName);
        }
        //eslint-disable-next-line
    }, [gst_number]);

    const matchRemarksSave = async (action) => {
        let data = {
            company_id: params.company_id,
            mapping_id: recoId,
            doc_type: 'BOOKS',
            match_remarks: userComment
        };

        try {
            const result = await updateMatchRemarksV2(data, type);
            return { success: true, message: result.message };
        } catch (err) {
            console.error('error: ', err);
            return { success: false, message: err.message };
        }
    };

    const throttledSaveUserComment = throttle(saveUserComment, 3000);

    return (
        <>
            <WindowWrapperFixWidth
                title={
                    <kendo-dialog-titlebar style={{ width: '100%' }}>
                        <div className="col-md-12 multilinking-titleviewdetails" style={{ display: 'flex' }}>
                            <div className="col-md-12 text-center">
                                <span className="justify-content-center">
                                    {type !== "IMPG" ? modelName : (doc_type === 'IMPGSEZ' && type !== "IMPG") ? modelName : ""}
                                </span>
                            </div>
                        </div>
                    </kendo-dialog-titlebar>
                }
                onClose={onHide}
                totalPopups={1}
                index={1}
                isModalView={true}
                initialHeight={'auto'}
                initialWidth={1500}
                className={`windowWrapper multilinking-invoice-details-popup gstr9reportspopup windowwrapperfix_heading`}
                showDiffClass={true}
            >
                <ShowAlert
                    error={error}
                    success={success}
                    onClose={() => { setError(null); setSuccess(null); }}
                />

                <div style={{ padding: '5px' }}>

                    <MultiInvoiceDetalPopupBooks books={books} gstrKey={gstrKey} matchStatus={matchStatus} matchStatusColor={matchStatusColor} tabel_col={tabel_col} />

                    <MultiInvoiceDetalPopupGstr2A2B gstr2a2b={portal} gstrKey={gstrKey} tabel_col={tabel_col} />

                </div>
                {
                    invoiceData.matchStatus === 'PARTIAL_MATCHED' || invoiceData.matchStatus === 'MATCHED' ?
                        <div className="updatebooks2a mt-2">
                            <div className="table-responsive-sm">
                                <table className="table table-striped updatebooks2adataleftsidepopup">
                                    <tr>
                                        <td width={75} className="green_color_class" style={{ border: '0px', color: '#333', verticalAlign: 'middle' }}> Remarks</td>
                                        <td style={{ border: '0px' }}>
                                            <input
                                                type="text"
                                                name="match_remarks"
                                                className="form-control"
                                                value={userComment}
                                                onChange={(e) => {
                                                    setUserComment(e.target.value);
                                                    setError(''); // Clear error when user starts typing
                                                }}
                                                maxLength={250}
                                            />
                                            {validationError && <div className="text-danger">{validationError}</div>}
                                        </td>
                                        <td style={{ border: '0px' }}>
                                            <button
                                                className="btn btn-default sectionwise_viewddbtn updatecredsubbtn"
                                                onClick={() => throttledSaveUserComment('close')}
                                                disabled={userComment.trim().length < 3 || userComment === initialComment} // Disable when comment hasn't changed
                                            >
                                                Done
                                            </button>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        : null
                }

            </WindowWrapperFixWidth>
        </>
    );
}
