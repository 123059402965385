/* 
  *****************
  This slice is for app level data, for any data which we wants in store, this slice can be used.
  *****************
*/
/* eslint-disable array-callback-return */
import { createSlice } from '@reduxjs/toolkit';

const initialData = {
  authError: ''
};

export const appDataSlice = createSlice({
  name: 'appData',
  initialState: initialData,
  reducers: {
    setFieldValue: (state, action) => {
      const { key, value } = action.payload;
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state[key] = value;
    },
    setAppData: (state, action) => {
      Object.keys(action.payload).map((key) => {
        state[key] = action.payload[key];
      });
    },
  },
});

export const { setFieldValue, setAppData } = appDataSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectAppData = state => state.appData;

export default appDataSlice.reducer;
