import React, { useState } from 'react'
import { Alert, Button, Modal, Spinner } from 'react-bootstrap'
import NumericInput from '../../components/NumericInput';
import { get2ADataVerifyCatchaV2 } from '../../api/2adataAPI';
import { getGstInfoWithCaptcha } from '../../api/profileAPI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, } from '@fortawesome/free-solid-svg-icons';
import "../2AData/UpdateCredentials.css";
import { convertYearToFormattedYear } from '../../utils/UtilityFunctions';
import OtpVerifiyForCaptcha from '../../components/profile/OtpVerifiyForCaptcha';
import FindGSTINsLinkedPANModal from './FindGSTINsLinkedPANModal';
import WindowWrapperFixWidth from '../gstr9/sales/reports/WindowWrapperFixWidth';

const GstinInfoModel = (props) => {
    const { show, handleClose, data, setData, headerData, company_id, gstInNumber, setLoading: setLoadingIndicator } = props;
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [captchaLoading, setCaptchaLoading] = useState(false);
    const [captchaId, setCaptchaId] = useState(data?.gpc_id ?? "");
    const [captchaImg, setCaptchaImg] = useState(data?.captcha_image ?? "");
    const [captcha, setCaptcha] = useState("");
    const [openSetPassword, setOpenSetPassword] = useState(!data?.gstportal_isverified);
    const [gstportalUsername, setGstportalUsername] = useState(data?.gstportal_username ?? "");
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(null);
    const [otpRequired, setOtpRequired] = useState(false)

    const [viewGstinLinkPopup, setViewGstinLinkPopup] = useState(false);

    const getCaptcha = () => {
        async function fetchEverything() {
            try {
                const result = await get2ADataVerifyCatchaV2(company_id);
                setCaptchaImg(result?.data?.captcha_image);
                setCaptchaId(result.data.gpc_id);
                setOpenSetPassword(!result?.data?.gstportal_isverified);
                setGstportalUsername(result?.data?.gstportal_username);
                setCaptcha("");
            } catch (err) {
                console.error("error: ", err);
                setError(err.message);
            } finally {
                setCaptchaLoading(false);
                setLoadingIndicator(false);
            }
        }
        setCaptchaLoading(true);
        fetchEverything();
    };

    const loadCaptcha = () => {
        setError(null);
        setCaptchaLoading(true);
        getCaptcha();
    };


    const verifyCatpchaAndGetData = (props) => {
        if (!props?.otp) {
            if (captcha.length === 0) {
                setError("Please enter captcha!");
                return;
            }
            if (captcha.length < 6) {
                setError("Please enter valid captcha!");
                return;
            }
        }
        if (openSetPassword) {
            if (password.length === 0) {
                setPasswordError("Please enter Password!");
                return;

            }
            if (password.length < 8 || password.length > 16) {
                setPasswordError("Password should be of 8 to 15 characters!");
                return;

            }
        }
        const data = {
            gpc_id: captchaId,
            ...(props?.otp ? { otp: props.otp } : { captcha_text: captcha }),
            company_id: company_id,
            gst_number: gstInNumber,
            ...(openSetPassword && { gstportal_password: password }),
        };

        async function fetchEverything() {
            async function fetch2AData() {
                const result = await getGstInfoWithCaptcha(data);
                setData(result?.data);

            }
            try {
                await Promise.all([fetch2AData()]);
                setError(null);
                setOtpRequired(false)

            } catch (err) {
                if (err?.status === 423) {
                    setOtpRequired(true)

                }
                if (err) {
                    setError(err?.data?.errors[0])
                }
                else {
                    handleClose()
                }
            } finally {
                setLoading(false);
            }
        }
        setLoading(true);
        fetchEverything();
    };
    const handleCloseOtp = () => {
        setOtpRequired(false);
        getCaptcha()
    }



    return (
        <>

            {(data?.gpc_id && !openSetPassword) ?
                <>

                    <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                        className={`bookVerify bookVerify_modal ${data?.gpc_id ? '' : 'gstinfo_search_model'}`}
                    >
                        <Modal.Header closeButton className="bookVerify">
                            <Modal.Title>Search GST Number</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="py-0 px-0">
                            <div className="container mt-2">
                                <div className="card bookVerifyCard">
                                    <div className="row mt-0">
                                        <div className="form-group col-md-12 mb-0">
                                            <div className="row">
                                                <div className="form-group col-md-6">
                                                    <div className="captcha_img">
                                                        {!captchaLoading ? (
                                                            <img
                                                                src={`data:image/png;base64, ${captchaImg}`}
                                                                alt=""
                                                            />
                                                        ) : (
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                                <div
                                                    className="form-group col-md-6"
                                                    style={{ marginTop: "8px" }}
                                                >
                                                    <NumericInput
                                                        className="form-control"
                                                        id="captcha"
                                                        placeholder="Enter Captcha"
                                                        value={captcha}
                                                        maxLength={6}
                                                        onChange={(event) => setCaptcha(event.target.value)}
                                                        autoComplete="new-password"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group col-md-12 mb-0">
                                                <p className="mb-1">
                                                    <small style={{ fontSize: '12px' }}>
                                                        Can't read the captcha?
                                                        <Button
                                                            variant="link"
                                                            onClick={() => loadCaptcha()}
                                                            style={{ margin: "-9px", fontSize: 12, verticalAlign: 'baseline' }}
                                                        >
                                                            Click here
                                                        </Button>
                                                        to refresh
                                                    </small>
                                                </p>
                                                <p className="mb-0"
                                                    style={{
                                                        fontSize: "12px",
                                                        fontWeight: "400",
                                                        color: "#797979",
                                                    }}
                                                >
                                                    Please do not login at GSTN portal during this
                                                    process.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="bookVerify">
                            {error ? <Alert variant="danger">{error}</Alert> : null}
                            <Button className="light-cancel-btn" onClick={handleClose}>
                                Close
                            </Button>

                            <Button
                                className="bookVerifyBtn"
                                id="zps_proceed_portal"
                                onClick={verifyCatpchaAndGetData}
                                disabled={loading}
                            >
                                {loading ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />{" "}
                                        Loading...
                                    </>
                                ) : (
                                    "Proceed"
                                )}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>

                : (data?.gpc_id && openSetPassword) ?

                    <>
                        <Modal
                            show={show}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}
                            className={`bookVerify bookVerify_modal ${data?.gpc_id ? '' : 'gstinfo_search_model'}`}
                        >
                            <Modal.Header closeButton className="updatecreaditpopuph">
                                <h4 className="updatecreadittile">
                                    <span className="credcompnameo cmapnameoverflowtext">
                                        <span
                                            data-toggle="tooltip"
                                            data-placement="right"
                                            title={headerData.company_name}
                                        >
                                            {headerData.company_name}
                                        </span>
                                    </span>{" "}
                                    <span className="gstnnotexfs">
                                        <span className="px-1 modltitlecls">|</span> {headerData.gst_number}
                                    </span>
                                    <br />
                                    <span className="gstnunamefs">
                                        {" "}
                                        GSTN Username:{" "}
                                        <span className="gunametextblod">
                                            {gstportalUsername}
                                        </span>
                                    </span>
                                </h4>
                            </Modal.Header>
                            <Modal.Body className="py-1 px-3">
                                <div className="row pt-2">
                                    <div className="col-md-12">
                                        <p className="providcredenttextfs">
                                            It seems your password on GSTN portal has changed. Please provide
                                            updated GSTN password:{" "}
                                        </p>
                                        <div className="row mt-2 ">
                                            <div className="form-group col-sm-12">
                                                {showPassword ? (
                                                    <div className="input-group">
                                                        <input
                                                            type="input"
                                                            className="form-control lg-input form-control credinputtextfs"
                                                            name="password"
                                                            placeholder="Enter Password"
                                                            required=""
                                                            value={password}
                                                            onChange={(e) => setPassword(e.target.value)}
                                                        />
                                                        <div className="input-group-prepend eyeipbox uPdcursorPointer" onClick={() => setShowPassword(false)} style={{ cursor: 'pointer' }}>
                                                            <FontAwesomeIcon icon={faEyeSlash} />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="input-group">
                                                        <input
                                                            type="password"
                                                            className="form-control lg-input form-control credinputtextfs"
                                                            name="password"
                                                            placeholder="Enter Password"
                                                            required=""
                                                            value={password}
                                                            onChange={(e) => setPassword(e.target.value)}
                                                            onClick={() => setPasswordError('')}
                                                            autoComplete="new-password"
                                                        />
                                                        <div className="input-group-prepend eyeipbox uPdcursorPointer eyeicon_toppadd" onClick={() => setShowPassword(true)} style={{ cursor: 'pointer' }}>
                                                            <FontAwesomeIcon icon={faEye} />
                                                        </div>
                                                    </div>
                                                )}

                                                {passwordError ? <span className="signup_error_msg">{passwordError}</span> : null}


                                            </div>
                                            <div className="form-group col-sm-6 mb-0">
                                                <label for="">Captcha</label>
                                                <div className="input-group mb-1">
                                                    <div className="captcha_img captcha_imgwidth">
                                                        {!captchaLoading ? (
                                                            <img
                                                                src={`data:image/png;base64, ${captchaImg}`}
                                                                alt=""
                                                            />
                                                        ) : (
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group col-sm-6 mb-0">
                                                <label for="">&nbsp;</label>
                                                <div className="input-group">
                                                    <NumericInput
                                                        className="form-control inputheight_padd"
                                                        id="captcha"
                                                        placeholder="Enter Captcha"
                                                        value={captcha}
                                                        maxLength={6}
                                                        onChange={(event) => setCaptcha(event.target.value)}
                                                        onClick={() => setError('')}
                                                        autoComplete="new-password"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group col-md-12 mb-0">
                                                <p className="readcaptcha_textfs">
                                                    <small>
                                                        Can't read the captcha?{' '}
                                                        <span className="reloadcaptcha" onClick={() => loadCaptcha()}>Click here</span>{' '}
                                                        to refresh
                                                    </small>
                                                </p>
                                            </div>
                                            {error ?
                                                <div className="form-group col-md-12 mb-0">
                                                    <p>
                                                        <Alert variant="danger">{error}</Alert>
                                                    </p>
                                                </div>
                                                : null}
                                        </div>
                                    </div>
                                    <div className="col-md-12 text-center pt-2 pb-1">
                                        <Button
                                            className="btn btn-default updatecredsubbtn"
                                            onClick={verifyCatpchaAndGetData}
                                            disabled={loading}
                                        >
                                            {loading ? (
                                                <>
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />{" "}
                                                    Loading...
                                                </>
                                            ) : (
                                                "Submit"
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer className="bookVerify">

                            </Modal.Footer>
                        </Modal>
                    </>

                    :
                    <>
                        {viewGstinLinkPopup &&
                            <FindGSTINsLinkedPANModal
                                onHide={() => setViewGstinLinkPopup(false)}
                                setLoading={setLoading}
                                company_id={company_id}
                                pan={data?.gst_number}
                            />
                        }
                        <WindowWrapperFixWidth
                            title={<div className="gst_verifyyremailid_title gstinheader_searchmodal" style={{width: '100%'}}>
                                <div className='row'>
                                    <div className='col-md-6 gstinsearch_titlefs'>
                                        {data?.gst_number}
                                    </div>
                                    <div className='col-md-6 text-right'>
                                        {headerData?.organization_type === "CORPORATE" && 
                                            <button className='btn btn-default gstinheader_findgstinbtn' onClick={() => setViewGstinLinkPopup(true)}>
                                                Find GSTINs linked to this PAN
                                            </button>
                                        }
                                    </div>

                                </div>
                            </div>}
                            onClose={handleClose}
                            totalPopups={1}
                            index={1}
                            isModalView={true}
                            initialWidth={'auto'}
                            initialHeight={'auto'}
                            initialLeft={400}
                            initialTop={80}
                            className={`searchGstin_linkedmodal gstin_headersearchbox_header bookVerify_modal ${data?.gpc_id ? '' : 'gstinfo_search_model'}`}
                            showDiffClass={true}
                            style={{ zIndex: 1001 }} // Assign a lower z-index to this popup
                        >
                            <div className="row">
                                <div className="col col-md-6">
                                    {/* <!-- leftside col--> */}
                                    <h4 className="gstinsearch_titlefs">
                                        Basic Details
                                    </h4>
                                    <div className="row">
                                        <div className="col-sm-9 pr-1">
                                            <form>
                                                <div className="form-row">
                                                    <div className="col col-sm-4">
                                                        <label className="gstinsearch_lablefsclr label-orange">
                                                            Name
                                                        </label>
                                                    </div>
                                                    <div className="col col-sm-8">
                                                        <label className="gstinsearch_lablefsoutclr gstin_searchboxbasic_overflowtext label-skyblue">
                                                            <span data-toggle="tooltip" data-placement="top" title={data?.company_name}>
                                                                {data?.company_name}
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col col-sm-4">
                                                        <label className="gstinsearch_lablefsclr label-orange">
                                                            Dt of Reg:
                                                        </label>
                                                    </div>
                                                    <div className="col col-sm-8">
                                                        <label className="gstinsearch_lablefsoutclr label-skyblue">
                                                            {data?.registration_date}
                                                        </label>
                                                    </div>
                                                </div>


                                            </form>


                                        </div>
                                        <div className="col-sm-3 pr-1">
                                            <form>
                                                <div className="form-row">
                                                    <div className="col col-sm-4">
                                                        <label className="gstinsearch_lablefsclr label-orange pr-1">
                                                            Status:
                                                        </label>
                                                    </div>
                                                    <div className="col col-sm-8">
                                                        <label className="gstinsearch_lablefsoutclr label-skyblue gstin_status_oveflowtext">
                                                            <span data-toggle="tooltip" data-placement="top" title={data?.gstin_status}>
                                                                {data?.gstin_status}
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="col col-sm-4">
                                                        <label className="gstinsearch_lablefsclr label-orange">
                                                            Type:
                                                        </label>
                                                    </div>
                                                    <div className="col col-sm-8">
                                                        <label className="gstinsearch_lablefsoutclr label-skyblue gstin_status_oveflowtext">

                                                            <span data-toggle="tooltip" data-placement="top" title={data?.taxpayer_type}>
                                                                {data?.taxpayer_type}
                                                            </span>

                                                        </label>
                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                        <div className="col-sm-12">
                                            <form>


                                                <div className="form-row">
                                                    <div className="col col-sm-3">
                                                        <label className="gstinsearch_lablefsclr label-orange">
                                                            Constitution:
                                                        </label>
                                                    </div>
                                                    <div className="col col-sm-9">
                                                        <label className="gstinsearch_lablefsoutclr gstin_searchboxbasic_overflowtext label-skyblue">
                                                            <span data-toggle="tooltip" data-placement="top" title={data?.constitution_of_business}>
                                                                {data?.constitution_of_business}
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="col col-sm-3">
                                                        <label className="gstinsearch_lablefsclr label-orange">
                                                            Nature of Business:
                                                        </label>
                                                    </div>
                                                    <div className="col col-sm-9">
                                                        <label className="gstinsearch_lablefsoutclr gstin_searchboxbasic_overflowtext label-skyblue">
                                                            <span data-toggle="tooltip" data-placement="top" title={data?.nature_of_business}>
                                                                {data?.nature_of_business}
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                                {data?.cancellation_date &&
                                                    <div className="form-row">
                                                        <div className="col col-sm-3">
                                                            <label className="gstinsearch_lablefsclr label-orange">
                                                                Dt of Cancel:
                                                            </label>
                                                        </div>
                                                        <div className="col col-sm-9">
                                                            <label className="gstinsearch_lablefsoutclr gstin_searchboxbasic_overflowtext label-skyblue">
                                                                <span data-toggle="tooltip" data-placement="top" title={data?.cancellation_date}>
                                                                    {data?.cancellation_date}
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                }

                                                <div className="form-row">
                                                    <div className="col col-sm-3">
                                                        <label className="gstinsearch_lablefsclr label-orange">
                                                            Principle POB:
                                                        </label>
                                                    </div>
                                                    <div className="col col-sm-9">
                                                        <label className="gstinsearch_lablefsoutclr gstin_priPOB_overflowtext label-skyblue">
                                                            <span data-toggle="tooltip" data-placement="top" title={data?.principal_address}>
                                                                {data?.principal_address}
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </form>


                                        </div>
                                    </div>
                                    {!((Object.keys(data?.qrmp).length === 0 && data?.qrmp.constructor === Object) || (Array.isArray(data?.qrmp) && data?.qrmp.length === 0)) &&
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card mt-2">
                                                    <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                                        <table className="table table-striped gstinsearchbox_efillingdetails_tb mb-0">
                                                            <colgroup>
                                                                {/* <col width="33%"></col>
                                                                <col width="33%"></col>
                                                                <col width="33%"></col> */}
                                                            </colgroup>
                                                            <thead>
                                                                <tr>
                                                                    <th className='searchGstin_linked_head_textclr'>Filing Frequency</th>
                                                                    {data?.qrmp && Object.keys(data?.qrmp).map((item) => (<th className='searchGstin_linked_head_textclr'>{convertYearToFormattedYear(item)}</th>))}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr style={{ background: "#f9f9f9" }}>
                                                                    <td className="text-left label-orange">Apr-Jun</td>
                                                                    {data?.qrmp && Object.keys(data?.qrmp).map((item) => (<td className="text-center">{data?.qrmp[item]?.Q1 === "M" ? "Monthly" : data?.qrmp[item]?.Q1 === "Q" ? "Quarterly" : "-"}</td>))}
                                                                </tr>
                                                                <tr style={{ background: "#f9f9f9" }}>
                                                                    <td className="text-left label-orange">Jul-Sep</td>
                                                                    {data?.qrmp && Object.keys(data?.qrmp).map((item) => (<td className="text-center">{data?.qrmp[item]?.Q2 === "M" ? "Monthly" : data?.qrmp[item]?.Q2 === "Q" ? "Quarterly" : "-"}</td>))}

                                                                </tr>
                                                                <tr style={{ background: "#f9f9f9" }}>
                                                                    <td className="text-left label-orange">Oct-Dec</td>
                                                                    {data?.qrmp && Object.keys(data?.qrmp).map((item) => (<td className="text-center">{data?.qrmp[item]?.Q3 === "M" ? "Monthly" : data?.qrmp[item]?.Q3 === "Q" ? "Quarterly" : "-"}</td>))}

                                                                </tr>
                                                                <tr style={{ background: "#f9f9f9" }}>
                                                                    <td className="text-left label-orange">Jan-Mar</td>
                                                                    {data?.qrmp && Object.keys(data?.qrmp).map((item) => (<td className="text-center">{data?.qrmp[item]?.Q4 === "M" ? "Monthly" : data?.qrmp[item]?.Q4 === "Q" ? "Quarterly" : "-"}</td>))}

                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </div>

                                {/* <!-- right side col--> */}
                                {data?.filing &&
                                    <div className="col col-md-6">

                                        <h4 className="gstinsearch_titlefs">
                                            Filing Details
                                        </h4>
                                        <div className="card">
                                            <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                                {data?.filing?.table1 && data?.filing?.table1.length > 0 ?
                                                    <table className="table table-striped gstinsearchbox_efillingdetails_tb mb-0">
                                                        <colgroup>
                                                            {/* <col width="33%"></col>
                                                        <col width="33%"></col>
                                                        <col width="33%"></col> */}
                                                        </colgroup>
                                                        <thead>
                                                            <tr className='searchGstin_linked_head_textclr'>
                                                                {(data?.filing?.table1[0]).map((item) => (<th>{item}</th>))}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(data?.filing?.table1).map((item, i) => {
                                                                if (i === 0) return null;

                                                                return (<tr>{

                                                                    item.map((newitem, index) => {
                                                                        if (index === 0) {
                                                                            return <td className="text-left label-orange">{newitem}</td>
                                                                        }
                                                                        return <td className="text-center">{newitem}</td>


                                                                    })}
                                                                </tr>

                                                                )
                                                            })}


                                                        </tbody>
                                                    </table>

                                                    :
                                                    <>

                                                        <table className="table table-striped gstinsearchbox_efillingdetails_tb mb-0">
                                                            <thead>
                                                                <tr className='label-orange'>
                                                                    <th>GSTR-1 / 3B / 4 / 2X / 7 / 8 / 11</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr className='text-center mt-3' style={{ fontSize: "12px" }} >No returns found for the last 6 months.</tr>

                                                            </tbody>
                                                        </table>
                                                    </>
                                                }
                                            </div>
                                        </div>

                                        <div className="card mt-2">
                                            <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">

                                                {data?.filing?.table2 && data?.filing?.table2.length > 0 ?

                                                    <table className="table table-striped gstinsearchbox_efillingdetails_tb mb-0">
                                                        <colgroup>
                                                            {/* <col width="33%"></col>
                                                        <col width="33%"></col>
                                                        <col width="33%"></col> */}
                                                        </colgroup>
                                                        <thead>
                                                            <tr>
                                                                {(data?.filing?.table2[0]).map((item) => (<th className='searchGstin_linked_head_textclr'> {item}</th>))}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(data?.filing?.table2).map((item, i) => {
                                                                if (i === 0) return null;
                                                                return (<tr>{

                                                                    item.map((newitem, index) => {
                                                                        if (index === 0) {
                                                                            return <td className="text-left label-orange">{newitem}</td>
                                                                        }
                                                                        return <td className="text-center">{newitem}</td>
                                                                    })}
                                                                </tr>

                                                                )
                                                            })}


                                                        </tbody>
                                                    </table>
                                                    :
                                                    <>  <table className="table table-striped gstinsearchbox_efillingdetails_tb mb-0">
                                                        <thead>
                                                            <tr className='label-orange'>
                                                                <th>GSTR-9 / 9C / 9A</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className='text-center mt-3' style={{ fontSize: "12px" }}  >No returns found.</tr>

                                                        </tbody>
                                                    </table></>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                }
                            </div>

                        </WindowWrapperFixWidth>

                        {/* <Modal.Header closeButton className="gstin_headersearchbox_header">
                                <Modal.Title className="gst_verifyyremailid_title gstinheader_searchmodal" style={{ width: '100%' }} >
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            {data?.gst_number}
                                        </div>
                                        <div className='col-md-6 text-right'>
                                            <button className='btn btn-default gstinheader_findgstinbtn' onClick={() => setViewGstinLinkPopup(true)}>
                                                Find GSTINs linked to this PAN
                                            </button>
                                        </div>

                                    </div>
                                </Modal.Title>
                            </Modal.Header> */}
                        {/* <Modal.Body>
                                <div className="row">
                                    <div className="col col-md-6">
                                        <h4 className="gstinsearch_basicdeatils_titlefs">
                                            Basic Details
                                        </h4>
                                        <div className="row">
                                            <div className="col-sm-9 pr-1">
                                                <form>
                                                    <div className="form-row">
                                                        <div className="col col-sm-4">
                                                            <label className="gstinsearch_lablefsclr label-orange">
                                                                Name
                                                            </label>
                                                        </div>
                                                        <div className="col col-sm-8">
                                                            <label className="gstinsearch_lablefsoutclr gstin_searchboxbasic_overflowtext label-skyblue">
                                                                <span data-toggle="tooltip" data-placement="top" title={data?.company_name}>
                                                                    {data?.company_name}
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="col col-sm-4">
                                                            <label className="gstinsearch_lablefsclr label-orange">
                                                                Dt of Reg:
                                                            </label>
                                                        </div>
                                                        <div className="col col-sm-8">
                                                            <label className="gstinsearch_lablefsoutclr label-skyblue">
                                                                {data?.registration_date}
                                                            </label>
                                                        </div>
                                                    </div>


                                                </form>


                                            </div>
                                            <div className="col-sm-3 pr-1">
                                                <form>
                                                    <div className="form-row">
                                                        <div className="col col-sm-4">
                                                            <label className="gstinsearch_lablefsclr label-orange pr-1">
                                                                Status:
                                                            </label>
                                                        </div>
                                                        <div className="col col-sm-8">
                                                            <label className="gstinsearch_lablefsoutclr label-skyblue gstin_status_oveflowtext">
                                                                <span data-toggle="tooltip" data-placement="top" title={data?.gstin_status}>
                                                                    {data?.gstin_status}
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="form-row">
                                                        <div className="col col-sm-4">
                                                            <label className="gstinsearch_lablefsclr label-orange">
                                                                Type:
                                                            </label>
                                                        </div>
                                                        <div className="col col-sm-8">
                                                            <label className="gstinsearch_lablefsoutclr label-skyblue gstin_status_oveflowtext">

                                                                <span data-toggle="tooltip" data-placement="top" title={data?.taxpayer_type}>
                                                                    {data?.taxpayer_type}
                                                                </span>

                                                            </label>
                                                        </div>
                                                    </div>

                                                </form>
                                            </div>
                                            <div className="col-sm-12">
                                                <form>


                                                    <div className="form-row">
                                                        <div className="col col-sm-3">
                                                            <label className="gstinsearch_lablefsclr label-orange">
                                                                Constitution:
                                                            </label>
                                                        </div>
                                                        <div className="col col-sm-9">
                                                            <label className="gstinsearch_lablefsoutclr gstin_searchboxbasic_overflowtext label-skyblue">
                                                                <span data-toggle="tooltip" data-placement="top" title={data?.constitution_of_business}>
                                                                    {data?.constitution_of_business}
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="form-row">
                                                        <div className="col col-sm-3">
                                                            <label className="gstinsearch_lablefsclr label-orange">
                                                                Nature of Business:
                                                            </label>
                                                        </div>
                                                        <div className="col col-sm-9">
                                                            <label className="gstinsearch_lablefsoutclr gstin_searchboxbasic_overflowtext label-skyblue">
                                                                <span data-toggle="tooltip" data-placement="top" title={data?.nature_of_business}>
                                                                    {data?.nature_of_business}
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {data?.cancellation_date &&
                                                        <div className="form-row">
                                                            <div className="col col-sm-3">
                                                                <label className="gstinsearch_lablefsclr label-orange">
                                                                    Dt of Cancel:
                                                                </label>
                                                            </div>
                                                            <div className="col col-sm-9">
                                                                <label className="gstinsearch_lablefsoutclr gstin_searchboxbasic_overflowtext label-skyblue">
                                                                    <span data-toggle="tooltip" data-placement="top" title={data?.cancellation_date}>
                                                                        {data?.cancellation_date}
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    }

                                                    <div className="form-row">
                                                        <div className="col col-sm-3">
                                                            <label className="gstinsearch_lablefsclr label-orange">
                                                                Principle POB:
                                                            </label>
                                                        </div>
                                                        <div className="col col-sm-9">
                                                            <label className="gstinsearch_lablefsoutclr gstin_priPOB_overflowtext label-skyblue">
                                                                <span data-toggle="tooltip" data-placement="top" title={data?.principal_address}>
                                                                    {data?.principal_address}
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </form>


                                            </div>
                                        </div>
                                        {!((Object.keys(data?.qrmp).length === 0 && data?.qrmp.constructor === Object) || (Array.isArray(data?.qrmp) && data?.qrmp.length === 0)) &&
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="card mt-2">
                                                        <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                                            <table className="table table-striped gstinsearchbox_efillingdetails_tb mb-0">
                                                                <colgroup>
                                                                    
                                                                </colgroup>
                                                                <thead>
                                                                    <tr>
                                                                        <th className='searchGstin_linked_head_textclr'>Filing Frequency</th>
                                                                        {data?.qrmp && Object.keys(data?.qrmp).map((item) => (<th className='searchGstin_linked_head_textclr'>{convertYearToFormattedYear(item)}</th>))}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr style={{ background: "#f9f9f9" }}>
                                                                        <td className="text-left label-orange">Apr-Jun</td>
                                                                        {data?.qrmp && Object.keys(data?.qrmp).map((item) => (<td className="text-center">{data?.qrmp[item]?.Q1 === "M" ? "Monthly" : data?.qrmp[item]?.Q1 === "Q" ? "Quarterly" : "-"}</td>))}
                                                                    </tr>
                                                                    <tr style={{ background: "#f9f9f9" }}>
                                                                        <td className="text-left label-orange">Jul-Sep</td>
                                                                        {data?.qrmp && Object.keys(data?.qrmp).map((item) => (<td className="text-center">{data?.qrmp[item]?.Q2 === "M" ? "Monthly" : data?.qrmp[item]?.Q2 === "Q" ? "Quarterly" : "-"}</td>))}

                                                                    </tr>
                                                                    <tr style={{ background: "#f9f9f9" }}>
                                                                        <td className="text-left label-orange">Oct-Dec</td>
                                                                        {data?.qrmp && Object.keys(data?.qrmp).map((item) => (<td className="text-center">{data?.qrmp[item]?.Q3 === "M" ? "Monthly" : data?.qrmp[item]?.Q3 === "Q" ? "Quarterly" : "-"}</td>))}

                                                                    </tr>
                                                                    <tr style={{ background: "#f9f9f9" }}>
                                                                        <td className="text-left label-orange">Jan-Mar</td>
                                                                        {data?.qrmp && Object.keys(data?.qrmp).map((item) => (<td className="text-center">{data?.qrmp[item]?.Q4 === "M" ? "Monthly" : data?.qrmp[item]?.Q4 === "Q" ? "Quarterly" : "-"}</td>))}

                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </div>

                                    {data?.filing &&
                                        <div className="col col-md-6">

                                            <h4 className="gstinsearch_basicdeatils_titlefs">
                                                Filing Details
                                            </h4>
                                            <div className="card">
                                                <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                                    {data?.filing?.table1 && data?.filing?.table1.length > 0 ?
                                                        <table className="table table-striped gstinsearchbox_efillingdetails_tb mb-0">
                                                            <colgroup>
                                                                
                                                            </colgroup>
                                                            <thead>
                                                                <tr className='searchGstin_linked_head_textclr'>
                                                                    {(data?.filing?.table1[0]).map((item) => (<th>{item}</th>))}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {(data?.filing?.table1).map((item, i) => {
                                                                    if (i === 0) return null;

                                                                    return (<tr>{

                                                                        item.map((newitem, index) => {
                                                                            if (index === 0) {
                                                                                return <td className="text-left label-orange">{newitem}</td>
                                                                            }
                                                                            return <td className="text-center">{newitem}</td>


                                                                        })}
                                                                    </tr>

                                                                    )
                                                                })}


                                                            </tbody>
                                                        </table>

                                                        :
                                                        <>

                                                            <table className="table table-striped gstinsearchbox_efillingdetails_tb mb-0">
                                                                <thead>
                                                                    <tr className='label-orange'>
                                                                        <th>GSTR-1 / 3B / 4 / 2X / 7 / 8 / 11</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr className='text-center mt-3' style={{ fontSize: "12px" }} >No returns found for the last 6 months.</tr>

                                                                </tbody>
                                                            </table>
                                                        </>
                                                    }
                                                </div>
                                            </div>

                                            <div className="card mt-2">
                                                <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">

                                                    {data?.filing?.table2 && data?.filing?.table2.length > 0 ?

                                                        <table className="table table-striped gstinsearchbox_efillingdetails_tb mb-0">
                                                            <colgroup>
                                                                
                                                            </colgroup>
                                                            <thead>
                                                                <tr>
                                                                    {(data?.filing?.table2[0]).map((item) => (<th className='searchGstin_linked_head_textclr'> {item}</th>))}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {(data?.filing?.table2).map((item, i) => {
                                                                    if (i === 0) return null;
                                                                    return (<tr>{

                                                                        item.map((newitem, index) => {
                                                                            if (index === 0) {
                                                                                return <td className="text-left label-orange">{newitem}</td>
                                                                            }
                                                                            return <td className="text-center">{newitem}</td>
                                                                        })}
                                                                    </tr>

                                                                    )
                                                                })}


                                                            </tbody>
                                                        </table>
                                                        :
                                                        <>  <table className="table table-striped gstinsearchbox_efillingdetails_tb mb-0">
                                                            <thead>
                                                                <tr className='label-orange'>
                                                                    <th>GSTR-9 / 9C / 9A</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr className='text-center mt-3' style={{ fontSize: "12px" }}  >No returns found.</tr>

                                                            </tbody>
                                                        </table></>
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    }
                                </div>
                            </Modal.Body> */}
                    </>
            }
            {/* </Modal> */}
            {otpRequired && <OtpVerifiyForCaptcha handleClose={handleCloseOtp} callFunc={verifyCatpchaAndGetData} error={error} setError={setError} />}

        </>



    )
}

export default GstinInfoModel