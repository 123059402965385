/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { BooksData, ImpgBooksData } from '../../../api/MultiLinkingApi';
import { ColumnMenu } from '../../../components/grid/ColumnMenu';
import CheckboxCell from '../common/CheckboxCell';
import ShowMorePagination from '../../../components/pagination/ShowMorePagination';
import { getPeriodString, getDocumentTypeLable } from '../../../utils/UtilityFunctions';
import moment from 'moment';
import { formatAccountsNumber } from '../../../utils/UtilityFunctions';
import ModelForMultiLinking from '../common/ModelForMultiLinking';
import SkeltonCustomGrid from '../../../components/Skeleton/SkeltonCustomGrid';

const Books2ATable = (props) => {
    const {
        company_id,
        fullResponse,
        requestedDataFormat,
        booksCheckboxes,
        selectedMode,
        fromDateBooks,
        toDateBooks,
        checkedItemsBooks,
        setCheckedItemsBooks,
        AllDocsBooks,
        setCheckedDateBooks,
        setRefDetails,
        selectedSeller,
        refDetails,
        firstSelection,
        AllDocsGSTR2A
    } = props;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalBooksData, setModalBooksData] = useState([]);
    const [modalPortalData, setModalPortalData] = useState([]);
    const [popupCurrentPage, setPopupCurrentPage] = useState(0);
    const [currentDataItem, setCurrentDataItem] = useState(null);

    const getDocTypesFromBooksRange = (checkboxes) => {
        return Object.keys(checkboxes).filter(key => checkboxes[key]);
    };

    const [dataState, setDataState] = useState({
        skip: 0,
        take: 17,
        sort: [],
        filter: {
            logic: 'and',
            filters: []
        },
    });

    const initialBooksDataState = {
        company_id,
        return_periods: requestedDataFormat,
        gst_number: fullResponse?.gst_number || "",
        filter_periods: [],
        sort: [],
        doc_type: getDocTypesFromBooksRange(booksCheckboxes),
        is_linked: AllDocsBooks ? "2" : "0",
        from_date: fromDateBooks || "",
        to_date: toDateBooks || "",
        limit: 17,
        page: 1,
    };

    const [booksDataState, setBooksDataState] = useState(initialBooksDataState);
    const [impgDataState, setImpgDataState] = useState({ ...initialBooksDataState, doc_type: "IMPG", gst_number: "" });
    const [data, setData] = useState([]);
    const [initialData, setInitialData] = useState([]);
    const [loading, setLoading] = useState(false);
    //eslint-disable-next-line
    const [showMoreVisible, setShowMoreVisible] = useState(false);
    const [fetchTrigger, setFetchTrigger] = useState(0);
    const [cursor, setCursor] = useState({ current_page: 1, next_page_url: null });

    const filterAndSortData = (data) => {
        if (firstSelection === 'books') return data;
        const fromDate = fromDateBooks ? new Date(fromDateBooks) : null;
        const toDate = toDateBooks ? new Date(toDateBooks) : null;

        let sortedData;

        if (fromDate && toDate) {
            const dataWithinRange = data.filter(item => {
                const docDate = new Date(item.doc_date);
                return docDate >= fromDate && docDate <= toDate;
            }).sort((a, b) => new Date(a.doc_date) - new Date(b.doc_date));

            const dataOutsideRange = data.filter(item => {
                const docDate = new Date(item.doc_date);
                return docDate < fromDate || docDate > toDate;
            }).sort((a, b) => new Date(b.doc_date) - new Date(a.doc_date));

            sortedData = [...dataWithinRange, ...dataOutsideRange];
        } else {
            sortedData = data.sort((a, b) => new Date(b.doc_date) - new Date(a.doc_date));
        }

        return sortedData.map(item => ({
            ...item,
            isChecked: checkedItemsBooks[item.id]?.isChecked || false,
        }));
    };

    const fetchData = async (state, additional = false) => {
        try {
            setLoading(true);
            const requestData = {
                ...state,
                filters: dataState.filter.filters,
                sort: dataState.sort,
                limit: state.limit,
                page: state?.page ?? 1
            };

            if(fullResponse.doc_type === 'IMPGSEZ') {
                requestData.doc_type = 'IMPGSEZ';                
            }
            if(state.doc_type === "IMPG"){
                requestData.doc_type = 'IMPG';
            }
         
            const response = ( state.doc_type === "IMPG" || fullResponse.doc_type === 'IMPGSEZ') ? await ImpgBooksData(requestData) : await BooksData(requestData);
            const dataKey = ( state.doc_type === "IMPG" || fullResponse.doc_type === 'IMPGSEZ') ? "boe_date" : "doc_date";
            const docKey = ( state.doc_type === "IMPG" || fullResponse.doc_type === 'IMPGSEZ') ? "boe_no" : "doc_no";

            
            if (!response.data.data) {
                setData([]);
                setInitialData([]);
                setShowMoreVisible(false);
            } else {
                const formattedData = response.data.data.map(item => ({
                    id: item.doc_id,
                    id_books: item.id_books,
                    doc_type: item.doc_type,
                    isChecked: checkedItemsBooks[item.doc_id]?.isChecked || false,
                    doc_date: item[dataKey],
                    doc_no: item[docKey],
                    BooksPrd: getPeriodString(item.month, item.year),
                    taxable_amt: item.taxable_amt,
                    tax_amt: item.tax_amt,
                    is_linked: item.is_linked,
                    books_data: item.books_data,
                    portal_data: item.portal_data,
                    reco_id: item.reco_id,
                    match_remarks: item.match_remarks,
                    match_status: item.match_status
                }));

                let newData = additional ? [...data, ...formattedData] : formattedData;

                newData = newData.filter((item, index, self) =>
                    index === self.findIndex((t) => t.id === item.id)
                );

                const filteredAndSortedData = filterAndSortData(newData);

                setData(filteredAndSortedData);
                if (!additional) {
                    setInitialData(filteredAndSortedData); // Store initial data only when fetching new data
                }

                if (additional) {
                    setBooksDataState(prevState => ({ ...prevState, page: prevState.page + 1 }));
                    setImpgDataState(prevState => ({ ...prevState, page: prevState.page + 1 }));
                } else {
                    setBooksDataState(prevState => ({ ...prevState, page: 1 }));
                    setImpgDataState(prevState => ({ ...prevState, page: 1 }));
                }

                setShowMoreVisible(!!response.data.cursor.next_page);
            }
            if (response?.data?.cursor?.next_page > 1) {
                response.data.cursor.next_page_url = response?.data?.cursor?.next_page;
            }
            setCursor(response?.data?.cursor);
        } catch (err) {
            setData([]);
            setShowMoreVisible(false);
        } finally {
            setLoading(false);
        }
    };


    // New effect to clear checkboxes when AllDocsGSTR2A changes
    useEffect(() => {
        setCheckedItemsBooks({});
        setCheckedDateBooks(null);
        // You may want to clear any related state here as well

        const updatedData = data.map(item => ({
            ...item,
            isChecked: false
        }));
        setData(updatedData);
    }, [AllDocsGSTR2A, AllDocsBooks]);


    useEffect(() => {
        const updatedDocType = getDocTypesFromBooksRange(booksCheckboxes);

        const newBooksDataState = {
            ...booksDataState,
            gst_number: fullResponse?.gst_number || "",
            return_periods: requestedDataFormat,
            doc_type: updatedDocType,
            is_linked: AllDocsBooks ? "2" : "0",
            page: 1,
            filters: dataState.filter?.filters
        };

        const newImpgDataState = {
            ...impgDataState,
            gst_number: "",
            return_periods: requestedDataFormat,
            doc_type: "IMPG",
            is_linked: AllDocsBooks ? "2" : "0",
            page: 1,
            filters: dataState.filter?.filters
        };

        const isBooksDataChanged = JSON.stringify(newBooksDataState) !== JSON.stringify(booksDataState);
        const isImpgDataChanged = JSON.stringify(newImpgDataState) !== JSON.stringify(impgDataState);

        if (isBooksDataChanged) {
            setBooksDataState(newBooksDataState);
        }

        if (isImpgDataChanged) {
            setImpgDataState(newImpgDataState);
        }

        if (isBooksDataChanged || isImpgDataChanged) {
            setFetchTrigger(prev => prev + 1);
        }
    }, [fullResponse, requestedDataFormat, booksCheckboxes, AllDocsBooks, dataState]);

    useEffect(() => {
        if (selectedMode === "IMPG") {
            setFetchTrigger(prev => prev + 1)
        } else if (!selectedSeller) {
            setData([]);
        } else {
            setFetchTrigger(prev => prev + 1)
        }
    }, [selectedMode, selectedSeller]);

    useEffect(() => {
        if (fetchTrigger === 0) return;

        if (selectedMode === "IMPG") {
            fetchData(impgDataState);
        } else {
            fetchData(booksDataState);
        }
    }, [fetchTrigger]);

    useEffect(() => {
        if (data.length > 0) {
            const filteredAndSortedData = filterAndSortData(data);
            setData(filteredAndSortedData);
        }
    }, [fromDateBooks, toDateBooks]);

    const handleCheckboxChange = (e, dataItem) => {
        const updatedCheckedItems = { ...checkedItemsBooks };

        // Determine the match status and reference ID to be added to refDetails
        const refId = dataItem.match_status === "IN_BOOKS_ONLY" || dataItem.match_status === "IN_2B_ONLY"
            ? dataItem.id
            : dataItem.reco_id;

        const newRefDetails = {
            ref_id: refId,
            ref_section: dataItem.doc_type,
            match_status: dataItem.match_status,
        };

        // Only update refDetails if necessary, to avoid unnecessary state updates
        if (!refDetails.some(ref => ref.ref_id === newRefDetails.ref_id && ref.ref_section === newRefDetails.ref_section)) {
            setRefDetails(prevRefDetails => [...prevRefDetails, newRefDetails]);
        }

        if (e.target.checked) {
            updatedCheckedItems[dataItem.id] = {
                isChecked: true,
                id_books: dataItem.id,
                doc_type: dataItem.doc_type,
                doc_date: dataItem.doc_date
            };
        } else {
            delete updatedCheckedItems[dataItem.id];
        }

        setCheckedItemsBooks(updatedCheckedItems);

        const updatedData = data.map(item =>
            item.id === dataItem.id ? { ...item, isChecked: e.target.checked } : item
        );
        setData(updatedData);

        if (Object.keys(updatedCheckedItems).length === 0) {
            setCheckedDateBooks(null);
        } else {
            const latestDate = Object.values(updatedCheckedItems).reduce((latest, item) => {
                return new Date(item.doc_date) > new Date(latest) ? item.doc_date : latest;
            }, new Date(0));
            setCheckedDateBooks(latestDate);
        }
    };

    //for telerik filters
    const dataStateChange = (event) => {
        const newDataState = event.dataState;
        setDataState(newDataState);

        const filtersCleared = !newDataState?.filter || newDataState.filter.filters.length === 0;

        if (filtersCleared) {
            // Handle the scenario where all filters are cleared
            setDataState({ ...newDataState, filter: { logic: 'and', filters: [] }, skip: 0 });
            setBooksDataState(prevState => ({ ...prevState, page: 1 }));
            setImpgDataState(prevState => ({ ...prevState, page: 1 }));
            setData([...initialData]); // Reset data to initial state
        }
    };

    const handleShowMore = () => {
        fetchData(selectedMode === "IMPG" ? impgDataState : booksDataState, true);
    };

    const isColumnActive = (field) => {
        return dataState.filter.filters.some(filter =>
            filter.field === field || (filter.filters && filter.filters.some(subFilter => subFilter.field === field))
        );
    };

    const rowRender = (trElement, rowProps) => {
        const { dataItem, index } = rowProps;

        const backgroundColor = dataItem.isChecked
            ? '#e0e0e0'
            : dataItem.is_linked
                ? '#fbf3cf'
                : '#d6e9f7';

        if (dataItem.books_data && dataItem.books_data.length > 0) {
            const booksCount = dataItem.books_data.length;

            return (
                <tr key={index} style={{ backgroundColor }} onClick={() => {
                    setModalBooksData(dataItem.books_data);
                    setModalPortalData(dataItem.portal_data);
                    setPopupCurrentPage(0);
                    setCurrentDataItem(dataItem); // Set the clicked data item
                    setIsModalOpen(true);
                }}>
                    <CheckboxCell dataItem={dataItem} handleCheckboxChange={handleCheckboxChange} disabled={true} />
                    <td colSpan={4} className="text-center" style={{ color: "#5687a4", cursor: "pointer" }}>
                        {`${booksCount} document${booksCount > 1 ? 's' : ''} linked`}
                    </td>
                    <td className="align-right">{formatAccountsNumber(dataItem.taxable_amt ?? 0)}</td>
                    <td className="align-right">{formatAccountsNumber(dataItem.tax_amt ?? 0)}</td>
                </tr>
            );
        }


        return (
            <tr key={index} style={{ backgroundColor }}>
                <CheckboxCell dataItem={dataItem} handleCheckboxChange={handleCheckboxChange} />
                <td className="align-center">{dataItem.doc_date ? moment(dataItem.doc_date).format('DD/MM/YYYY') : ''}</td>
                <td className="align-center">
                <span title={dataItem.doc_no}>{dataItem.doc_no && dataItem.doc_no.length > 12 ? dataItem.doc_no.substring(0, 12)+ "..." : dataItem.doc_no}</span>
                </td>
                <td className="align-center">{getDocumentTypeLable(dataItem.doc_type)}</td>
                <td className="align-center">{dataItem.BooksPrd}</td>
                <td className="align-right">{formatAccountsNumber(dataItem.taxable_amt ?? 0)}</td>
                <td className="align-right">{formatAccountsNumber(dataItem.tax_amt ?? 0)}</td>
            </tr>
        );
    };

    return (
        <>
            <div className="col-lg-6">
                <div className="card px-1 py-1">
                    {loading ? (<table className="table">
                        <SkeltonCustomGrid column={5} rowNo={5} />
                    </table>) :
                        <>
                            <div className="table-responsive">
                            <Tooltip anchorElement="pointer" position="top">
                                <Grid
                                    data={data}
                                    {...dataState}
                                    onDataStateChange={dataStateChange}
                                    className="table-responsive multilinking-data-listing"
                                    filterOperators={{
                                        text: [{ text: 'grid.filterContainsOperator', operator: 'contains' }],
                                        numeric: [
                                            { text: 'grid.filterGteOperator', operator: 'gte' },
                                            { text: 'grid.filterLteOperator', operator: 'lte' },
                                            { text: 'grid.filterLtOperator', operator: 'lt' },
                                            { text: 'grid.filterGtOperator', operator: 'gt' },
                                            { text: 'grid.filterEqOperator', operator: 'eq' },
                                            { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                        ],
                                        textWithEmpty: [
                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                            { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                        ],
                                        date: [
                                            { text: 'grid.filterGteOperator', operator: 'gte' },
                                            { text: 'grid.filterLteOperator', operator: 'lte' },
                                            { text: 'grid.filterLtOperator', operator: 'lt' },
                                            { text: 'grid.filterGtOperator', operator: 'gt' },
                                            { text: 'grid.filterEqOperator', operator: 'eq' },
                                        ],
                                    }}
                                    rowRender={rowRender}
                                    style={{ maxHeight: '610px', overflowY: 'auto' }}
                                >
                                    <Column
                                        width="35"
                                        field="checkbox"
                                        filterable={false}
                                        headerCell={() => <span></span>}
                                        columnMenu={null}
                                    />
                                    <Column
                                        width="90"
                                        field={selectedMode === "IMPG" || fullResponse?.doc_type === "IMPGSEZ" ? "boe_date" : "doc_date"}
                                        filter='date'
                                        headerClassName={isColumnActive(selectedMode === "IMPG" || fullResponse?.doc_type === "IMPGSEZ" ? "boe_date" : "doc_date") ? 'active' : ''}
                                        title={(selectedMode === "IMPG" || fullResponse?.doc_type === "IMPGSEZ") ? "BoE Dt." : "Doc Dt."}
                                        columnMenu={(props) => <ColumnMenu {...props} />}
                                    />
                                    <Column
                                        width="90"
                                        field={selectedMode === "IMPG" || fullResponse?.doc_type === "IMPGSEZ" ? "boe_no" : "doc_no"}
                                        filter='text'
                                        headerClassName={isColumnActive(selectedMode === "IMPG" || fullResponse?.doc_type === "IMPGSEZ" ? "boe_no" : "doc_no") ? 'active' : ''}
                                        title={(selectedMode === "IMPG" || fullResponse?.doc_type === "IMPGSEZ") ? "BoE No." : "Doc No."}
                                        columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                    />
                                    <Column
                                        width="90"
                                        field="doc_type"
                                        filter='text'
                                        headerClassName={isColumnActive('doc_type') ? 'active' : ''}
                                        title={"Doc Type"}
                                        columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                    />
                                    <Column
                                        width="90"
                                        field="BooksPrd"
                                        filterable={false}
                                        headerClassName="disabled_sorting"
                                        title={"Books Prd"}
                                    />
                                    <Column
                                        width="90"
                                        field="taxable_amt"
                                        filter='numeric'
                                        headerClassName={isColumnActive('taxable_amt') ? 'active' : ''}
                                        title={"Taxable Val"}
                                        columnMenu={(props) => <ColumnMenu {...props} />}
                                    />
                                    <Column
                                        width="90"
                                        field="tax_amt"
                                        filter='numeric'
                                        headerClassName={isColumnActive('tax_amt') ? 'active' : ''}
                                        title={"Tax"}
                                        columnMenu={(props) => <ColumnMenu {...props} />}
                                    />
                                </Grid>
                                </Tooltip>
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <ShowMorePagination
                                        cursor={cursor}
                                        postData={booksDataState}
                                        loading={loading}
                                        fetchData={handleShowMore}
                                    />
                                </div>
                            </div>
                        </>
                    }
                </div>

            </div>

            {isModalOpen && (
                <ModelForMultiLinking
                    onHide={() => setIsModalOpen(false)}
                    closeModal={() => setIsModalOpen(false)}
                    totalInvoiceObj={modalBooksData?.length + modalPortalData?.length}
                    popupCurrentPage={popupCurrentPage}
                    invoiceList={[
                        ...(modalBooksData?.map(item => ({ ...item, matchRemark: currentDataItem?.match_remarks, matchStatus: currentDataItem?.match_status, reco_id: currentDataItem?.reco_id })) || []),
                        ...(modalPortalData?.map(item => ({ ...item, matchRemark: currentDataItem?.match_remarks, matchStatus: currentDataItem?.match_status, reco_id: currentDataItem?.reco_id })) || [])
                    ]}
                    type={selectedMode}
                    gstrKey="gstr2a"
                    books={modalBooksData}
                    portal={modalPortalData}
                    gst_number={selectedMode !== "IMPG" ? selectedSeller : ""}
                    tabel_col={(selectedMode === "IMPG" || fullResponse?.doc_type === "IMPGSEZ") ? "imp" : `b2b`}
                    doc_type={fullResponse?.doc_type ?? ''}
                />
            )}
        </>
    );
};

export default Books2ATable;
