import React from 'react';

const CheckboxGroup = ({ checkboxes, handleCheckboxChange, elementId }) => (
    <div className="invoicereco_by2a2b_checkbox_left">
        <span className="pr-1" style={{fontSize:'1.2rem, fontWeigth:500'}}>B2B (</span>
        <div className="form-check-inline invoicereco_by2a2b_checkbox">
            <label className="form-check-label pr-1" id={`${elementId}-filter_doc_type_invoices`}>
                <input type="checkbox" className="form-check-input mr-0" checked={checkboxes.I} onChange={() => handleCheckboxChange('I')} />
                <span style={{ verticalAlign: 'text-bottom', paddingLeft: "2px" }}>Invoices</span>
            </label>
        </div>
        <div className="form-check-inline invoicereco_by2a2b_checkbox">
            <label className="form-check-label pr-1" id={`${elementId}-filter_doc_type_cn`}>
                <input type="checkbox" className="form-check-input mr-0" checked={checkboxes.C} onChange={() => handleCheckboxChange('C')} />
                <span style={{ verticalAlign: 'text-bottom', paddingLeft: "2px" }}>CN</span>
            </label>
        </div>
        <div className="form-check-inline invoicereco_by2a2b_checkbox">
            <label className="form-check-label" id={`${elementId}-filter_doc_type_dn`}>
                <input type="checkbox" className="form-check-input mr-0" checked={checkboxes.D} onChange={() => handleCheckboxChange('D')} />
                <span style={{ verticalAlign: 'text-bottom', paddingLeft: "2px" }}>DN</span>
            </label>
        </div>
        <span>)</span>
    </div>
);

export default CheckboxGroup;
