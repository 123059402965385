// invoiceSlice.js or relevant slice file
import { createSlice } from '@reduxjs/toolkit';

const invoiceSlice = createSlice({
    name: 'invoice',
    initialState: {
        selectedInvoice: null,
    },
    reducers: {
        setSelectedInvoices(state, action) {
            state.selectedInvoice = action.payload;
        },
    },
});

export const { setSelectedInvoices } = invoiceSlice.actions;
export default invoiceSlice.reducer;
