import React, { useState, useEffect } from 'react';
import AutoCompleteInput from './common/AutoCompleteInput';
import Modal from 'react-bootstrap/Modal';
import './multiLinking.scss';
import $ from 'jquery';
import { encodeParams } from '../../utils/UtilityFunctions';

const ModelSellerSelection = (props) => {
    const {
        setModel,
        handleSelectionConfirm,
        requestedDataFormat,
        company_id,
        setSelectedSeller,
        setFullResponse,
        gst_number,
        mobile_number,
        model,
    } = props;

    const [selectedOption, setSelectedOption] = useState('B2B/IMPGSEZ');
    const [selectedSellerLocal, setSelectedSellerLocal] = useState(''); // Local state to manage the selected seller
    const [fullResponseModel, setFullResponseModel] = useState(null);    // Local state to manage the full response
    const [error, setError] = useState(false);                          // Error state for validation

    useEffect(() => {
        if (!model) {
            $('body').removeClass('modal-open');
            $('.modal-backdrop').remove();
        }
    }, [model]);

    // Handle seller selection, but only update local state
    const handleSellerSelect = (sellerInfo, seller) => {
        setSelectedSellerLocal(sellerInfo);  // Only update local state
        setFullResponseModel(seller);        // Only update local state
        setError(false);                     // Clear any previous error

        const encodedSeller = encodeParams(seller);
        const keyseller = `selectedSeller_${mobile_number}_${gst_number}`;
        localStorage.setItem(keyseller, encodedSeller);
    };

    const handleClose = () => {
        setModel(false);  // Close modal
    };

    // Handle option change for the radio buttons
    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);    // Update selected option
        setSelectedSellerLocal('');           // Clear seller selection when option changes
        setFullResponseModel(null);           // Clear response model when option changes
        setError(false);                      // Clear error state
    };

    // Handle Proceed button click to confirm selection
    const handleProceed = () => {
        if (selectedOption === 'B2B/IMPGSEZ' && !selectedSellerLocal) {
            setError(true); // Show error if no seller is selected for B2B/IMPGSEZ
            return;
        }
        // Update parent state only when Proceed button is clicked
        handleSelectionConfirm(selectedOption, selectedSellerLocal);  // Pass the selected option and seller
        setFullResponse(fullResponseModel);                           // Update full response in parent state
        setSelectedSeller(selectedSellerLocal);                       // Update seller in parent state
        hidePopup();                                                  // Hide the modal and update local storage
    };

    // Function to hide the popup and update local storage
    const hidePopup = () => {
        const key = `selectedOption_${mobile_number}_${gst_number}`;
        const info_icon = $('.hideSellerSelectionPopup');
        if (info_icon.length > 0) {
            $('.multilinkingSellerSelection').animate(
                {
                    opacity: '0.5',
                    position: 'absolute',
                    height: '10px',
                    width: '10px',
                    top: '75px',
                    left: `${info_icon.offset().left + 100}px`,
                },
                700,
                () => {
                    localStorage.setItem(key, selectedOption);  // Store selected option in local storage
                    setModel(false);  // Close modal
                }
            );
        } else {
            localStorage.setItem(key, selectedOption);  // Store selected option in local storage
            setModel(false);  // Close modal
        }

      


    };

    return (
        <Modal
            show={model}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            className="multilinkingSellerSelection"
        >
            <div className="row">
                <div className="col-md-12">
                    <div className="d-flex justify-content-center">
                        <div className="card by2bproceed_boxbgclr" style={{ width: '100%' }}>
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    {/* Radio button for B2B/IMPGSEZ */}
                                    <div className="form-check-inline manytomany_radiobox pl-4">
                                        <label className="form-check-label" id='htm_multilinking-supplier_selection_popup_b2b_impgsez_lbl'>
                                            <input
                                                id='htm_multilinking-supplier_selection_popup_b2b_impgsez_radio'
                                                type="radio"
                                                className="form-check-input"
                                                name="b2b/impgsez"
                                                value="B2B/IMPGSEZ"
                                                checked={selectedOption === 'B2B/IMPGSEZ'}
                                                onChange={handleOptionChange}
                                            />
                                            B2B / IMPGSEZ
                                        </label>
                                    </div>
                                    {/* Radio button for IMPG */}
                                    <div className="form-check-inline manytomany_radiobox pl-4">
                                        <label className="form-check-label" id='htm_multilinking-supplier_selection_popup_b2b_impg_lbl'>
                                            <input
                                                id='htm_multilinking-supplier_selection_popup_b2b_impg_radio'
                                                type="radio"
                                                className="form-check-input"
                                                name="impg"
                                                value="IMPG"
                                                checked={selectedOption === 'IMPG'}
                                                onChange={handleOptionChange}
                                            />
                                            IMPG
                                        </label>
                                    </div>
                                </div>

                                {/* Show AutoCompleteInput only when B2B/IMPGSEZ is selected */}
                                <div className="col-md-12 text-center">
                                    {selectedOption !== 'IMPG' && (
                                        <div className="manytomany_rangedropbox" style={{ display: 'flex' }}>
                                            <div className="form-group col-md-12 mb-0 text-left">
                                                <AutoCompleteInput
                                                    company_id={company_id}
                                                    requestedDataFormat={requestedDataFormat}
                                                    gst_number={gst_number}
                                                    mobile_number={mobile_number}
                                                    handleSellerSelect={handleSellerSelect}
                                                    shouldSaveToLocalStorage={false}  // Prevent saving to localStorage
                                                    elementId={`htm_multilinking-supplier_selection_popup`}
                                                />
                                                {error && (
                                                    <span className="invalid-feedback" style={{ display: 'block' }}>
                                                        Please select a Supplier/ Import your Book!
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="col-md-12 text-center pt-3">
                                    {/* Proceed Button */}
                                    <button id='htm_multilinking-supplier_selection_popup_proceed_button' className="multiVerifyBtn btn btn-primary" onClick={handleProceed}>
                                        Proceed
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ModelSellerSelection;
