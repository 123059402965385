import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { ProgressBar } from '@progress/kendo-react-progressbars';
import { ChunkProgressBar } from "@progress/kendo-react-progressbars";
import { booksImportStatus, downloadBookTemplate, importBooks, deleteBook, getLastBookDataUpdatedDate, downloadBookPurchaseConsolidatedTemplate } from '../../api/booksAPI';
import { importConsolidatedPurchaseBooks } from '../../api/booksAPI';
import { syncStatus } from '../../api/2adataAPI';
import ShowAlert from '../../components/ShowAlert';
import Modal from 'react-bootstrap/Modal';
import { validateGstNumberInFileName } from "../../utils/UtilityFunctions";
import { useSelector } from 'react-redux';
import { selectHeaderData } from '../header/headerSlice';
import ConfirmPopup from '../../components/ConfirmPopup';
import PurchaseImportStatus from '../returnDashboard/PurchaseImportStatus';
import TallySyncPopup from '../tally/TallySyncPopup';
import './BooksDataV2.scss';
import tellyIcon from '../../images/tally_img.png';
import importExcelIcon from '../../images/excelimg.png';
import * as XLSX from 'xlsx';
import { useDropzone } from 'react-dropzone';
import downloadIcon from "../../images/download_down.png";
import excelFileIcon from "../../images/fileimg.png";
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';

const BooksDataV2 = (props) => {

    const { show, handleClose, company_id, filterYear, fetchData, postData, setSuccess, handleProcced } = props;
    const allowedExtensions = /(\.xlsx|\.xlsm|\.xls)$/i;
    const headerData = useSelector(selectHeaderData);
    const DIY = headerData.organization_type === "CORPORATE" ? true : false;
    const {
        isDragActive, acceptedFiles, getRootProps, getInputProps, isDragReject,
    } = useDropzone({
        accept: '.xls,.xlsx,.xlsm',
        maxFiles: 1, // Set the maximum number of files allowed to 1
    });
    const [bookList, setBookList] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [fileBookData, setFileBookData] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showPurchaseImportStatus, setShowPurchaseImportStatus] = useState(false);
    const [purchaseImportData, setPurchaseImportData] = useState([]);
    const [purchaseImportText, setPurchaseImportText] = useState([]);
    const [showValidateFileNameConfirm, setShowValidateFileNameConfirm] = useState(false);
    const [uploadBookData, setUploadBookData] = useState(false);
    const [successImport, setSuccessImort] = useState(null)
    //const excelTabLocalData = localStorage.getItem(`tally_sync_excel_tab_${headerData?.mobile_number}`)
    //const excelTabSelected = excelTabLocalData ? JSON.parse(excelTabLocalData)?.[headerData?.gst_number] ? true : false : false;
    const [tab, setTab] = useState("default");

    const currentMonth = parseInt(moment().format('M'));
    //eslint-disable-next-line
    const [lastUpdatedOn, setLastUpdatedOn] = useState(null);
    const [purchaseSheetCount, setPurchaseSheetCount] = useState(null);

    const [fileProcssingStatus, setFileProcssingStatus] = useState(false);

    const getBookList = (filterStr) => {
        async function fetchEverything() {
            async function fetchBookList() {
                const result = await booksImportStatus(filterStr);
                setBookList(result.data);
            }
            try {
                await Promise.all([
                    fetchBookList(),
                ]);
                setError(null);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        }
        setLoading(true);
        fetchEverything();
    };

    useEffect(() => {
        let filterstr = `?company_id=${company_id}&year=${filterYear}`
        getBookList(`${filterstr}`);
    }, [company_id, filterYear]);


    const getSyncStatus = (company_id) => {
        async function fetchSync() {
            async function fetchSyncStatus() {
                await syncStatus(company_id);
            }
            try {
                await Promise.all([
                    fetchSyncStatus(),
                ]);
                // setError(null);
            } catch (err) {
                // setError(err.message);
            }
        }
        fetchSync();
    };

    const downloadBook = () => {
        async function fetchEverything() {
            async function getBookTemplate() {
                await downloadBookTemplate();
            }
            try {
                await Promise.all([
                    getBookTemplate(),
                ]);
                setError(null);
            } catch (err) {
                setError(err.toString());
            } finally {
                setLoading(false);
            }
        }
        setLoading(true);
        fetchEverything();
    };

    const uploadFiles = async (book) => {
        const formData = new FormData();
        formData.append('file', book.file);
        formData.append('company_id', company_id);
        formData.append('year', book.year);
        formData.append('month', book.month);
        setFileProcssingStatus(true);
        try {
            // let result;
            // result = await importBooks(formData, book, startUploading);

            let result;
            if (purchaseSheetCount <= 1 && DIY) {
                result = await importConsolidatedPurchaseBooks(formData, book, startUploading);
            } else {
                result = await importBooks(formData, book, startUploading);
            }

            updateBookList(book, 'add');
            setPurchaseImportData(result.data);
            setPurchaseImportText(result.message);
            setShowPurchaseImportStatus(true);
        } catch (err) {
            if (err?.code === 504) {
                updateBookList(book, 'remove');

                setSuccessImort("Your imported file is being processed. Please refresh after sometime to view the updated data.");
                // document.getElementById('rm_purchsae_file_gstr2').click();
            } else {
                setError(err?.message);
            }
            updateBookList(book, '');
        } finally {
            setFileProcssingStatus(false)
        }
        setLoading(false);
        //setFileBookData(false);
    };

    const removeBookFile = async () => {
        setShowConfirm(false);
        setLoading(true);
        try {
            const result = await deleteBook(company_id, fileBookData.year, fileBookData.month);
            updateBookList(fileBookData, 'remove');
            refreshSupplierList(result.message);
            if (handleProcced) handleProcced(result.message);
            if (setSuccess) setSuccess(result.message)
        } catch (err) {
            setError(err.message);
        }
        setLoading(false);
        setFileBookData(false);
    };

    const refreshSupplierList = (message, refresh_status = false) => {
        let done = true;
        bookList.map((bookdata) => {
            if (bookdata.file_uploading && bookdata.file_uploading > 0 && bookdata.file_uploading < 100) {
                done = false;
            }
            return false
        });
        if (done) {
            // document.getElementById('book_modal_close').click();
            // setBookListPopup(false);
            handleClose();
            if (refresh_status) { getSyncStatus(company_id); }
            if (fetchData) fetchData(postData);
            if (handleProcced) handleProcced(message)
            if (setSuccess) setSuccess(message);
        }
    };

    const startUploading = (book, progress) => {
        const new_bookList = bookList.map((bookdata) => {
            if (bookdata.month === book.month && bookdata.year === book.year) {
                bookdata.file_uploading = progress;
            }
            return bookdata;
        });
        setBookList(new_bookList);
    };

    const updateBookList = (book, type) => {
        const new_bookList = bookList.map((bookdata) => {
            if (bookdata.month === book.month && bookdata.year === book.year) {
                if (type === 'add') {
                    bookdata.imported_on = moment().format('DD/MM/yyyy');
                    bookdata.import_status = 'PENDING';
                }
                if (type === 'remove') {
                    bookdata.imported_on = moment().format('DD/MM/yyyy');
                    bookdata.import_status = 'PENDING';
                }
                bookdata.file = null;
                bookdata.file_uploading = 0;
            }
            return bookdata;
        });
        setBookList(new_bookList);
    };

    const browseFile = (book) => {
        document.getElementById('book_file').click();
        book.file_uploading = 0;
        setFileBookData(book);
    };

    const onFileSelected = (event) => {
        const file = event.target.files[0];
        let valid = false;
        if (allowedExtensions.exec(file.name)) {
            valid = true;
        } else {
            setError('Import Format Supported: .xls, .xlsx, xlsm');
            valid = false;
            setFileBookData(false);
        }
        event.target.value = null;
        const new_bookList = bookList.map((bookdata) => {
            if (bookdata.month === fileBookData.month && bookdata.year === fileBookData.year) {

                if (file) {
                    const fileReader = new FileReader();
                    setLoading(true)

                    fileReader.onload = (event) => {
                        const buffer = event.target?.result;
                        const workbook = XLSX.read(buffer, { type: 'buffer' });
                        const sheets = workbook.SheetNames;
                        setLoading(false)
                        setPurchaseSheetCount((sheets.length - 1)); // minus count of default mastersheet 
                    };
                    // Read the file as an array buffer
                    fileReader.readAsArrayBuffer(file);
                }





                bookdata.file = valid ? file : null;
            }
            return bookdata;
        });
        setBookList(new_bookList);
    };






    const removeFile = (book) => {
        const new_bookList = bookList.map((bookdata) => {
            if (bookdata.month === book.month && bookdata.year === book.year) {
                bookdata.file = null;
                book.file_uploading = 0;
            }
            return bookdata;
        });
        setBookList(new_bookList);
    };

    const validateFileNameAndUpload = (book) => {
        const validateFile = validateGstNumberInFileName(book.file.name ?? '', headerData.gst_number ?? '');
        if (validateFile === false) {
            setUploadBookData(book)
            setShowValidateFileNameConfirm(true)
        } else {
            uploadFiles(book);
        }
    }

    const getLastUpdatedDate = async (company_id) => {
        try {
            setLoading1(true);
            const result = await getLastBookDataUpdatedDate(company_id);
            if (result?.data) {
                const date = moment(result?.data).format('DD/MM/yyyy')
                setLastUpdatedOn(date);
            }
            setError(null);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading1(false);
        }
    };

    useEffect(() => {
        getLastUpdatedDate(company_id);
    }, [company_id])

    const downloadBookGSTR2Lite = () => {
        async function fetchEverything() {
            async function getBookTemplate() {
                await downloadBookPurchaseConsolidatedTemplate();
            }
            try {
                await Promise.all([
                    getBookTemplate(),
                ]);
                setError(null);
            } catch (err) {
                setError(err.toString());
            } finally {
                setLoading(false);
            }
        }
        setLoading(true);
        fetchEverything();
    };

    useEffect(() => {
        //handleing drag and drop book file event
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            let valid = false;
            if (allowedExtensions.exec(file.name)) {
                valid = true;
            } else {
                setError('Import Format Supported: .xls, .xlsx, xlsm');
                valid = false;
            }
            if (valid) {
                const new_bookList = bookList.map((book) => {
                    if (book.month === currentMonth) {
                        if (file) {

                            const fileReader = new FileReader();
                            setLoading(true)

                            fileReader.onload = (event) => {
                                const buffer = event.target?.result;
                                const workbook = XLSX.read(buffer, { type: 'buffer' });
                                const sheets = workbook.SheetNames;
                                setLoading(false)
                                setPurchaseSheetCount((sheets.length - 1)); // minus count of default mastersheet 
                            };
                            // Read the file as an array buffer
                            fileReader.readAsArrayBuffer(file);
                        }
                        book.file = valid ? file : null;
                        setFileBookData(book);
                    }
                    return book;
                })
                setBookList(new_bookList);
            }
        }

        //eslint-disable-next-line
    }, [acceptedFiles]);

    return (
        <>

            {loading ? <LoadingIndicator /> : null}
            <ShowAlert
                error={null}
                success={successImport}
                onClose={() => {  setSuccessImort(null); }}
            />
            {
                showValidateFileNameConfirm &&
                <ConfirmPopup
                    show={showValidateFileNameConfirm}
                    handleProceed={() => {
                        uploadFiles(uploadBookData)
                        setShowValidateFileNameConfirm(false);
                    }}
                    confirmText={`It seems that the file you are trying to import belongs to a different GST Number. Do you still wish to continue importing the file ?`}
                    confirmButtonText="Upload"
                    handleClose={() => {
                        setShowValidateFileNameConfirm(false);
                    }}
                />
            }
            {showPurchaseImportStatus && (
                <PurchaseImportStatus
                    show={showPurchaseImportStatus}
                    onClose={() => {
                        setShowPurchaseImportStatus(false);
                        setPurchaseImportData([]);
                        if (fetchData) fetchData(postData);
                        if (handleProcced) handleProcced('', false)
                        handleClose();
                    }}
                    handleSucessProcees={() => {
                        setShowPurchaseImportStatus(false);
                        setPurchaseImportData([]);
                        if (fetchData) fetchData(postData);
                        if (handleProcced) handleProcced('')
                        handleClose();
                        window.location.reload();
                    }}
                    onReImport={() => {
                        setShowPurchaseImportStatus(false);
                        setPurchaseImportData([]);
                        document.getElementById('book_file').click();
                    }}
                    data={purchaseImportData}
                    purchaseImportText={purchaseImportText}
                />
            )}
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                id="gstr1leftsidem"
                className="gstr1leftsidem"
            >

                <Modal.Header closeButton className="gstr1leftsideh">
                    <h4 className="modal-title">Import Books Data</h4>
                </Modal.Header>

                <Modal.Body>                   

                    {( loading1) ?
                        <div className="k-loading-mask">
                            <span className="k-loading-text">Loading</span>
                            <div className="k-loading-image"></div>
                            <div className="k-loading-color"></div>
                        </div> : null}

                    <ShowAlert
                        success=""
                        error={error}
                        onClose={() => {
                            if (setSuccess) setSuccess(null);
                            if (setError) setError(null);
                        }}
                        headerStyle={{
                            position: 'absolute', top: 0, left: 0, width: '100%', fontSize: '13.5px',
                        }}
                    />
                    <ConfirmPopup
                        show={showConfirm}
                        handleProceed={() => removeBookFile()}
                        confirmText={`Are you sure that you want to delete the uploaded books data for the period ${fileBookData.tax_period}? Please note that this process is irreversible. Click Delete button to continue.`}
                        confirmButtonText="Delete"
                        handleClose={() => {
                            setFileBookData(false);
                            setShowConfirm(false);
                        }}
                    />
                    <input type="file" name="book_file" id="book_file" accept=".xls, .xlsx, .xlsm" onChange={onFileSelected} style={{ display: 'none' }} />

                    <div className="row">
                        <div className="col-md-12">
                            <div className="recodash_importbooksdata_popup">
                                <div class="row mb-2">
                                    <div class="col-md-5 text-center" onClick={() => { setTab('tally') }}>
                                        <div class="card recodash_importbooksdata_tallybox">
                                            <h4 class="recodash_tallywithconfig_titlefs">
                                                Configure with
                                            </h4>
                                            <span class="tally_imgbox">
                                                <img src={tellyIcon} width="80" alt='' />
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <h4 class="importtextortextfs">
                                            OR
                                        </h4>
                                    </div>
                                    <div class="col-md-5 text-center" onClick={() => { setTab('excel') }}>
                                        <div class="card recodash_importbooksdata_excelbox">
                                            <span class="excelbox_imgbox">
                                                <img src={importExcelIcon} width="60" alt='' />
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                {(tab === "tally") &&
                                    <TallySyncPopup companyId={company_id} />
                                }

                                {
                                    (tab === "excel") &&
                                    <>

                                        <div className='row mb-2'>
                                            <div class="col-lg-12 pt-2">
                                                <div class="row mt-2">
                                                    <div class="col-md-1">
                                                        <img src={downloadIcon} alt='download' width="22" class="cursorpointer" />
                                                    </div>
                                                    <div class="col-md-5">
                                                        <button class="btn btn-default recodash_gstr1blanktemp_btn" onClick={downloadBook}>
                                                            Blank Template
                                                        </button>
                                                    </div>
                                                    {
                                                        DIY &&
                                                        <>
                                                            <div class="col-md-1 text-center">
                                                                <span class="importOR_textfs">
                                                                    OR
                                                                </span>
                                                            </div>
                                                            <div class="col-md-5 text-right">
                                                                <button class="btn btn-default recodash_gstr1blanktemp_btn" onClick={downloadBookGSTR2Lite}>
                                                                    Template with PAN Level
                                                                </button>
                                                            </div>
                                                        </>
                                                    }
                                                </div>

                                                {
                                                    bookList.map((book, index) => {
                                                        if (book.month === currentMonth) {
                                                            return (
                                                                <>
                                                                    <div class="row mt-3">
                                                                        <div class="col-md-1">
                                                                            <img src={downloadIcon} alt='download' width="22" class="cursorpointer" />
                                                                        </div>
                                                                        <div class="col-md-5">
                                                                            <button class="btn btn-default recodash_gstrdropfile_btn" >
                                                                                <div {...getRootProps({ className: 'dropzone dropzonestyle', onClick: event => event.stopPropagation() })}>
                                                                                    <input id="book_file" {...getInputProps({ accept: '.xls,.xlsx,.xlsm', multiple: false })} />
                                                                                    <span className="font11Center">
                                                                                        {!isDragActive && 'Drag & Drop File Here'}
                                                                                        {isDragActive && !isDragReject && ' Drag & Drop File Here'}
                                                                                        {isDragReject && 'Invalid File Type!'}
                                                                                    </span>
                                                                                </div>
                                                                            </button>
                                                                        </div>
                                                                        <div class="col-md-1 text-center">
                                                                            <span class="importOR_textfs">
                                                                                OR
                                                                            </span>
                                                                        </div>
                                                                        <div class="col-md-5 text-right">
                                                                            <button class="btn btn-default recodash_gstr1blanktemp_btn" onClick={() => browseFile(book)} >
                                                                                Browse Files
                                                                            </button>
                                                                        </div>
                                                                    </div>

                                                                    <div class="row mt-3">
                                                                        <>
                                                                            <div class="col-md-1">
                                                                                <img src={excelFileIcon} alt='excel file' width="22" class="cursorpointer" />
                                                                            </div>
                                                                            <div class="col-md-11">
                                                                                <button class="btn btn-default recodash_filename_btn" style={{ minHeight: '30px' }}>
                                                                                    {
                                                                                        book.file
                                                                                            ?
                                                                                            <>
                                                                                                {book.file_uploading && book.file_uploading > 0
                                                                                                    ?
                                                                                                    book.file_uploading && book.file_uploading === 100 ?
                                                                                                        <>
                                                                                                            <ChunkProgressBar
                                                                                                                min={1} max={50}
                                                                                                                value={null}
                                                                                                                chunkCount={10}
                                                                                                                style={{ width: '100%' }}
                                                                                                                label={(props) => (
                                                                                                                    <strong> Processing </strong>
                                                                                                                )}
                                                                                                            />
                                                                                                            <span className="import_processing_text">Processing</span>
                                                                                                        </>
                                                                                                        :
                                                                                                        <ProgressBar
                                                                                                            value={book.file_uploading}
                                                                                                            style={{ width: '100%' }}
                                                                                                            labelPlacement="center"
                                                                                                            label={(props) => (
                                                                                                                <strong> Uploading..{props.value}%
                                                                                                                </strong>
                                                                                                            )}
                                                                                                        />
                                                                                                    : <>
                                                                                                        {/* {book.file.name} */}
                                                                                                        <span title={`${book.file.name.length > 45 ? book.file.name : ''}`}>
                                                                                                            {book.file.name.length > 45 ? `${book.file.name.substr(0, 45)}...` : book.file.name}
                                                                                                        </span>

                                                                                                        <span class="btn btn-default recodash_filenameremove_btn ml-1" onClick={() => removeFile(book)}>
                                                                                                            Remove
                                                                                                        </span>
                                                                                                    </>
                                                                                                }
                                                                                            </>
                                                                                            : null
                                                                                    }
                                                                                </button>
                                                                            </div>
                                                                        </>
                                                                    </div>

                                                                    <div class="col-lg-12">
                                                                        <p class="importformat_textfs mb-1">
                                                                            Import Format Supported: .xlsx, .xls, .xlsm
                                                                        </p>
                                                                        <p class="recodash_notetextfs">
                                                                            Note: We support Govt. and all Accounting Soft. Templates
                                                                        </p>
                                                                    </div>

                                                                    <div class="col-md-12 text-center pt-2">
                                                                        {
                                                                            (fileBookData?.file?.name && fileProcssingStatus === false)
                                                                                ?
                                                                                <>
                                                                                    <button class="btn btn-default recodash_importbooksdata_submitbtn" onClick={() => validateFileNameAndUpload(book)}>
                                                                                        Import
                                                                                    </button>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <button class="btn btn-default recodash_importbooksdata_submitbtn disabled">
                                                                                        Import
                                                                                    </button>
                                                                                </>
                                                                        }
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                        return null;
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );
};
export default BooksDataV2;
