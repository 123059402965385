import React from 'react';

const CheckboxCell = (props) => {
    const { dataItem, handleCheckboxChange, disabled = false } = props;

    return (
        <td className="align-center">
            <input
                type="checkbox"
                checked={dataItem.isChecked || false}
                onChange={(e) => handleCheckboxChange(e, dataItem)}
                disabled={disabled}
            />
        </td>
    )
}

export default CheckboxCell;
