/**
 * SalesIQ Chat configuration by default [KDKMKT 072023]
 */
const salesIqConfig = `
 var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: 'siqf32ac63a8099b5e4c04697924b2f11b1521604b76365d14eed9d397e27c1613b', values:{},ready:function(){}};
 var d=document;s=d.createElement('script');s.type='text/javascript';s.id='zsiqscript';s.defer=true;s.src='https://salesiq.zohopublic.com/widget';t=d.getElementsByTagName('script')[0];t.parentNode.insertBefore(s,t);
`;
/**
 * createSalesIqScript function that creates a new <script> element with provided id and code
 * @param {string} id - The id attribute for the new <script> element.
 * @param {string} code - The JavaScript code to be set as the content of the <script> element.
 * Function to create and append script elements
 */
function createScript(id, code) {
  // Check if an element with the given ID already exists
  const existingScript = document.getElementById(id);
  if (existingScript) {
    // If it exists, update its content
    existingScript.textContent = code;
    //console.log('existingScript ' + id);
    return; // Exit the function to prevent creating a new script element
  }
  // If it doesn't exist, create a new script element
  const script = document.createElement("script");
  script.id = id;
  script.async = true;
  script.textContent = code;
  document.head.appendChild(script);
  //console.log('call ' + id);
}

/**
 * Function to store customer data and trigger custom action
 */
export function salesiq_to_crm(
  cust_name,
  cust_email,
  cust_mobile,
  cust_industry,
  cust_pincode,
  cust_utm_source
) {
  const industry_type =
    cust_industry === "CA" ? "CA/CS/Tax Professionals" : "Corporate/SME";
  const custDB = {
    SalesIq_status: true,
    name: cust_name,
    email: cust_email,
    mobile: cust_mobile,
    industry: industry_type,
    pincode: cust_pincode,
    utm_source: cust_utm_source,
  };

  const jsonObj_custDB = JSON.stringify(custDB);
  localStorage.setItem("Salesiq_custDB", jsonObj_custDB);

  createScript(
    "salesiq_script_trg",
    "$zoho.salesiq.visitor.customaction('Singup_sales_iqupdate');"
  );
}

/**
 * Function to turn off the SalesIQ chat button and tracking
 */
export function salesiq_chatoff() {
  localStorage.setItem("salesiq_chatoff", true);
  createScript(
    "salesiq_chatoff",
    "$zoho.salesiq.floatbutton.visible('hide'); $zoho.salesiq.tracking.off();"
  );
}

/**
 * Function to turn on the SalesIQ chat button and tracking
 */
export function salesiq_chaton() {
  createScript(
    "salesiq_chaton",
    "$zoho.salesiq.floatbutton.visible('show'); $zoho.salesiq.tracking.on();"
  );
}

/**
 * Function to initialize SalesIQ with provided configuration
 */
export function initializeSalesIq() {

  createScript("initializeSalesIq", salesIqConfig);
  initializeSalesIqCustomField();

  if (localStorage.getItem("salesiq_chatoff")) {
    localStorage.removeItem("salesiq_chatoff");
    salesiq_chaton();
  }
}

/**
 * Function to initialize SalesIQ custom fields
 */
export function initializeSalesIqCustomField() {
  createScript(
    "initializeSalesIqCustomField",
    `
    $zoho.salesiq.ready = function () {
    
    $zoho.salesiq.visitor.trigger(function(triggername, visitorinfo) {
      if (triggername === 'Singup_sales_iqupdate') {
        const siq_custDB = localStorage.getItem("Salesiq_custDB");
        const g = JSON.parse(siq_custDB);
        const SalesIqCheck = g.SalesIq_status;
        if (SalesIqCheck) {
          $zoho.salesiq.visitor.name(g.name);
          $zoho.salesiq.visitor.email(g.email); 
          $zoho.salesiq.visitor.contactnumber(g.mobile); 
          $zoho.salesiq.visitor.info({
            "Industry": g.industry,
            "Pin Code": g.pincode,
            "Rating": "Hot",
            "Description": \`Express GST Signup data Submit with Industry=\${g.industry} & Campaign Source=\${g.utm_source}\`
          });
          localStorage.removeItem("Salesiq_custDB");
           
        } 
      }
    });
  
    $zoho.salesiq.customfield.add({
      name: 'Pin Code',
      hint: 'Pin Code',
      required: true,
      maxlength: 6,
      minlength: 6,
      type: 'number',
      visibility: 'both',
    });
  
    $zoho.salesiq.customfield.add({
      name: 'Industry',
      hint: 'Industry',
      type: 'radio',
      options: [
        {
          text: 'CA/CS/Tax Professionals',
          value: 'CA/CS/Tax Professionals',
        },
        {
          text: 'Corporate/SME',
          value: 'Corporate/SME',
        },
      ],
      required: true,
      visibility: 'both',
    });
  };
    
 `
  );
}
