import axios from 'axios';
import { BASE_URL, BASE_URL_GO } from '../app/constants';
import { handleAllSuccess, handleSuccess, handleErrorWithCode, handlePublicURLError } from './responseHandler';
import { getConfig, getLoadBalancedApiBaseUrl } from './common';

export async function verify2ADataCatcha(data) {
  const url = `${getLoadBalancedApiBaseUrl()}/api/v1/gstportal/start-sync`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return result.data;
  } catch (err) {
    // throw handleErrorWithCode(err);
    throw err.response;

  }
}

export async function syncStatus(company_id) {
  const url = `${BASE_URL_GO}/api/v1/gstportal/sync-status?company_id=${company_id}`;
  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleAllSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function get2A2BMailSyncCaptcha(token) {
  const url = `${getLoadBalancedApiBaseUrl()}/api/v1/pub-auth/gstportal/captcha`;
  try {
    const result = await axios.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handlePublicURLError(err);
  }
}

export async function verify2A2BMailSyncCatpcha(data, token) {
  const url = `${getLoadBalancedApiBaseUrl()}/api/v1/pub-auth/gstportal/reco-2a-2b/start-sync-email`;
  try {
    const result = await axios.post(url, data, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return result.data;
  } catch (err) {
    throw handlePublicURLError(err);
  }
}

export async function getPublicAuthSyncStatus(token) {
  const url = `${getLoadBalancedApiBaseUrl()}/api/v1/pub-auth/gstportal/sync-status`;
  try {
    const result = await axios.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleAllSuccess(result.data);
  } catch (err) {
    throw handlePublicURLError(err);
  }
}
export async function syncStatusWithoutSettingHeader(company_id) {
  const url = `${BASE_URL_GO}/api/v1/gstportal/sync-status?company_id=${company_id}`;
  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}
export async function get2ADataVerifyCatchaV2(company_id) {
  const url = `${getLoadBalancedApiBaseUrl()}/api/v2/gstportal/captcha?company_id=${company_id}`;
  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}
export async function getUserCredentials(company_id) {
  const url = `${BASE_URL}/api/v2/gstportal/credentials?company_id=${company_id}`;
  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function verify2ADataV2(data) {
  const url = `${BASE_URL_GO}/api/v1/usuc/gstportal/start-sync`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return result;
  } catch (err) {
    // throw handleErrorWithCode(err);
    throw err.response;

  }
}
