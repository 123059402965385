import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

const IRNInfo = ({ irn_no }) => {
    const [copyIRNNumber, setCopyIRNNumber] = useState(false);

    const handleCopy = () => {
        if (irn_no) {
            navigator.clipboard.writeText(irn_no);
            setCopyIRNNumber(true);
        }
    };

    const truncatedIrnNo = irn_no ? `${irn_no.substring(0, 5)}...` : '-';

    return (
        <div>
            {irn_no ? (
                <>
                    <span title={irn_no}>{truncatedIrnNo}</span>
                    <span
                        onClick={handleCopy}
                        style={{ color: copyIRNNumber ? '#808080' : '#31719b', cursor: 'pointer', marginLeft: '5px' }}
                    >
                        <FontAwesomeIcon icon={faCopy} title={copyIRNNumber ? 'Copied' : 'Copy text'} />
                    </span>
                </>
            ) : (
                <span>-</span>
            )}
        </div>
    );
};

export default IRNInfo;
