import { Skeleton } from '@mui/material'
import React from 'react'

const Skeleton12ColumnGrid = (props) => {

  let rowNo = props?.rowNo ?? 5


  return (<>
    { 
      props?.invoiceReco && <div className="row justify-content-around">
      <div className="col-4" style={{ "textAlign": "-webkit-right" }}>
        <Skeleton animation="wave" variant="text" width={"25%"} height={"40px"} />
      </div>
      <div className="col-4" style={{ "textAlign": "-webkit-center" }}>
        <Skeleton animation="wave" variant="text" width={"25%"} height={"40px"} />
      </div>
    </div>}

    <br />

    <div className=" row ">
      <div className='col-md-1'>
        {[...Array(rowNo)].map((items) => <Skeleton animation="wave" variant="text" width={"100%"} height={"40px"} />)}
      </div>
      <div className='col-md-1'>
        {[...Array(rowNo)].map((items) => <Skeleton animation="wave" variant="text" width={"100%"} height={"40px"} />)}
      </div>
      <div className='col-md-1'>
        {[...Array(rowNo)].map((items) => <Skeleton animation="wave" variant="text" width={"100%"} height={"40px"} />)}
      </div>
      <div className='col-md-1'>
        {[...Array(rowNo)].map((items) => <Skeleton animation="wave" variant="text" width={"100%"} height={"40px"} />)}
      </div>
      <div className='col-md-1'>
        {[...Array(rowNo)].map((items) => <Skeleton animation="wave" variant="text" width={"100%"} height={"40px"} />)}
      </div>
      <div className='col-md-1'>
        {[...Array(rowNo)].map((items) => <Skeleton animation="wave" variant="text" width={"100%"} height={"40px"} />)}
      </div>
      <div className='col-md-1'>
        {[...Array(rowNo)].map((items) => <Skeleton animation="wave" variant="text" width={"100%"} height={"40px"} />)}
      </div>
      <div className='col-md-1'>
        {[...Array(rowNo)].map((items) => <Skeleton animation="wave" variant="text" width={"100%"} height={"40px"} />)}
      </div>
      <div className='col-md-1'>
        {[...Array(rowNo)].map((items) => <Skeleton animation="wave" variant="text" width={"100%"} height={"40px"} />)}
      </div>
      <div className='col-md-1'>
        {[...Array(rowNo)].map((items) => <Skeleton animation="wave" variant="text" width={"100%"} height={"40px"} />)}
      </div>
      <div className='col-md-1'>
        {[...Array(rowNo)].map((items) => <Skeleton animation="wave" variant="text" width={"100%"} height={"40px"} />)}
      </div>
      <div className='col-md-1'>
        {[...Array(rowNo)].map((items) => <Skeleton animation="wave" variant="text" width={"100%"} height={"40px"} />)}
      </div></div>
  </>
  )
}

export default Skeleton12ColumnGrid