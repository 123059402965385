import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import Exgstlogo from '../../images/logo/exgstlogo.svg';
import ExgstDiwalilogo from '../../images/logo/exgstlogo_diwali.svg';
import Abv1logo from '../../images/logo/abv1logo.svg';
import Abv2logo from '../../images/logo/abv2logo.svg';
import {
  BASE_PATH, LOGO
} from '../../app/constants';

// Modify Logo, as per Cobrand identified. If not, default to Express GST Logo
let logo_touse = Exgstlogo;
const logo_uc = LOGO.toUpperCase();
if (logo_uc === "ABV1") {
  logo_touse = Abv1logo;
} else if (logo_uc === "ABV2") {
  logo_touse = Abv2logo;
} else if (logo_uc === "EXGSTDIWALI") {
  logo_touse = ExgstDiwalilogo;
}

const PublicHeader = ({ history }) => {
  return (
    <header id="header">

      <nav className="navbar-header logo_width_mobile30 col-md-4 col-sm-4 col-lg-5 col-xl-5 col-xs-12">
        <div className="container-fluid">
          {/* left side col-6 */}
          <div
            className="navbar-header logo_width_mobile30 col-md-4 col-sm-4 col-lg-5 col-xl-5 col-xs-12"
          >            <div className="headerlogodispinle">
              <Link className="navbar-brand mainexplogo" to={`${BASE_PATH}`}>
                {
                  logo_touse === ExgstDiwalilogo
                    ? <object style={{ pointerEvents: "none" }} type="image/svg+xml" data={ExgstDiwalilogo} aria-labelledby="Express GST" width="120" height="45"></object>
                    : <img src={logo_touse} alt="Express GST" />
                }
              </Link>

            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default PublicHeader;
