import localforage from "localforage";
import { TASK_QUEUE } from "../app/constants";
import store from "../app/store";
import { updateTaskQueue as updateTaskQueueInStore } from "../features/TaskQueue/TaskQueueSlice";
import moment from 'moment';

export async function getTaskQueue(userId) {
  try {
    const taskQueue = await localforage.getItem(TASK_QUEUE) || {};
    return taskQueue[userId] || {};
  } catch (err) {
    throw err;
  }
}

export async function setTaskQueue(userId, data) {
  try {
    const taskQueue = await localforage.getItem(TASK_QUEUE) || {};
    taskQueue[userId] = data;
    await localforage.setItem(TASK_QUEUE, taskQueue);
    return true;
  } catch (err) {
    throw err;
  }
}

export async function initTaskQueue(userId) {
  try {
    const taskQueue = await getTaskQueue(userId);
    store.dispatch(updateTaskQueueInStore(taskQueue));
  } catch (err) {
    console.error("Error in adding task: ", err);
  }
}

export async function addTask(userId, task) {
  const uniqueId = `${task.gst_number}_${task.process_type}_${task.return_month}_${task.return_year}`;
  task['uniqueId'] = uniqueId;
  task['last_updated_at'] = moment().format();
  task['is_opened'] = false;

  try {
    const taskQueue = await getTaskQueue(userId);
    taskQueue[uniqueId] = task;
    setTaskQueue(userId, taskQueue);
    store.dispatch(updateTaskQueueInStore(taskQueue));
  } catch (err) {
    console.error("Error in adding task: ", err);
  }
}

export async function updateTask(userId, task) {
  try {
    const taskQueue = await getTaskQueue(userId);
    taskQueue[task.uniqueId] = task;
    setTaskQueue(userId, taskQueue);
    store.dispatch(updateTaskQueueInStore(taskQueue));
  } catch (err) {
    console.error("Error in adding task: ", err);
  }
}

export async function removeTask(userId, task) {
  try {
    const taskQueue = await getTaskQueue(userId);
    delete taskQueue[task.uniqueId];
    setTaskQueue(userId, taskQueue);
    store.dispatch(updateTaskQueueInStore(taskQueue));
  } catch (err) {
    console.error("Error in adding task: ", err);
  }
}

