import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    companyId: null,
    year: null,
};

const paramsSlice = createSlice({
    name: 'params',
    initialState,
    reducers: {
        setCompanyId(state, action) {
            state.companyId = action.payload;
        },
        setYear(state, action) {
            state.year = action.payload;
        },
    },
});

export const { setCompanyId, setYear } = paramsSlice.actions;

// Selectors
// export const selectCompanyId = state => state.params.companyId;
// export const selectYear = state => state.params.year;

export default paramsSlice.reducer;
