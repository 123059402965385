
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */

import React, { useEffect, useRef, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ShowAlert.css';


export default function ShowAlert(props) {
  const { success, error, onClose, warning, autoHideTime = 3000, autoHide = true,  position = "bottom-right", theme = "colored", posStyle=null } = props;
  
  let showToast = useRef();
   const [show, setShow] = useState(null);

  useEffect(() => {
    if (warning || error || success) {

      if (success) {
        toast.success(success, {
          position: position,
          autoClose: autoHide ? autoHideTime : false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: theme,
          toastId: "toastSuccess",
          onClose: () => onClose(),
        });
      } else if (error) {
        toast.error(error, {
          position: position,
          autoClose: autoHide ? autoHideTime : false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: theme,
          toastId: "toastError",
          onClose: () => onClose(),
        });
      } else if (warning) {
        toast.warn(warning, {
          position: position,
          autoClose: autoHide ? autoHideTime : false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: theme,
          toastId: "toastWarning",
          onClose: () => onClose(),
        });
      }
      
      showToast.current =( <ToastContainer
        position={position}
        autoClose={autoHide ? autoHideTime : false}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={theme}
      />)
      setShow(showToast);
      
    }
    //eslint-disable-next-line
  }, [success, error, warning]);



  return (<>
    {posStyle ? 
    <div className={posStyle}>{show?.current}</div>
     :
     <>{show?.current}</>
    }
    </>)
}
