/* eslint-disable array-callback-return */
import { createSlice } from '@reduxjs/toolkit';

const initialData = {
};
export const tallySyncStatusSlice = createSlice({
  name: 'tallySync',
  initialState: initialData,
  reducers: {
    setFieldValue: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    setTallySyncStatus: (state, action) => {
      Object.keys(action.payload).map((key) => {
        state[key] = action.payload[key];

      });
    },
  },
});

export const { setFieldValue, setTallySyncStatus } = tallySyncStatusSlice.actions;
export const selectTallySyncStatusData = state => state.tallySync;
export default tallySyncStatusSlice.reducer;
