/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { initTaskQueue, updateTask, getTaskQueue } from '../../utils/taskQueueHelper';
import { selectHeaderData } from '../header/headerSlice';
import { getFilingProcessSaveStatus } from '../../api/gstr1PortalAPI';
import moment from 'moment';

const TaskQueue = () => {
  const headerData = useSelector(selectHeaderData);

  const fetchAndUpdateTaskStatus = async (task) => {
    try {
      const result = await getFilingProcessSaveStatus(`company_id=${task.company_id}&return_year=${task.return_year}&return_month=${task.return_month}`);
      let response = result.data;
      if (response) {
        const newTask = { ...task, ...response, last_updated_at: moment().format() };
        updateTask(headerData.mobile_number, newTask);
      }
    } catch (err) {
      console.error('error in fetching task status: ', err);
    }
  }

  const checkTasksStatus = (taskQueue) => {
    taskQueue.forEach(async (task) => {
      if (task.process_status === 'IN_PROGRESS') {
        await fetchAndUpdateTaskStatus(task);
      }
    });
  }
  
  useEffect(() => {
    if (headerData.mobile_number) {
      initTaskQueue(headerData.mobile_number);
    }

    const checkStatusOfTasks = async () => {
      try {
        if (headerData.mobile_number) {
          const taskQueue = await getTaskQueue(headerData.mobile_number);
          checkTasksStatus(Object.values(taskQueue));
        }
      } catch (err) {
        console.error("Error in fetching tasks status: ", err);
      }
    }
    checkStatusOfTasks();
    const statusTask = setInterval(checkStatusOfTasks, 1000*60);

    return () => {
      if (statusTask) {
        clearInterval(statusTask);
      }
    }
  }, [headerData.mobile_number]);

  return (null);
}

export default TaskQueue;
