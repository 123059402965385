/* eslint-disable no-useless-catch */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-throw-literal */
import axios from "axios";
import { handleConnectorError } from "./responseHandler";
import { getConfig, getConnectorConfig } from "./common";

const connectorError =
  "Unable to connect to the Express Connector. Please ensure that the Expressor Connector is running. If the problem still persists, then Close the Browser completely and restart.";

export async function getConnectionStatus(port) {
  if (!port) throw { code: 404, message: connectorError };

  const url = `http://127.0.0.1:${port}/api/v1/status`;

  try {
    const result = await axios.get(url, { ...getConfig(), getConnectorConfig });

    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);

    if (response.code === 404) {
      throw { code: 404, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function startAction(port, data, headers) {
  const url = `http://127.0.0.1:${port}/api/v1/gui/action/perform`;

  try {
    const result = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers,
      },
    });
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 401 || response.code === 404) {
      throw { code: response.code, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function getActionStatus(port, action_id) {
  const url = `http://127.0.0.1:${port}/api/v1/gui/action/status?action_id=${action_id}`;

  try {
    const result = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return { data: result.data, headers: result.headers };
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 401 || response.code === 404) {
      throw { code: response.code, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function performTallyAction(port, data, extraHeaders) {
  const url = `http://127.0.0.1:${port}/api/v1/gst/tally/perform-action`;

  try {
    const result = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...extraHeaders,
      },
    });
    return { data: result.data, headers: result.headers }
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 401 || response.code === 404) {
      throw { code: response.code, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function getTallyStatus(port, processId) {
  const url = `http://127.0.0.1:${port}/api/v1/process-status?process_id=${processId}`;

  try {
    const result = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return { data: result.data, headers: result.headers }
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 401 || response.code === 404) {
      throw { code: response.code, message: connectorError };
    } else {
      throw response;
    }
  }
}


export async function getCaptchaCaptchaFromConnector(port, data, extraHeaders) {
  const url = `http://127.0.0.1:${port}/api/v1/gui/action/captcha`;

  try {
    const result = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...extraHeaders,
      },
    });
    return { data: result.data.data, headers: result.data.headers }
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 401 || response.code === 404) {
      throw { code: response.code, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function performGstR1PdfConnector(port, data, extraHeaders) {
  const url = `http://127.0.0.1:${port}/api/v1/gui/action/perform-with-captcha`;
  try {

    const result = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",        
        ...extraHeaders,           
      },
    });
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 401 || response.code === 404) {
      throw { code: response.code, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function performGstR1PdfConnectorV2(port, data, extraHeaders) {
  const url = `http://127.0.0.1:${port}/api/v1/gui/action/perform-with-ui`;
  try {

    const result = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",        
        ...extraHeaders,           
      },
    });
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 401 || response.code === 404) {
      throw { code: response.code, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function downloadR1PdfFile(port, captchaRef) {
  const url = `http://127.0.0.1:${port}/api/v1/gui/action/download-pdf?captcha_ref=${captchaRef}`;

  try {
    const result = await axios.get(url, {
      responseType: 'blob'  ,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
         
      },
    });

    return { data: result.data, headers: result.headers }
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 401 || response.code === 404) {
      throw { code: response.code, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function getCaptchaFromConnector(port) {
  if (!port) throw { code: 404, message: connectorError };

  const url = `http://127.0.0.1:${port}/api/v1/gst/portal/captcha`;

  try {
    const result = await axios.get(url);
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 404) {
      throw { code: 404, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function verifyGSTPortalLogin(port, data) {
  const url = `http://127.0.0.1:${port}/api/v1/gst/portal/login`;

  try {
    const result = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return { data: result.data, headers: result.headers }
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 401 || response.code === 404) {
      throw { code: response.code, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function verifyGSTPortalLoginWithOTP(port, data) {
  const url = `http://127.0.0.1:${port}/api/v1/gst/portal/login-with-otp`;

  try {
    const result = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return { data: result.data, headers: result.headers }
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 401 || response.code === 404) {
      throw { code: response.code, message: connectorError };
    } else {
      throw response;
    }
  }
}


export async function startSyncAction(port, data, headers) {
  const url = `http://127.0.0.1:${port}/api/v1/gst/portal/start-sync`;

  try {
    const result = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers,
      },
    });
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 401 || response.code === 404) {
      throw { code: response.code, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function addCompanyByConnector(port, data, headers) {
  const url = `http://127.0.0.1:${port}/api/v1/gst/portal/add-company`;

  try {
    const result = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers,
      },
    });
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 401 || response.code === 404) {
      throw { code: response.code, message: connectorError };
    } else {
      throw response;
    }
  }
}

