/* eslint-disable react/prop-types */
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './ConfirmPopup.css';
function ConfirmPopup(props) {
  const {
    show, handleClose, handleProceed, confirmText, confirmButtonText,cancelButtonText, showConfrimBtn = true, textClass
  } = props;

  return (
    <Modal className="areusuredelmbox" show={show} onHide={handleClose}>
      <Modal.Body><p className={textClass ?? ""}>{confirmText ?? 'Are you sure ?'}</p></Modal.Body>
      <Modal.Footer className="areusuredelmboxF">
        <Button variant="secondary" className="confirpopupcancelbtn" onClick={handleClose}>
          {cancelButtonText ?? "Cancel"}
        </Button>
       {showConfrimBtn && <Button variant="danger" className="confirpopupdelbtn" onClick={handleProceed}>
          {confirmButtonText ?? 'Confirm'}
        </Button> }
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmPopup;
