import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import ShowAlert from '../ShowAlert';
import './Profile.css';

const OtpVerifiyForCaptcha = (props) => {
    const { handleClose, callFunc, error, setError } = props;
    const [otp, setOtp] = useState("");
    return (
        <Modal className="gst_verifyyremailid_modal" backdrop="static" show={true} onHide={handleClose}>
            <Modal.Header closeButton className="gst_verifyyremailid_header">
                <Modal.Title className="gst_verifyyremailid_title" >{`Verify OTP`}</Modal.Title>
            </Modal.Header>
            <ShowAlert
                error={error}
                onClose={() => { setError(null); }}
                autoHide={false}
                headerStyle={{
                    position: "initial", textAlign: "left",
                    width: "100%"
                }}
            />
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <div className="form-group mb-2">
                            <input type="text" className="form-control gst_verifyyremailid_inptfs" maxLength={6} placeholder="Enter OTP" onChange={(e) => setOtp(e.target.value)} value={otp} />
                        </div>
                    </div>
                    <div className="col-md-12 text-center">
                        <button className={`gst_verifyyremailid_emailidbgclr ${!otp ? "emailopacity" : ""}`} disabled={!otp ? true : false} onClick={() => callFunc({ otp: otp })} >Verify OTP</button>
                    </div>

                </div>
            </Modal.Body>

        </Modal>
    )
}

export default OtpVerifiyForCaptcha
