import React, { useState, useEffect } from 'react';
import "./FindGSTINsLinedPANModal.css";
import { getGSTINsFromPan } from "../../api/companyAPI";
import { STATE_UT_LIST } from "../../app/constants";
import ShowAlert from "../../components/ShowAlert";
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { CustomCell } from "../../components/grid/Cell";
import { ColumnMenu } from "../../components/grid/ColumnMenu";
import WindowWrapperFixWidth from "../gstr9/sales/reports/WindowWrapperFixWidth";
import { process } from "@progress/kendo-data-query";
import { deepClone } from "../../utils/UtilityFunctions";


const FindGSTINsLinkedPANModal = (props) => {

    const { onHide, setLoading, company_id, pan } = props;

    let changedPan = pan.substring(2, 12);

    const initialDataState = {
        skip: 0,
        take: 16,
    };

    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [dataState, setDataState] = useState(initialDataState);
    const [fixedData, setFixedData] = useState([]);
    const [summary, setSummary] = useState(0);


    const getGSTINsData = () => {
        const request = {
            "pan": changedPan,
            "company_id": company_id
        }
        async function fetchData() {
            try {
                const result = await getGSTINsFromPan(request);

                if (result?.data?.list && result?.data?.list.length > 0) {

                    let activeResult = [];
                    let inActiveResult = [];
                    result?.data?.list.forEach((item) => {
                        if (item?.authStatus === "Active") activeResult.push(item);
                        else if (item?.authStatus === "Inactive") inActiveResult.push(item);

                        item.display_name = getStateLabel(item?.stateCd ?? '')
                    })
                    activeResult.sort((a, b) => {
                        a = a.display_name.replaceAll(" ", "").toLowerCase()
                        b = b.display_name.replaceAll(" ", "").toLowerCase()
                        return a.localeCompare(b)
                    });

                    inActiveResult.sort((a, b) => {
                        a = a.display_name.replaceAll(" ", "").toLowerCase()
                        b = b.display_name.replaceAll(" ", "").toLowerCase()
                        return a.localeCompare(b)
                    });

                    setData([...activeResult, ...inActiveResult])
                    setFixedData(JSON.parse(JSON.stringify([...activeResult, ...inActiveResult])));
                    setSummary(result?.data?.list.length ?? 0)
                }
            } catch (err) {
                setError(err.message)
            } finally {
                setLoading(false);
            }
        }
        setLoading(true);
        fetchData();
    };

    const getStateLabel = (code) => {
        let val = ""
        if (STATE_UT_LIST?.length > 0) {
            STATE_UT_LIST.forEach((item) => {
                if (item?.value === code) {
                    val = item?.label.substring(3,)
                }
            })
            return val
        }
    };

    const renderSubTextTitle = (title, subText) => {
        return (
            <div>
                {title} {' '}
                {subText !== '' && (<div className={"text-center"}>
                    <span className="b2btbtrthtextclr">{Number(subText)}</span>
                </div>)}
            </div>
        );
    };

    const dataStateChange = (data_state) => {

        if (data_state.filter || (data_state.sort && data_state?.sort?.length > 0)) {

            const filter_state = deepClone(data_state);
            if (filter_state?.filter?.filters?.length > 0) {

                filter_state.filter.filters.forEach((item) => {
                    if (item?.filters?.length > 0) {
                        item.filters.forEach((itemObj) => {
                            if (["in"].includes(itemObj?.operator) && Array.isArray(itemObj.value)) {
                                let array = []
                                item.logic = "or"
                                if (itemObj?.value?.length > 0) {
                                    itemObj?.value.forEach((keyValue) => {
                                        if (itemObj.field === 'authStatus') {
                                            array.push({ field: itemObj.field, operator: 'eq', value: keyValue })
                                        }
                                        else {
                                            array.push({ field: itemObj.field, operator: 'contains', value: keyValue })
                                        }
                                    })
                                    item.filters = array;
                                }
                            }
                        })
                    }
                })
            }

            const dataProcess = process(data, { ...filter_state });
            setDataState({ ...data_state });
            setData(dataProcess.data ?? []);
            setSummary(dataProcess.data?.length ?? 0);

        } else {
            setData([...fixedData]);
            setDataState({});
            setSummary(fixedData?.length ?? 0)

        }
    };

    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true;
            })
        }
        return active;
    };

    const rowRender = (trElement, props,) => {
        const data = props.dataItem;
        const rowCounter = trElement._owner.index;
        return (
            <tr data-index={rowCounter} role="row" style={{ lineHeight: '0.5' }}>
                <td className="searchGstin_linked_tbtextcle_coral text-center">{data?.gstin ?? ''}</td>
                <td className="findgstin_overflowtext">
                <span data-toggle="tooltip" data-placement="top" title={data?.display_name ?? ''}>
                    {data?.display_name ?? ''}
                </span>
                    
                </td>
                <td className="text-center">
                    {data?.authStatus ?? ''}
                </td>
            </tr>
        )
    };

    useEffect(() => {
        getGSTINsData();
        // eslint-disable-next-line
    }, [company_id, pan]);

    return (
        <>

            <ShowAlert
                error={error}
                onClose={() => {
                    setError(null);
                }}
            />

            <WindowWrapperFixWidth
                title={
                    <div className="searchGstin_linked_title">
                        <h4>{changedPan ?? ''}</h4>
                    </div>
                }
                onClose={() => {
                    onHide();
                    setDataState({});
                }}
                totalPopups={2}
                index={1}
                isModalView={false}
                initialHeight={'auto'}
                initialLeft={500}
                initialWidth={600}
                initialTop={140}
                className={`searchGstin_linkedmodal`}
                showDiffClass={true}
                style={{ zIndex: '1050' }}
            >

                <div className='row'>
                    <div className='col-md-12'>
                        <div className='card'>
                            <div class="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                <Grid
                                    className="table table-striped searchGstin_linked_table mb-0"
                                    style={{ maxHeight: '368px', overflowY: "auto" }}
                                    data={data}
                                    {...dataState}
                                    onDataStateChange={(event) => dataStateChange(event.dataState)}
                                    fixedScroll={true}
                                    filterOperators={{
                                        text: [
                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                        ],
                                        dropdown: [
                                            { text: 'grid.filterEqOperator', operator: 'in' },
                                        ],
                                    }}
                                    sort={false}
                                    rowRender={rowRender}
                                >

                                    <Column
                                        width={130}
                                        field="gstin"
                                        headerClassName={isColumnActive('gstin') ? 'active' : ''}
                                        title={<CustomCell title={renderSubTextTitle('GSTIN', summary)} field="gstin" dataStateChange={dataStateChange} dataState={dataState} />}
                                        filter="text"
                                        columnMenu={dataState && ((props) => <ColumnMenu hideSecondFilter {...props} />)}
                                    />
                                    <Column
                                        width={150}
                                        field="display_name"
                                        headerClassName={isColumnActive('display_name') ? 'active' : ''}
                                        title={<CustomCell title={renderSubTextTitle('State', summary)} field="display_name" dataStateChange={dataStateChange} dataState={dataState} />}
                                        filter="text"
                                        columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                    />
                                    <Column
                                        width={100}
                                        field="authStatus"
                                        headerClassName={isColumnActive('authStatus') ? 'active' : ''}
                                        title={<CustomCell title={renderSubTextTitle('Status', summary)} field="authStatus" dataStateChange={dataStateChange} dataState={dataState} />}
                                        filter="dropdown"
                                        columnMenu={(props) => <ColumnMenu {...props} filterList={[{ label: 'Active', value: 'Active' }, { label: 'Inactive', value: 'Inactive' }]} hideSecondFilter />}
                                    />

                                    <GridNoRecords>
                                        No Record Found
                                    </GridNoRecords>

                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>

            </WindowWrapperFixWidth>
        </>
    )

};

export default FindGSTINsLinkedPANModal;