/* eslint-disable no-unreachable */
/* eslint-disable no-eval */
/* eslint-disable no-useless-catch */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { BASE_URL } from '../app/constants';
import {
  handleSuccess, handleErrorWithCode,
} from './responseHandler';
import { getConfig } from './common';


export async function getSalesReportForB2B(data) {
  const url = `${BASE_URL}/api/v1/gstr1/portal/b2b/list`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}


export async function getGSTREnums(qStr) {
  const url = `${BASE_URL}/api/v1/gstr1/portal/valid-enums?${qStr}`;
  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }

}

export async function getSalesReportForB2CS(data) {
  const url = `${BASE_URL}/api/v1/gstr1/portal/b2cs/list`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getSalesReportForEXP(data) {
  const url = `${BASE_URL}/api/v1/gstr1/portal/exp/list`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getSalesReportForEXEMP(data) {
  const url = `${BASE_URL}/api/v1/gstr1/portal/exemp/list`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getAdvanceReceived(data) {
  const url = `${BASE_URL}/api/v1/gstr1/portal/at/list`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getAdvanceAdjusted(data) {
  const url = `${BASE_URL}/api/v1/gstr1/portal/atadj/list`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getAdvanceReceivedAmendment(data) {
  const url = `${BASE_URL}/api/v1/gstr1/portal/ata/list`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getAdvanceAdjustedAmendment(data) {
  const url = `${BASE_URL}/api/v1/gstr1/portal/atadja/list`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getSalesReportForHSNSummary(data) {
  const url = `${BASE_URL}/api/v1/gstr1/portal/hsn/list`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getSalesReportForDocSummary(data) {
  const url = `${BASE_URL}/api/v1/gstr1/portal/docs/list`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getSalesReportForB2BA(data) {
  const url = `${BASE_URL}/api/v1/gstr1/portal/b2ba/list`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getSalesReportForB2CLA(data) {
  const url = `${BASE_URL}/api/v1/gstr1/portal/b2cla/list`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}


export async function getSalesReportForB2CSA(data) {
  const url = `${BASE_URL}/api/v1/gstr1/portal/b2csa/list`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}


export async function getSalesReportForExpA(data) {
  const url = `${BASE_URL}/api/v1/gstr1/portal/expa/list`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}


export async function getSalesReportForCNDNA(data) {
  const url = `${BASE_URL}/api/v1/gstr1/portal/cdnra/list`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getSalesReportForCNDNUA(data) {
  const url = `${BASE_URL}/api/v1/gstr1/portal/cdnura/list`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getSalesReportForB2CL(data) {
  const url = `${BASE_URL}/api/v1/gstr1/portal/b2cl/list`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getAllSectionSalesRegister(data) {
  const url = `${BASE_URL}/api/v1/gstr1/books/all-section-summary?${data}`;
  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getAllSectionUploadedData(data) {
  const url = `${BASE_URL}/api/v1/gstr1/portal/all-section-summary?${data}`;
  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getGSTR1Json(data) {
  const url = `${BASE_URL}/api/v1/gstr1/books/download-json-for-portal?${data}`;
  try {
    const result = await axios.get(url, getConfig());
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getGSTR1BooksUploadSummary(data) {
  const url = `${BASE_URL}/api/v1/gstr1/books/upload-summary?${data}`;
  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getSalesReportForCNDN(data) {
  const url = `${BASE_URL}/api/v1/gstr1/portal/cdnr/list`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getSalesReportForCNDNU(data) {
  const url = `${BASE_URL}/api/v1/gstr1/portal/cdnur/list`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getGSTR1PrevMonthUploadSummary(data) {
  const url = `${BASE_URL}/api/v1/gstr1/books/upload-summary-prvmonth?${data}`;
  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function startFilingProcess(data) {
  const url = `${BASE_URL}/api/v1/gstr1/filing-process/start-save`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    // throw handleErrorWithCode(err);
    throw err.response

  }
}

export async function getFilingProcessSaveStatus(data) {
  const url = `${BASE_URL}/api/v1/gstr1/filing-process/save-status?${data}`;
  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function reStartFilingProcess(data) {
  const url = `${BASE_URL}/api/v1/gstr1/filing-process/restart-save`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    // throw handleErrorWithCode(err);
    throw err.response
  }
}

export async function getInvalidGstinList(data) {
  const url = `${BASE_URL}/api/v1/gstr1/books/invalid-gstin/list`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function deleteInvalidGstinData(queryString) {
  const url = `${BASE_URL}/api/v1/gstr1/books/invalid-gstin/delete?${queryString}`;
  try {
    const result = await axios.delete(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function moveInvalidGstinData(data) {
  const url = `${BASE_URL}/api/v1/gstr1/books/invalid-gstin/move`;
  try {
    const result = await axios.put(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function updateInvalidGstinData(data) {
  const url = `${BASE_URL}/api/v1/gstr1/books/invalid-gstin/update-gstin`;
  try {
    const result = await axios.put(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function sendGSTR1AnnualConsolidatedReportEmail(data) {
  const url = `${BASE_URL}/api/v1/report/gstr1/annual-consolidated/email`;

  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

