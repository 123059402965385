import { combineReducers } from '@reduxjs/toolkit';
import headerSliceReducer from '../features/header/headerSlice';
import gstr1BooksSliceReducer from '../features/gstr1/books/detail/gstr1BooksSlice';
import appDataSlice from './appDataSlice';
import GSTR9SalesSliceReducer from '../features/gstr9/sales/GSTR9SalesSlice';
import GSTR9ITCSliceReducer from '../features/gstr9/itc/GSTR9ITCSlice';
import GSTR9FyDetailSliceReducer from '../features/gstr9/fyDetails/GSTR9FyDetailSlice';
import GSTR9CTurnoverSliceReducer from '../features/gstr9c/reco-turnover/GSTR9CTurnoverSlice';
import GSTR9CTaxPaidSliceReducer from '../features/gstr9c/reco-tax-paid/GSTR9CTaxPaidSlice';
import GSTR9CITCSliceReducer from '../features/gstr9c/reco-itc/GSTR9CITCSlice';
import GSTR3BEligibleSliceReducer from '../features/gstr3B/eligibleITC/GSTR3BEligibleSlice';
import TaskQueueSliceReducer from '../features/TaskQueue/TaskQueueSlice';
import tallySyncData from '../features/header/tallySyncStatusSlice';
import paramSlice from '../features/returnDashboard/ReturnDataSlice'

import invoiceReducer from '../features/gstr1a/books/detail/ammendments/b2csa/InvoiceSlice';

const rootReducer = combineReducers({
  header: headerSliceReducer,
  gstr1booksdetail: gstr1BooksSliceReducer,
  appData: appDataSlice,
  gstr9SalesData: GSTR9SalesSliceReducer,
  gstr9ITCData: GSTR9ITCSliceReducer,
  gstr9FyDetailData: GSTR9FyDetailSliceReducer,
  gstr9cTurnoverData: GSTR9CTurnoverSliceReducer,
  gstr9cTaxPaidData: GSTR9CTaxPaidSliceReducer,
  gstr9cITCData: GSTR9CITCSliceReducer,
  gstr3BEligibleData: GSTR3BEligibleSliceReducer,
  taskQueue: TaskQueueSliceReducer,
  tallySync: tallySyncData,
  params: paramSlice,
  invoiceGstr1a: invoiceReducer,
});

export default rootReducer;
