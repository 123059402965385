import React, { useState } from "react";
import GstPortalLoginConnectorPopUp from "./GstPortalLoginConnectorPopUp";
import gstinPortalLogo from "../../images/gstin-portal-logo.png";


export default function GstPortalLoginConnector(props) {

  const { companyId } = props;

  const [showGstPortalLoginConnectorPopUp, setShowGstPortalLoginConnectorPopUp] = useState(false);


  return (
    <>

      {showGstPortalLoginConnectorPopUp && <GstPortalLoginConnectorPopUp
        companyId={companyId}
        show={showGstPortalLoginConnectorPopUp}
        onClose={() => {
          setShowGstPortalLoginConnectorPopUp(false);
        }}
      />}

      <img src={gstinPortalLogo} width="22" alt='' style={{ cursor: 'pointer' }} className="planexpiredalert"
        onClick={() => {
          setShowGstPortalLoginConnectorPopUp(true);
        }}
      />

    </>

  )
}