import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { selectHeaderData } from '../header/headerSlice';
import ImportBookTallyClientPC from '../returnDashboard/ImportBookTallyClientPC';
import ImportBookTallyMyPC from '../returnDashboard/ImportBookTallyMyPC';
import tallyIcon from '../../images/icons/tally_img.png';
import { tallyMappingSyncStatus } from '../../api/booksAPI';
import ShowAlert from '../../components/ShowAlert';
import '../../features/returnDashboard/ReturnDashboard.css'

const TallySyncPopup = (props) => {
    const { companyId } = props
    const headerData = useSelector(selectHeaderData);
    const excelTabLocalData = localStorage.getItem(`tally_sync_excel_tab_${headerData?.mobile_number}`)
    const excelTabSelected = excelTabLocalData ? JSON.parse(excelTabLocalData)?.[headerData?.gst_number] ? true : false : false;
    const [myPCPopup, setMyPCPopup] = useState(false);
    const [clientPCPopup, setClientPCPopup] = useState(false);
    const [myPcClicked, setMyPcClicked] = useState(false);
    const [pcOption, setPcOption] = useState(true)
    const [mappedInfo, setMappedInfo] = useState({});
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null); useEffect(() => {
        //if (!excelTabSelected) {
            async function fetchStatus() {
                try {
                    const result = await tallyMappingSyncStatus(companyId);
                    if (result?.data) {
                        if ((result?.data?.last_mapped_by_type === "CLIENT" || (result?.data?.mapping_status === 0 && result?.data?.tally_client_last_email_sent_at))) {
                            setClientPCPopup(true)
                        } else {
                            setMyPCPopup(true)
                        }
                        setMappedInfo(result?.data)

                        if (result?.data?.mapping_status === 0) {
                            setPcOption(true)
                        } else {
                            setPcOption(false)
                        }
                    }
                } catch (err) {
                    // setError(err.message)  
                }
            }
            fetchStatus()
        //}
    }, [excelTabSelected, companyId])

    return (

        <>
            <div className="col-md-12 px-2">
            <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade active show" id="impbooksdata_tabbox_tally_01" role="tabpanel" aria-labelledby="nav-home-tab">
                    <div className="row">
                        <div className="col-md-12 tally-alert-box">
                            <ShowAlert
                                error={error}
                                success={success}
                                onClose={() => { setError(null); setSuccess(null); }}
                                autoHide={true}
                                //autoHideTime={100000}
                                headerStyle={{
                                    position: "relative",
                                    top: "0",
                                    left: "0",
                                    width: "100%",
                                    fontSize: "12px"
                                }} />
                        </div>

                        <div className="col-md-12">
                            <div className="card tally_cardbox ">
                                <p className={`mb-0 `}>
                                    <span className="tally_config_textfs pr-4">
                                        Configure with
                                        <span className="tally_imgbox">
                                            <img alt="tally img" src={tallyIcon} width="50" />
                                        </span>
                                    </span>
                                    {pcOption && <div style={{ float: "right" }} >
                                        <span className="btn btn-default tally_mypc_btnbgclr mr-2 zps_tally_mypc " onClick={() => { setMyPCPopup(true); setClientPCPopup(false); setMyPcClicked(true) }}>
                                            My PC
                                        </span>
                                        <span className="btn btn-default tally_mypc_btnbgclr zps_tally_clientpc" onClick={() => {
                                            setClientPCPopup(true); setMyPCPopup(false); setMyPcClicked(false)
                                        }}>
                                            Client PC
                                        </span>
                                    </div>}
                                    <div id="tally-sync-box" style={{ float: "right" }}></div>
                                </p>
                            </div>
                        </div>
                        <div className="col-md-12 pb-2">
                            {clientPCPopup &&
                                <ImportBookTallyClientPC
                                    companyId={companyId}
                                    initialMappedInfo={mappedInfo}
                                    setPcOption={setPcOption}
                                    setInitialMappedInfo={setMappedInfo}
                                    setError={setError}
                                    setSuccess={setSuccess}
                                />
                            }

                            {myPCPopup &&
                                <ImportBookTallyMyPC
                                    companyId={companyId}
                                    setError={setError}
                                    setSuccess={setSuccess}
                                    myPcClicked={myPcClicked}
                                    setMyPCPopup={setMyPCPopup}
                                    setPcOption={setPcOption}
                                    initialMappedInfo={mappedInfo}
                                    setInitialMappedInfo={setMappedInfo}
                                />
                            }
                        </div>
                        {(!myPCPopup && !clientPCPopup) &&
                            <div className="col-md-12 pt-3 pb-2">
                                <div className="card tallyconfg_card">
                                    <p className="tally_config_Ginfofs mb-0">
                                        General Instructions:
                                    </p>
                                    <p className="tally_config_textfs mb-1">
                                        1.  Choose appropriate option from Configure with Tally and ExpressGST will scan your Entire network and Tally instances running thereof.

                                    </p>
                                    <p className="tally_config_textfs tally_config_textfs">
                                        2. Please ensure the below points in the entire network-
                                    </p>
                                    <ul className="importbooksdt_tally_listbox py-0 mb-0">
                                        <li>
                                            <span className="pr-0 tally_listbox_checkicon_clrblue">
                                                <i className="fa fa-check" aria-hidden="true"></i>
                                            </span>
                                            Updated Connector is installed on the PC
                                        </li>
                                        <li>
                                            <span className="pr-0 tally_listbox_checkicon_clrblue">
                                                <i className="fa fa-check" aria-hidden="true"></i>
                                            </span>
                                            Tally is up and running on the PC
                                        </li>
                                        <li>
                                            <span className="pr-0 tally_listbox_checkicon_clrblue">
                                                <i className="fa fa-check" aria-hidden="true"></i>
                                            </span>
                                            Tally Company is open from which you want to sync.
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}

export default TallySyncPopup