import React from 'react'
import { useSelector } from 'react-redux';
import { selectHeaderData } from '../header/headerSlice';
import TallyProgressBar from './TallyProgressBar';
import { useEffect } from 'react';
import { useState } from 'react';
import tallyIcon from '../../images/icons/tally_img.png'
import { selectTallySyncStatusData } from '../header/tallySyncStatusSlice';


const TallyProgressPopup = () => {
    const headerData = useSelector(selectHeaderData);
    const [showTallyProgress, setShowTallyProgress] = useState(false)
    const [tallySyncInProgress, setTallySyncInProgress] = useState(false)
    const [isActiveTallySync, setIsActiveTallySync] = useState(false)
    const eFilingStatusData = useSelector(selectTallySyncStatusData);
    useEffect(() => {
        if (headerData.gst_number) {
            let localData = JSON.parse(localStorage.getItem("tally-sync-progress-" + headerData?.mobile_number))
            if (localData && localData?.[headerData.gst_number]) {
                setIsActiveTallySync(true)
            }
            if (eFilingStatusData?.syncStarted) {
                setShowTallyProgress(true)
            }else if(eFilingStatusData?.syncStarted === false){
                setShowTallyProgress(false)

            }
        }
    }, [headerData.gst_number, headerData?.mobile_number, eFilingStatusData?.syncStarted]);
    return (
        <>
            {(headerData?.mobile_number && headerData.gst_number && isActiveTallySync) ?
                <>
                    <div className='leftside_navbar_toggletimeline' onClick={() => {
                        setShowTallyProgress((prev) => !prev);
                    }}>
                        <span className='toggleColum'>
                            {!showTallyProgress ? <>
                                <img src={tallyIcon} className="timelineimg" alt="" /></>
                                : showTallyProgress && tallySyncInProgress ?
                                    <img src={tallyIcon} className="timelineimg rotate_img" alt="" /> :
                                    <>
                                        <span className='closebutton'>&#x2715;</span>
                                    </>

                            }
                        </span>
                    </div>
                    {showTallyProgress &&
                        <TallyProgressBar gstNo={headerData?.gst_number} mobileNo={headerData?.mobile_number} setTallySyncInProgress={setTallySyncInProgress} setShowTallyProgress={setShowTallyProgress} />}
                </> : null
            }
        </>
    )
}

export default TallyProgressPopup