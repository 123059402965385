/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { GstrPortal, ImpgGstrData } from '../../../api/MultiLinkingApi';
import { ColumnMenu } from '../../../components/grid/ColumnMenu';
import CheckboxCell from '../common/CheckboxCell';
import ShowMorePagination from '../../../components/pagination/ShowMorePagination';
import { getPeriodString, getDocumentTypeLable } from '../../../utils/UtilityFunctions';
import moment from 'moment';
import { formatAccountsNumber } from '../../../utils/UtilityFunctions';
import ModelForMultiLinking from '../common/ModelForMultiLinking';
import SkeltonCustomGrid from '../../../components/Skeleton/SkeltonCustomGrid';

const GSTR2ATable = (props) => {
    const {
        company_id,
        fullResponse,
        requestedDataFormat,
        gstr2aCheckboxes,
        selectedMode,
        firstSelection,
        fromDateGstr2a,
        toDateGstr2a,
        checkedItemsGSTR,
        setCheckedItemsGSTR,
        AllDocsGSTR2A,
        setCheckedDateGstr,
        selectedSeller,
        refDetails,
        setRefDetails,
        AllDocsBooks
    } = props;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalBooksData, setModalBooksData] = useState([]);
    const [modalPortalData, setModalPortalData] = useState([]);
    const [popupCurrentPage, setPopupCurrentPage] = useState(0);
    const [dataState, setDataState] = useState({
        skip: 0,
        take: 17,
        sort: [],
        filter: {
            logic: 'and',
            filters: []
        },
    });

    const getDocTypesFromBooksRange = (checkboxes) => {
        return Object.keys(checkboxes).filter(key => checkboxes[key]);
    };

    const initialGstrDataState = {
        company_id: company_id,
        return_periods: requestedDataFormat,
        gst_number: fullResponse?.gst_number || "",
        filter_periods: [],
        sort: [],
        doc_type: getDocTypesFromBooksRange(gstr2aCheckboxes),
        is_linked: AllDocsGSTR2A ? "2" : "0",
        from_date: fromDateGstr2a || "",
        to_date: toDateGstr2a || "",
        portal_mode: "2A",
        limit: 17,
        page: 1,
    };

    const [gstrDataState, setGstrDataState] = useState(initialGstrDataState);
    const [impgDataState, setImpgDataState] = useState({ ...initialGstrDataState, doc_type: (selectedMode === 'B2B/IMPGSEZ' && fullResponse?.doc_type === 'IMPGSEZ') ? "IMPGSEZ"  : "IMPG" });

    const [data, setData] = useState([]);
    const [initialData, setInitialData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showMoreVisible, setShowMoreVisible] = useState(false);
    const [fetchTrigger, setFetchTrigger] = useState(0);
    const [cursor, setCursor] = useState({ current_page: 1, next_page_url: null });
    const [currentDataItem, setCurrentDataItem] = useState(null);
    // const [error, setError] = useState("")

    const filterAndSortData = (data) => {
        if (firstSelection === 'gstr') return data;

        const fromDate = fromDateGstr2a ? new Date(fromDateGstr2a) : null;
        const toDate = toDateGstr2a ? new Date(toDateGstr2a) : null;

        let sortedData;

        if (fromDate && toDate) {
            const dataWithinRange = data.filter(item => {
                const docDate = new Date(item.doc_date);
                return docDate >= fromDate && docDate <= toDate;
            }).sort((a, b) => new Date(a.doc_date) - new Date(b.doc_date));

            const dataOutsideRange = data.filter(item => {
                const docDate = new Date(item.doc_date);
                return docDate < fromDate || docDate > toDate;
            }).sort((a, b) => new Date(b.doc_date) - new Date(a.doc_date));

            sortedData = [...dataWithinRange, ...dataOutsideRange];
        } else {
            sortedData = data.sort((a, b) => new Date(b.doc_date) - new Date(a.doc_date));
        }
        
        return sortedData.map(item => ({
            ...item,
            isChecked: checkedItemsGSTR[item.id]?.isChecked || false,
        }));
    };

    const fetchData = async (state, additional = false) => {
        if (!gstrDataState.gst_number) {
            return;
        }
        try {
            setLoading(true);
            setShowMoreVisible(true);
            const requestData = {
                ...state,
                filters: dataState.filter.filters,
                sort: dataState.sort,
                limit: state.limit,
                page: state.page ?? 1
            };

            const response = await GstrPortal(requestData);
            if (response.data.data === null) {
                setData([]);
                setShowMoreVisible(false);
            } else {
                const formattedData = response.data.data.map(item => ({
                    id: item.doc_id,
                    id_portal: item.id_portal,
                    doc_type: item.doc_type,
                    isChecked: checkedItemsGSTR[item.doc_id]?.isChecked || false,
                    doc_date: item.doc_date,
                    doc_no: item.doc_no,
                    Prd2A: getPeriodString(item.month, item.year),
                    taxable_amt: item.taxable_amt,
                    tax_amt: item.tax_amt,
                    is_linked: item.is_linked,
                    books_data: item.books_data,
                    portal_data: item.portal_data,
                    reco_id: item.reco_id,
                    match_remarks: item.match_remarks,
                    match_status: item.match_status
                }));

                let newData = additional ? [...data, ...formattedData] : formattedData;

                newData = newData.filter((item, index, self) =>
                    index === self.findIndex((t) => t.id === item.id)
                );

                const filteredAndSortedData = filterAndSortData(newData);

                setData(filteredAndSortedData);
                if (!additional) {
                    setInitialData(filteredAndSortedData); // Store initial data only when fetching new data
                }

                if (additional) {
                    setGstrDataState(prevState => ({ ...prevState, page: prevState.page + 1 }));
                } else {
                    setGstrDataState(prevState => ({ ...prevState, page: 1 }));
                }

                //setShowMoreVisible(response.data.cursor.next_page !== null);
            }
            if (response?.data?.cursor?.next_page > 1) {
                response.data.cursor.next_page_url = response?.data?.cursor?.next_page;
            }
            setCursor(response?.data?.cursor);
        } catch (err) {
            // setError(err.message)
            console.log(err)
            setData([]);
        } finally {
            setLoading(false);
            setShowMoreVisible(false);
        }
    };


    /*
     const fetchData = async (state, additional = false) => {
         if (!gstrDataState.gst_number) return;
 
         try {
             setLoading(true);
             const requestData = {
                 ...state,
                 filters: dataState.filter.filters,
                 sort: dataState.sort,
                 limit: state.limit,
                 page: state.page ?? 1
             };
 
             const response = await GstrPortal(requestData);
             const newCursor = response?.data?.cursor;
 
             if (response.data.data === null || response.data.data.length === 0) {
                 setData([]);
                 setShowMoreVisible(false);  // Ensure showMoreVisible is false when there's no data
             } else {
                 const formattedData = response.data.data.map(item => ({
                     ...item,
                     isChecked: checkedItemsGSTR[item.doc_id]?.isChecked || false,
                 }));
 
                 let newData = additional ? [...data, ...formattedData] : formattedData;
                 const filteredAndSortedData = filterAndSortData(newData);
 
                 setData(filteredAndSortedData);
                 if (!additional) {
                     setInitialData(filteredAndSortedData); // Store initial data only when fetching new data
                 }
 
                 // Check if more pages are available
                 if (newCursor?.next_page_url) {
                     setCursor(newCursor);
                     setShowMoreVisible(true);  // Only show the button if more pages are available
                 } else {
                     setShowMoreVisible(false);  // Hide the button if no more pages
                 }
             }
         } catch (err) {
             console.error(err);
             setData([]);
             setShowMoreVisible(false);
         } finally {
             setLoading(false);
         }
     };
     */
    const fetchImpgData = async (state, additional = false) => {
        if (requestedDataFormat?.length < 1) return;
        try {
            setLoading(true);
            const requestData = {
                ...state,
                filters: dataState.filter.filters,
                sort: dataState.sort,
                limit: state.limit,
                page: state.page ?? 1,
                gst_number: ""
            };

            if(selectedMode === 'B2B/IMPGSEZ' && fullResponse?.doc_type === 'IMPGSEZ' && requestData?.gst_number === ""){
                requestData.gst_number = fullResponse?.gst_number || "";
            }

            const response = await ImpgGstrData(requestData);
            if (response.data.data === null) {
                setData([]);
                setShowMoreVisible(false);
            } else {
                const formattedData = response.data.data.map(item => ({
                    id: item.doc_id,
                    id_portal: item.id_portal,
                    doc_type: item.doc_type,
                    isChecked: checkedItemsGSTR[item.doc_id]?.isChecked || false,
                    doc_date: item.boe_date,
                    doc_no: item.boe_no,
                    Prd2A: getPeriodString(item.month, item.year),
                    taxable_amt: item.taxable_amt,
                    tax_amt: item.tax_amt,
                    is_linked: item.is_linked,
                    books_data: item.books_data,
                    portal_data: item.portal_data,
                    reco_id: item.reco_id,
                    match_remarks: item.match_remarks,
                    match_status: item.match_status
                }));

                const filteredAndSortedData = filterAndSortData(formattedData);

                if (additional) {
                    setData(prevData => [...prevData, ...filteredAndSortedData]);
                    setImpgDataState(prevState => ({ ...prevState, page: prevState.page + 1 }));
                } else {
                    setData(filteredAndSortedData);
                    setInitialData(filteredAndSortedData);
                    setImpgDataState(prevState => ({ ...prevState, page: 1 }));
                }

                //setShowMoreVisible(!!response.data.cursor.next_page);
            }
            if (response?.data?.cursor?.next_page > 1) {
                response.data.cursor.next_page_url = response?.data?.cursor?.next_page;
            }
            setCursor(response?.data?.cursor);
        } catch (err) {
            // setError(err.message)
            console.log(err)
            setData([]);
            setShowMoreVisible(false);
        } finally {
            setLoading(false);
            setShowMoreVisible(false);
        }
    };

    // Effect to clear checkboxes when AllDocsBooks changes
    useEffect(() => {
        setCheckedItemsGSTR({});
        setCheckedDateGstr(null);

        // Update the data state to reflect that no items are checked
        const updatedData = data.map(item => ({
            ...item,
            isChecked: false
        }));
        setData(updatedData);
    }, [AllDocsBooks, AllDocsGSTR2A]);


    useEffect(() => {
        const updatedDocType = getDocTypesFromBooksRange(gstr2aCheckboxes);

        const newGstrDataState = {
            ...gstrDataState,
            gst_number: fullResponse?.gst_number || "",
            return_periods: requestedDataFormat,
            doc_type: updatedDocType,
            is_linked: AllDocsGSTR2A ? "2" : "0",
            page: 1,
            filters: dataState.filter?.filters
        };

        const newImpgDataState = {
            ...impgDataState,
            gst_number: "",
            return_periods: requestedDataFormat,
            doc_type: (selectedMode === 'B2B/IMPGSEZ' && fullResponse?.doc_type === 'IMPGSEZ') ? "IMPGSEZ"  : "IMPG",
            is_linked: AllDocsGSTR2A ? "2" : "0",
            page: 1,
            filters: dataState.filter?.filters
        };

        const isGstrDataChanged = JSON.stringify(newGstrDataState) !== JSON.stringify(gstrDataState);
        const isImpgDataChanged = JSON.stringify(newImpgDataState) !== JSON.stringify(impgDataState);

        if (isGstrDataChanged) {
            setGstrDataState(newGstrDataState);
        }

        if (isImpgDataChanged) {
            setImpgDataState(newImpgDataState);
        }

        if (isGstrDataChanged || isImpgDataChanged) {
            setFetchTrigger(prev => prev + 1);
        }
    }, [fullResponse, requestedDataFormat, gstr2aCheckboxes, AllDocsGSTR2A, dataState, selectedMode]);

    useEffect(() => {
        if (selectedMode === "IMPG" || fullResponse?.doc_type === 'IMPGSEZ') {
            setFetchTrigger(prev => prev + 1)
        } else if (!selectedSeller) {
            setData([]);
        } else {
            setFetchTrigger(prev => prev + 1)
        }
    }, [selectedMode, selectedSeller]);

    useEffect(() => {
        if (selectedMode === "IMPG" || fullResponse?.doc_type === 'IMPGSEZ') {
            setFetchTrigger(prev => prev + 1)
        } else {
            setFetchTrigger(prev => prev + 1)
        }
    }, [selectedMode]);

    useEffect(() => {
        if (requestedDataFormat.length <= 0) return;
        if (fetchTrigger === 0) return;

        if (selectedMode === "IMPG" || fullResponse?.doc_type === 'IMPGSEZ') {
            fetchImpgData(impgDataState);
        } else {
            fetchData(gstrDataState);
        }
    }, [fetchTrigger]);

    useEffect(() => {
        if (data.length > 0) {
            const filteredAndSortedData = filterAndSortData(data);
            setData(filteredAndSortedData);
        }
    }, [fromDateGstr2a, toDateGstr2a]);

    const handleCheckboxChange = (e, dataItem) => {
        const updatedCheckedItems = { ...checkedItemsGSTR };


        // Determine the match status and reference ID to be added to refDetails
        const refId = dataItem.match_status === "IN_BOOKS_ONLY" || dataItem.match_status === "IN_2B_ONLY"
            ? dataItem.id
            : dataItem.reco_id;

        const newRefDetails = {
            ref_id: refId,
            ref_section: dataItem.doc_type,
            match_status: dataItem.match_status,
        };

        // Only update refDetails if necessary, to avoid unnecessary state updates
        if (!refDetails.some(ref => ref.ref_id === newRefDetails.ref_id && ref.ref_section === newRefDetails.ref_section)) {
            setRefDetails(prevRefDetails => [...prevRefDetails, newRefDetails]);
        }

        if (e.target.checked) {
            updatedCheckedItems[dataItem.id] = {
                isChecked: true,
                id_books: dataItem.id,
                doc_type: dataItem.doc_type,
                doc_date: dataItem.doc_date
            };
        } else {
            delete updatedCheckedItems[dataItem.id];
        }

        setCheckedItemsGSTR(updatedCheckedItems);

        const updatedData = data.map(item =>
            item.id === dataItem.id ? { ...item, isChecked: e.target.checked } : item
        );
        setData(updatedData);

        setCheckedDateGstr(e.target.checked ? dataItem.doc_date : null);
    };

    const dataStateChange = (event) => {

        const newDataState = event.dataState;
        setDataState(newDataState)

        const filtersCleared = !newDataState?.filter || newDataState.filter.filters.length === 0;

        if (filtersCleared) {
            // Handle the scenario where all filters are cleared
            setDataState({ ...newDataState, filter: { logic: 'and', filters: [] }, skip: 0 });
            setGstrDataState(prevState => ({ ...prevState, page: 1 }));
            setImpgDataState(prevState => ({ ...prevState, page: 1 }));
            setData([...initialData]); // Reset data to initial state
        }
    }


    const handleShowMore = () => {
        if (selectedMode === "IMPG" || fullResponse?.doc_type === 'IMPGSEZ') {
            fetchImpgData(impgDataState, true);
        } else {
            fetchData(gstrDataState, true);
        }
    };

    useEffect(() => {
        if (selectedMode === "IMPG" || fullResponse?.doc_type === 'IMPGSEZ') {
            setData([]);
            setCursor({ current_page: 1, next_page_url: null });
            fetchImpgData(impgDataState);
        } else {
            setData([]);
            setCursor({ current_page: 1, next_page_url: null });
            fetchData(gstrDataState);
        }
    }, [selectedMode]);


    // Utility function to get the active state of a column
    const isColumnActive = (field) => {
        return dataState.filter.filters.some(filter =>
            filter.field === field || (filter.filters && filter.filters.some(subFilter => subFilter.field === field))
        );
    };


    const rowRender = (trElement, rowProps) => {
        const { dataItem, index } = rowProps;

        const backgroundColor = dataItem.isChecked
            ? '#e0e0e0'
            : dataItem.is_linked
                ? '#fbf3cf'
                : '#d6e9f7';

        if (dataItem.portal_data && dataItem.portal_data.length > 0) {
            const portalCount = dataItem.portal_data.length;

            return (
                <tr key={index} style={{ backgroundColor }} onClick={() => {
                    setModalBooksData(dataItem.books_data?.map(item => ({ ...item, matchRemark: dataItem.match_remarks, reco_id: dataItem.reco_id, match_status: dataItem.match_status })));
                    setModalPortalData(dataItem.portal_data?.map(item => ({ ...item, matchRemark: dataItem.match_remarks, reco_id: dataItem.reco_id, match_status: dataItem.match_status })));
                    setPopupCurrentPage(0);
                    setCurrentDataItem(dataItem);
                    setIsModalOpen(true);
                }}>
                    <CheckboxCell dataItem={dataItem} handleCheckboxChange={handleCheckboxChange} disabled={true} />
                    <td colSpan={4} className="text-center" style={{ color: "#5687a4", cursor: "pointer" }}>
                        {portalCount > 0 ? `${portalCount} document${portalCount > 1 ? 's' : ''} linked` : ''}
                    </td>
                    <td className="align-right">{formatAccountsNumber(dataItem.taxable_amt ?? 0)}</td>
                    <td className="align-right">{formatAccountsNumber(dataItem.tax_amt ?? 0)}</td>
                </tr>
            );
        }

        const doc_no = dataItem.doc_no ?? dataItem.boe_no;

        return (
            <tr key={index} style={{ backgroundColor }}>
                <CheckboxCell dataItem={dataItem} handleCheckboxChange={handleCheckboxChange} />
                <td className="align-center">{dataItem.doc_date ? moment(dataItem.doc_date).format('DD/MM/YYYY') : ''}</td>
                <td className="align-center">
                    <span title={doc_no}>{doc_no.length > 12 ? doc_no.substring(0, 12)+ "..." : doc_no}</span>
                </td>
                <td className="align-center">{getDocumentTypeLable(dataItem.doc_type)}</td>
                <td className="align-center">{dataItem.Prd2A}</td>
                <td className="align-right">{formatAccountsNumber(dataItem.taxable_amt ?? 0)}</td>
                <td className="align-right">{formatAccountsNumber(dataItem.tax_amt ?? 0)}</td>
            </tr>
        );
    };

    return (
        <>
            <div className="col-lg-6">
                <div className="card px-1 py-1">
                    {loading ? (<table className="table">
                        <SkeltonCustomGrid column={5} rowNo={5} />
                    </table>) : <>
                        <div className="table-responsive">
                        <Tooltip anchorElement="pointer" position="top">
                            <Grid
                                data={data}
                                {...dataState}
                                onDataStateChange={dataStateChange}
                                className="table-responsive multilinking-data-listing"
                                filterOperators={{
                                    text: [
                                        { text: 'grid.filterContainsOperator', operator: 'contains' },
                                    ],
                                    numeric: [
                                        { text: 'grid.filterGteOperator', operator: 'gte' },
                                        { text: 'grid.filterLteOperator', operator: 'lte' },
                                        { text: 'grid.filterLtOperator', operator: 'lt' },
                                        { text: 'grid.filterGtOperator', operator: 'gt' },
                                        { text: 'grid.filterEqOperator', operator: 'eq' },
                                        { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                    ],
                                    textWithEmpty: [
                                        { text: 'grid.filterContainsOperator', operator: 'contains' },
                                        { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                    ],
                                    date: [
                                        { text: 'grid.filterGteOperator', operator: 'gte' },
                                        { text: 'grid.filterLteOperator', operator: 'lte' },
                                        { text: 'grid.filterLtOperator', operator: 'lt' },
                                        { text: 'grid.filterGtOperator', operator: 'gt' },
                                        { text: 'grid.filterEqOperator', operator: 'eq' },
                                    ],
                                }}
                                rowRender={rowRender}
                                style={{ maxHeight: '610px', overflowY: 'auto' }}
                            >
                                <Column
                                    width="35"
                                    field="checkbox"
                                    filterable={false}
                                    headerCell={() => <span></span>}
                                    columnMenu={null}
                                />
                                <Column
                                    width="90"
                                    field={selectedMode === "IMPG" || fullResponse?.doc_type === "IMPGSEZ" ? "boe_date" : "doc_date"}
                                    filter='date'
                                    headerClassName={isColumnActive(selectedMode === "IMPG" || fullResponse?.doc_type === "IMPGSEZ" ? "boe_date" : "doc_date") ? 'active' : ''}
                                    title={selectedMode === "IMPG" || fullResponse?.doc_type === 'IMPGSEZ' ? "BoE Dt." : "Doc Dt."}
                                    columnMenu={(props) => <ColumnMenu {...props} />}
                                />
                                <Column
                                    width="90"
                                    field={selectedMode === "IMPG" || fullResponse?.doc_type === "IMPGSEZ" ? "boe_no" : "doc_no"}
                                    filter='text'
                                    headerClassName={isColumnActive(selectedMode === "IMPG" || fullResponse?.doc_type === "IMPGSEZ" ? "boe_no" : "doc_no") ? 'active' : ''}
                                    title={selectedMode === "IMPG" || fullResponse?.doc_type === 'IMPGSEZ' ? "BoE No." : "Doc No."}
                                    columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                />
                                <Column
                                    width="90"
                                    field="doc_type"
                                    filter='text'
                                    headerClassName={isColumnActive('doc_type') ? 'active' : ''}
                                    title={"Doc Type"}
                                    columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                />
                                <Column
                                    width="90"
                                    field="Prd2A"
                                    filterable={false}
                                    headerClassName="disabled_sorting"
                                    title={"2A Prd"}
                                />
                                <Column
                                    width="90"
                                    field="taxable_amt"
                                    filter='numeric'
                                    headerClassName={isColumnActive('taxable_amt') ? 'active' : ''}
                                    title={"Taxable Val"}
                                    columnMenu={(props) => <ColumnMenu {...props} />}
                                />
                                <Column
                                    width="90"
                                    field="tax_amt"
                                    filter='numeric'
                                    headerClassName={isColumnActive('tax_amt') ? 'active' : ''}
                                    title={"Tax"}
                                    columnMenu={(props) => <ColumnMenu {...props} />}
                                />
                            </Grid>
                            </Tooltip>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <ShowMorePagination
                                    cursor={cursor}
                                    postData={selectedMode === "IMPG" ? impgDataState : gstrDataState}
                                    loading={showMoreVisible}
                                    fetchData={handleShowMore}
                                />
                            </div>
                        </div>
                    </>}
                </div>
            </div>

            {isModalOpen && (
                <ModelForMultiLinking
                    onHide={() => setIsModalOpen(false)}
                    closeModal={() => setIsModalOpen(false)}
                    totalInvoiceObj={modalBooksData?.length + modalPortalData?.length}
                    popupCurrentPage={popupCurrentPage}
                    invoiceList={[
                        ...(modalBooksData?.map(item => ({ ...item, matchRemark: currentDataItem?.match_remarks, matchStatus: currentDataItem?.match_status, reco_id: currentDataItem?.reco_id })) || []),
                        ...(modalPortalData?.map(item => ({ ...item, matchRemark: currentDataItem?.match_remarks, matchStatus: currentDataItem?.match_status, reco_id: currentDataItem?.reco_id })) || [])
                    ]}
                    type={selectedMode}
                    gstrKey="gstr2a"
                    books={modalBooksData}
                    portal={modalPortalData}
                    gst_number={selectedMode !== "IMPG" ? selectedSeller : ""}
                    tabel_col={selectedMode === "IMPG" || fullResponse?.doc_type === "IMPGSEZ" ? "imp" : "b2b"}
                    doc_type={fullResponse?.doc_type ?? ''}
                />
            )}
        </>
    );
};

export default GSTR2ATable;
