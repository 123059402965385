import React from 'react';

const RangeSelector = ({ range, handleRangeChange, elementId }) => {

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     handleRangeChange(name, value);
    // };

    // const dropdownItemStyle = {
    //     cursor: 'pointer'
    // };

    return (
        <div className="manytomany_datebefore_afterboxlist">
        <span className="manytomany_rangedrop_titlefs pr-2">Range: </span>
        <span className="dropdown manytomany_rangedropinner mr-1">
            <span
                type="button"
                className="btn btn-primary dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="false"
            >
                {range.range}
            </span>
            <span className="dropdown-menu manytomany_rangedrop_menu" id={`${elementId}-range_selector_type`}>
                <span className="dropdown-item" onClick={() => handleRangeChange('range', 'On or Before / After')}>On or Before / After</span>
                <div className="dropdown-divider"></div>
                <span className="dropdown-item" onClick={() => handleRangeChange('range', 'On or Before')}>On or Before</span>
                <div className="dropdown-divider"></div>
                <span className="dropdown-item" onClick={() => handleRangeChange('range', 'On or After')}>On or After</span>
            </span>
        </span>
        <span className="manytomany_rangedrop_valbox mx-2">
            <input
                type="text"
                className="form-control rangdropval_input"
                placeholder="10"
                maxLength="10"
                value={range.value}
                onChange={(e) => handleRangeChange('value', e.target.value)}
            />
        </span>
        <span className="dropdown manytomany_rangedropinner mr-1">
            <span
                type="button"
                className="btn btn-primary dropdown-toggle drop2"
                data-toggle="dropdown"
            >
                {range.unit}
            </span>
            <span className="dropdown-menu manytomany_rangedrop_menu daymenu" id={`${elementId}-range_selector_unit`}>
                <span className="dropdown-item" onClick={() => handleRangeChange('unit', 'Days')}>Days</span>
                <div className="dropdown-divider"></div>
                <span className="dropdown-item" onClick={() => handleRangeChange('unit', 'Weeks')}>Weeks</span>
                <div className="dropdown-divider"></div>
                <span className="dropdown-item" onClick={() => handleRangeChange('unit', 'Months')}>Months</span>
            </span>
        </span>
    </div>
    );
};

export default RangeSelector;
