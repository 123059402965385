/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { multiLinkingModel } from '../../../api/MultiLinkingApi';
import { encodeParams, decodeBase64, debounce } from "../../../utils/UtilityFunctions";
import Spinner from 'react-bootstrap/Spinner';
import "../multiLinking.scss";

const AutoCompleteInput = ({
    company_id,
    requestedDataFormat,
    gst_number,
    mobile_number,
    handleSellerSelect,
    width = '100%',
    shouldSaveToLocalStorage = true,  // New prop for controlling localStorage behavior
    elementId='htm_multilinking-supplier_selection_input',
}) => {
    const [state, setState] = useState({
        strQuery: '',
        isDropdownOpen: false,
        sellerListingResponse: [],
        cursor: {
            current_page: 1,
            next_page: null,
            previous_page: null,
        },
        error: false,
        loading: false,
        selectedSeller: '',
    });
    const [isInputFocused, setIsInputFocused] = useState(false);

    const inputRef = useRef(null);
    const dropdownRef = useRef(null);
    const inputContainerRef = useRef(null);
    const [errorMSG, setErrorMSG] = useState('');

    // Debounced API call function
    const debouncedFetchData = useCallback(
        debounce(async (page = 1, searchQuery = state.strQuery) => {
            if (requestedDataFormat.length <= 0) return;
            try {
                setState((prevState) => ({ ...prevState, loading: true }));
                const data = {
                    company_id,
                    return_periods: requestedDataFormat,
                    mode: 'B2B/IMPGSEZ',
                    is_linked: '2',
                    search_text: searchQuery,
                    limit: 15,
                    page,
                };

                const result = await multiLinkingModel(data);
                const newSellers = result.data.data || [];
                const cursor = result.data.cursor;

                const uniqueSellers = newSellers.filter(
                    (seller, index, self) =>
                        index === self.findIndex((s) => s.gst_number === seller.gst_number)
                );

                setState((prevState) => ({
                    ...prevState,
                    sellerListingResponse:
                        page === 1 ? uniqueSellers : [...prevState.sellerListingResponse, ...uniqueSellers],
                    cursor,
                    loading: false,
                }));
                setErrorMSG('');
                if (newSellers.length === 0) {
                    setErrorMSG('No results found');
                }

            } catch (err) {
                setState((prevState) => ({ ...prevState, sellerListingResponse: [], loading: false }));
            }
        }, 300), // 300ms delay
        [company_id, requestedDataFormat, state.strQuery] // Added `state.strQuery` as dependency
    );

    // Effect to trigger data fetching
    useEffect(() => {
        if (isInputFocused) {
            debouncedFetchData(1, state.strQuery);
        }
    }, [state.strQuery, isInputFocused, debouncedFetchData]);

    // Handle scroll pagination
    useEffect(() => {
        const dropdownNode = dropdownRef.current;
        const handleScroll = () => {
            if (dropdownNode) {
                const { scrollTop, scrollHeight, clientHeight } = dropdownNode;
                if (scrollTop + clientHeight >= scrollHeight - 5 && state.cursor.next_page && !state.loading) {
                    debouncedFetchData(state.cursor.next_page, state.strQuery);
                }
            }
        };

        if (dropdownNode) {
            dropdownNode.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (dropdownNode) {
                dropdownNode.removeEventListener('scroll', handleScroll);
            }
        };
    }, [state.cursor.next_page, state.loading, debouncedFetchData]);

    // Fetch selected seller from local storage on component mount
    useEffect(() => {
        if (shouldSaveToLocalStorage) {
            const key = `selectedSeller_${mobile_number}_${gst_number}`;
            const savedSelectedSeller = localStorage.getItem(key);

            if (savedSelectedSeller) {
                const decodedSavedSeller = decodeBase64(savedSelectedSeller);
                const parsedSelectedSeller = JSON.parse(decodedSavedSeller);
                const { supplier_name, gst_number } = parsedSelectedSeller;
                const savedSelectedSellerString = `${supplier_name.length > 25
                    ? supplier_name.substring(0, 25) + '...'
                    : supplier_name
                    } (${gst_number})`;

                setState((prevState) => ({
                    ...prevState,
                    selectedSeller: savedSelectedSellerString,
                }));

                handleSellerSelect(savedSelectedSellerString, parsedSelectedSeller);
            }
        }
    }, [gst_number, mobile_number, handleSellerSelect, shouldSaveToLocalStorage]);

    const handleBlur = () => {
        setTimeout(() => {
            setState((prevState) => ({ ...prevState, isDropdownOpen: false }));
            setIsInputFocused(false);
        }, 150);
    };

    const handleFocus = () => {
        setState((prevState) => ({ ...prevState, isDropdownOpen: true }));
        setIsInputFocused(true);
        // Trigger data fetching when input is focused
        if (!state.strQuery) {
            debouncedFetchData(1, '');
        }
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setState((prevState) => ({
            ...prevState,
            strQuery: value,
            isDropdownOpen: true,
            error: false,
        }));
    };

    const handleSellerSelectLocal = (seller) => {
        const updatedSeller = {
            ...seller,
            supplier_name: seller.supplier_name ? seller.supplier_name : "Name not available"
        };

        const value_local = `${updatedSeller.supplier_name.length > 25
            ? updatedSeller.supplier_name.substring(0, 25) + '...'
            : updatedSeller.supplier_name
            } (${updatedSeller.gst_number})`;

        setState((prevState) => ({
            ...prevState,
            strQuery: '',
            isDropdownOpen: false,
            error: false,
            selectedSeller: value_local,
        }));

        if (shouldSaveToLocalStorage) {  // Only store in localStorage if this prop is true
            const encodedSeller = encodeParams(updatedSeller);
            const key = `selectedSeller_${mobile_number}_${gst_number}`;
            localStorage.setItem(key, encodedSeller);
        }

        handleSellerSelect(value_local, updatedSeller); // Pass selected seller data to parent
    };

    const handleMouseDown = (e) => {
        if (dropdownRef.current && dropdownRef.current.contains(e.target)) {
            e.preventDefault();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleMouseDown);
        return () => {
            document.removeEventListener('mousedown', handleMouseDown);
        };
    }, []);

    return (
        <div className="autocomplete-input" style={{ width: '400px', minWidth: '400px', maxWidth: '700px' }}>
            <div className="manytomany_rangedropbox" style={{ display: 'flex', alignItems: 'center' }}>
                <span className="manytomany_rangedrop_supp_textfs">Supplier:</span>
                <div ref={inputContainerRef} style={{ flex: 1, position: 'relative' }}>
                    <input
                        id={`${elementId}`}
                        ref={inputRef}
                        type="text"
                        className={`form-control multilinking_dropinputfs  ${state.error ? 'is-invalid' : ''}`}
                        placeholder="Search or select Supplier/GSTIN"
                        value={state.isDropdownOpen || state.strQuery ? state.strQuery : state.selectedSeller}
                        onChange={handleInputChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        style={{ zIndex: 10, backgroundColor: 'white' }}
                    />
                    {state.isDropdownOpen && (
                        <div
                            className="dropdown-menu show multilinking_droplistbox"
                            ref={dropdownRef}
                            style={{
                                position: 'absolute',
                                top: '100%',
                                left: 0,
                                zIndex: 11,
                                width: '100%',
                                maxHeight: '220px',
                                overflowY: 'auto',
                            }}
                        >
                            {state.sellerListingResponse.length > 0 ? (
                                state.sellerListingResponse.map((seller, index) => (
                                    <span
                                        key={index}
                                        className={`dropdown-item ${index % 2 === 0 ? 'odd' : 'even'}`}
                                        onMouseDown={() => handleSellerSelectLocal(seller)}
                                        style={{ fontSize: '12px' }}
                                    >
                                        {`${seller.supplier_name.length > 22
                                            ? seller.supplier_name.substring(0, 22) + '...'
                                            : seller.supplier_name || "Name not available"
                                            } (${seller.gst_number})`}
                                    </span>
                                ))
                            ) : (

                                state.loading ? <><span className="dropdown-item disabled" style={{ paddingLeft: '110px' }}><Spinner animation="border" size="sm" /></span></>
                                    :
                                    errorMSG && <> <span className="dropdown-item disabled">{errorMSG}</span> </>
                            )}
                            {state.loading && (
                                <div className="text-center py-2">
                                    <Spinner animation="border" size="sm" />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            {state.error && (
                <span className="invalid-feedback" style={{ display: 'block' }}>
                    Please select a Supplier/ Import your Book!
                </span>
            )}
        </div>
    );
};

export default AutoCompleteInput;

AutoCompleteInput.defaultProps = {
    shouldSaveToLocalStorage: true,  // Default behavior is to save to localStorage
};
