/* eslint-disable array-callback-return */
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faIdCard, faCircle, faUsers, faBuilding, faMoneyBillAlt, faCreditCard, faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import GIF_animation from '../../images/GIF_animation2.gif';
import Bank_icon from '../../images/bank-icon.png';
import { Carousel } from 'react-bootstrap';
import { ProgressBar } from '@progress/kendo-react-progressbars';
import "./GstInfoAnimation.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';

class GstInfoAnimation extends React.Component {

    state = {
        loading: this.props.loading ? this.props.loading : 0,
        intervalId: false,
        firstTime: false
    };

    componentDidMount() {
        if (this.state.loading === 0) {
            this.setState({ firstTime: true });
            let self = this;
            let interval_id = setInterval(function () {
                if (self.state.loading < 100) {
                    self.setState({ loading: self.state.loading + 1 });
                }
                else {
                    clearInterval(interval_id);
                    const gstr_popup = JSON.parse(localStorage.getItem('gstr-popup'));
                    let popup_show = [];
                    popup_show = gstr_popup && gstr_popup.filter((data, index) => { return data.gst_number !== self.props.headerData.gst_number });
                    localStorage.setItem('gstr-popup', JSON.stringify(popup_show));
                }
            }, 600);
            this.setState({ intervalId: interval_id });
        }
    }

    componentWillMount() {
        clearInterval(this.state.intervalId);
    }

    hidePopup = () => {
        let info_icon = $(".infoicongstin");
        let self = this;
        $(".gstindashpoup").animate({
            'opacity': '0.5',
            'position': 'absolute',
            'height': '10px',
            'width': '10px',
            'top': `35px`,
            'left': `${info_icon.offset().left + 1}px`,
        }, 800);
        setTimeout(function () { self.props.onHide(); }, 850);
    }

    render() {
        let { showGstInfoPopup, gstInfo } = this.props;

        return (
            <Modal
                show={showGstInfoPopup}
                animation={false}
                id="gstinslidepopup"
                className="gstindashpoup"
            >
                <Modal.Body className="pt-1 pb-0">
                    <div id="bootstrap-touch-slider">
                        <Carousel
                            interval={7000}
                            controls={false}
                            pause={false}
                            fade={true}
                            className="bs-slider control-round indicators-line ciinersliderheight">

                            <Carousel.Item className="item">

                                {this.state.firstTime ?
                                    <h1 className="topcont">
                                        We are fetching your GSTIN data from GSTN Portal and preparing Snapshot for
                                        first time use.<br /> Meanwhile have a look at your GSTIN Information
                                    </h1>
                                    :
                                    <h1 className="topcont">
                                        Here is a quick look at your GSTIN Information.
                                    </h1>
                                }

                                <img src={GIF_animation} alt="Bootstrap Touch Slider" className="slide-image" />
                                <div className="bs-slider-overlay"></div>

                                {gstInfo && gstInfo.basic_details ?
                                    <div className="slide-text slide_style_right">
                                        <div className="animation_1">
                                            <h1 className="leftpadd">
                                                <span className="bussuserbox"><FontAwesomeIcon icon={faIdCard} /></span> Basic Details
                                            </h1>
                                            <p className="secodcontsect01">
                                                Hello, <span className="textblodfs"> {gstInfo.basic_details.company} </span>
                                                You are registered as <span className="textblodfs"> {gstInfo.basic_details.registered_as} </span>
                                                taxpayer since <span className="textblodfs"> {gstInfo.basic_details.registered_since} </span>
                                                with GSTIN <span className="textblodfs"> <FontAwesomeIcon style={gstInfo.basic_details.gst_number_status ? { color: 'green' } : { color: 'red' }} icon={faCircle} /> {gstInfo.basic_details.gstin} </span>.
                                            </p>
                                            <p className="secodcontsect02">
                                                You had a turnover of <span className="textblodfs"> {gstInfo.basic_details.turnover} </span>
                                                in <span className="textblodfs">F.Y. {gstInfo.basic_details.turnover_in_fy} </span>
                                            </p>
                                            {gstInfo.basic_details.core_business ? <p className="secodcontsect03">You are a <span className="textblodfs"> {gstInfo.basic_details.core_business} </span> at the Core of your Business</p> : null}
                                        </div>
                                    </div> : null}

                            </Carousel.Item>



                            <Carousel.Item className="item">
                                {this.state.firstTime ?
                                    <h1 className="topcont">
                                        We are fetching your GSTIN data from GSTN Portal and preparing Snapshot for
                                        first time use.<br /> Meanwhile have a look at your GSTIN Information
                                    </h1>
                                    :
                                    <h1 className="topcont">
                                        Here is a quick look at your GSTIN Information.
                                    </h1>
                                }

                                <img src={GIF_animation} alt="Bootstrap Touch Slider" className="slide-image" />
                                <div className="bs-slider-overlay"></div>

                                <div className="slide-text slide_style_right">

                                    <div className="animation_1">
                                        <h1 className="leftpadd" data-animation="animated fadeInUp ">
                                            <span className="bussuserbox"><FontAwesomeIcon icon={faUsers} /></span>
                                            <span className="textblodfs"> Proprietor / Director(s) / Partner(s) / Promoter(s)</span>
                                        </h1>
                                        {gstInfo && gstInfo.proprietor ?
                                            <p data-animation="animated fadeInUp">
                                                {gstInfo.proprietor.length > 2 && gstInfo.proprietor.map((proprietor, index) => {
                                                    return (<> Name {index + 1}: <span className="textblodfs">{proprietor.toUpperCase()}</span> <br /></>)
                                                })}

                                                {gstInfo.proprietor.length <= 2 && gstInfo.proprietor.length > 0 ?
                                                    <>
                                                        <span className="textblodfs">{gstInfo.proprietor[0].toUpperCase()}</span>
                                                        {gstInfo.proprietor[1] ? <> and <span className="textblodfs">{gstInfo.proprietor[1].toUpperCase()}</span></> : null}
                                                    </>
                                                    : null}

                                            </p> : null}
                                    </div>


                                    <div className="animation_2">
                                        <h1 className="leftpadd" data-animation="animated fadeInUp">
                                            <span className="bussuserbox"><FontAwesomeIcon icon={faUsers} /></span>
                                            <span className="textblodfs"> Authorized Signatory</span>
                                        </h1>

                                        {gstInfo && gstInfo.authorized_signatory ?
                                            <p data-animation="animated fadeInUp">
                                                {gstInfo.authorized_signatory.map((authorized_signatory, index) => {
                                                    return (<p>
                                                        <span className="textblodfs "> {authorized_signatory.name.toUpperCase()} </span> with Mobile
                                                        <span className="textblodfs"><a href={"tel:" + authorized_signatory.mobile}> {authorized_signatory.mobile}</a></span>
                                                        and Email  Address
                                                        <span className="textblodfs"><a href={"mailto:" + authorized_signatory.email}> {authorized_signatory.email}</a></span>
                                                    </p>)
                                                })}
                                            </p> : null}
                                    </div>


                                    <div className="animation_3">
                                        <h1 className="leftpadd" data-animation="animated fadeInUp ">
                                            <span className="bussuserbox"><FontAwesomeIcon icon={faUsers} /></span> GST Practitioner
                                        </h1>
                                        {gstInfo && gstInfo.gst_practitioner && gstInfo.gst_practitioner.length > 0 ?
                                            <p data-animation="animated fadeInUp">
                                                {gstInfo.gst_practitioner.map((gst_practitioner, index) => {
                                                    return (<> <span className="textblodfs textblue">{gst_practitioner.name} </span> with Mobile
                                                        < span className="textblodfs" > <a href={"tel:" + gst_practitioner.mobile}> {gst_practitioner.mobile}</a></span> and Email
                                                        <span className="textblodfs"><a href={"mailto:" + gst_practitioner.email}>{gst_practitioner.email}</a></span> </>)
                                                })}
                                            </p> : <p>No records found</p>}
                                    </div>
                                </div>
                            </Carousel.Item>


                            <Carousel.Item className="item">
                                {this.state.firstTime ?
                                    <h1 className="topcont">
                                        We are fetching your GSTIN data from GSTN Portal and preparing Snapshot for
                                        first time use.<br /> Meanwhile have a look at your GSTIN Information
                                    </h1>
                                    :
                                    <h1 className="topcont">
                                        Here is a quick look at your GSTIN Information.
                                    </h1>
                                }

                                <img src={GIF_animation} alt="Bootstrap Touch Slider" className="slide-image" />
                                <div className="bs-slider-overlay"></div>

                                <div className="slide-text slide_style_right">
                                    <div className="animation_1">
                                        <h1 className="leftpadd textblodfs" data-animation="animated fadeInUp ">
                                            <span className="bussuserbox"><FontAwesomeIcon icon={faBuilding} /></span> Place of Business
                                        </h1>
                                        {gstInfo && gstInfo.business_address ?
                                            <p data-animation="animated fadeInUp">
                                                Address: <span className="textblodfs textblue">{gstInfo.business_address.address}</span><br />
                                                Mobile No.: <span className="textblodfs"><a href={"tel:" + gstInfo.business_address.mobile}> {gstInfo.business_address.mobile}</a>
                                                </span><br />
                                                Email: <span className="textblodfs"><a href={"mailto:" + gstInfo.business_address.email}>{gstInfo.business_address.email}</a></span>
                                            </p> : null}

                                    </div>

                                    {gstInfo && gstInfo.additional_address && gstInfo.additional_address.length > 0 ?
                                        <div className="animation_2">
                                            <h1 className="leftpadd textblodfs" data-animation="animated fadeInUp ">
                                                <span className="bussuserbox"><FontAwesomeIcon icon={faBuilding} /></span> Place of Business
                                            </h1>
                                            <p data-animation="animated fadeInUp" id="additional_view_btn">
                                                There are <span className="textblodfs textblue">{gstInfo.additional_address.length}</span> Additional Place of Businesses.<br />
                                                <span onClick={() => {
                                                    document.getElementById('additional_address').style.display = "block";
                                                    document.getElementById('additional_view_btn').style.display = "none"
                                                }} className="wanttoexbtn" data-animation="animated fadeInUp">
                                                    Click here to view details.
                                                </span>
                                            </p>
                                            <div id="additional_address" style={{ display: 'none' }}>
                                                {gstInfo.additional_address.map((additional_address, index) => {
                                                    return (
                                                        <p data-animation="animated fadeInUp">
                                                            Address: <span className="textblodfs textblue">{additional_address.address}</span><br />
                                                            Mobile No.: <span className="textblodfs"><a href={"tel:" + additional_address.mobile}> {additional_address.mobile}</a>
                                                            </span><br />
                                                            Email: <span className="textblodfs"><a href={"mailto:" + additional_address.email}>{additional_address.email}</a></span>
                                                        </p>
                                                    )
                                                })}

                                            </div>

                                        </div>
                                        : null}
                                </div>

                            </Carousel.Item>


                            <Carousel.Item className="item">
                                {this.state.firstTime ?
                                    <h1 className="topcont">
                                        We are fetching your GSTIN data from GSTN Portal and preparing Snapshot for
                                        first time use.<br /> Meanwhile have a look at your GSTIN Information
                                    </h1>
                                    :
                                    <h1 className="topcont">
                                        Here is a quick look at your GSTIN Information.
                                    </h1>
                                }

                                <img src={GIF_animation} alt="Bootstrap Touch Slider" className="slide-image" />
                                <div className="bs-slider-overlay"></div>

                                <div className="slide-text slide_style_right">

                                    {gstInfo && gstInfo.filing_data && gstInfo.filing_data.last_6_month ?
                                        <div className="animation_1">
                                            <h1 className="tablefilingscreen" data-animation="animated fadeInUp ">
                                                Filing Status for Monthly/Quarterly in last 6 months
                                            </h1>
                                            <div className="tbourbor" data-animation="animated fadeInUp ">
                                                <div
                                                    className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                                    <table className="table table-striped finlingsttb">
                                                        <thead className="topbornonetb">
                                                            <th>Tax Period</th>
                                                            <th>GSTR 1</th>
                                                            <th>GSTR 3B </th>
                                                        </thead>
                                                        <tbody>
                                                            {gstInfo.filing_data.last_6_month.length > 0 ?
                                                                gstInfo.filing_data.last_6_month.map((last_6_month, index) => {
                                                                    return (
                                                                        <tr className="trtdcontentblue">
                                                                            <td>{last_6_month.tax_period}</td>
                                                                            <td className="text-center">{last_6_month.gst_1}</td>
                                                                            <td className="text-center">{last_6_month.gst_3}</td>
                                                                        </tr>
                                                                    )
                                                                }) :
                                                                <tr className="trtdcontentblue">
                                                                    <td colSpan="3" className="text-center">No Data Found</td>
                                                                </tr>
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div></div> : null}

                                    {gstInfo && gstInfo.filing_data && gstInfo.filing_data.last_2_year ?
                                        <div className="animation_2">
                                            <h1 className="tablefilingscreen titleblueclr" data-animation="animated fadeInUp ">Filing Status for Annual Returns in last 2 Years</h1>
                                            <div className="tbourbor" data-animation="animated fadeInUp ">
                                                <div
                                                    className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                                    <table className="table table-striped finlingsttb textblueclr">
                                                        <thead className="topbornonetb">
                                                            <th>Tax Period</th>
                                                            <th>GSTR 9</th>
                                                            <th>GSTR 9C </th>
                                                        </thead>
                                                        <tbody>
                                                            {gstInfo.filing_data.last_2_year.length > 0 ?
                                                                gstInfo.filing_data.last_2_year.map((last_2_year, index) => {
                                                                    return (
                                                                        <tr className="trtdcontentblue">
                                                                            <td>{last_2_year.tax_period}</td>
                                                                            <td className="text-center">{last_2_year.gst_1}</td>
                                                                            <td className="text-center">{last_2_year.gst_3}</td>
                                                                        </tr>
                                                                    )
                                                                }) :
                                                                <tr className="trtdcontentblue">
                                                                    <td colSpan="3" className="text-center">No Data Found</td>
                                                                </tr>
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div></div> : null}
                                </div>
                            </Carousel.Item>


                            <Carousel.Item className="item">
                                {this.state.firstTime ?
                                    <h1 className="topcont">
                                        We are fetching your GSTIN data from GSTN Portal and preparing Snapshot for
                                        first time use.<br /> Meanwhile have a look at your GSTIN Information
                                    </h1>
                                    :
                                    <h1 className="topcont">
                                        Here is a quick look at your GSTIN Information.
                                    </h1>
                                }

                                <img src={GIF_animation} alt="Bootstrap Touch Slider" className="slide-image" />
                                <div className="bs-slider-overlay"></div>

                                <div className="slide-text slide_style_right Electronicsect">
                                    <div className="animation_1">
                                        <h1 className="tablefilingscreen" data-animation="animated fadeInUp ">
                                            <span className="bussuserbox"><FontAwesomeIcon icon={faMoneyBillAlt} /></span> Electronic Cash Ledger
                                        </h1>
                                        <div className="tbourbor" data-animation="animated fadeInUp ">
                                            <div
                                                className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                                <table className="table table-striped finlingsttb">
                                                    <thead className="topbornonetb">
                                                        <th>IGST</th>
                                                        <th>CGST</th>
                                                        <th>SGST</th>
                                                        <th>Cess</th>
                                                        <th>Total</th>
                                                    </thead>
                                                    <tbody>
                                                        {gstInfo && gstInfo.gst_ledger && gstInfo.gst_ledger.summary && gstInfo.gst_ledger.summary.length > 0 ?
                                                            gstInfo.gst_ledger.summary.map((gst_ledger, index) => {
                                                                if (gst_ledger.key === 'cash') {
                                                                    return (<tr className="trtdcontentblue">
                                                                        <td className="text-right">{gst_ledger.igst_amt}</td>
                                                                        <td className="text-right">{gst_ledger.cgst_amt}</td>
                                                                        <td className="text-right">{gst_ledger.sgst_amt}</td>
                                                                        <td className="text-right">{gst_ledger.cess_amt}</td>
                                                                        <td className="text-right">{gst_ledger.total_amt}</td>
                                                                    </tr>)
                                                                }
                                                            }) :
                                                            <tr className="trtdcontentblue">
                                                                <td colSpan="5" className="text-center">Data Not Found</td>
                                                            </tr>
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="animation_2">
                                        <h1 className="tablefilingscreen titleblueclr" data-animation="animated fadeInUp ">
                                            <span className="bussuserbox"><FontAwesomeIcon icon={faCreditCard} /></span> Credit Ledger
                                        </h1>
                                        <div className="tbourbor" data-animation="animated fadeInUp ">
                                            <div
                                                className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                                <table className="table table-striped finlingsttb textblueclr">
                                                    <thead className="topbornonetb">
                                                        <th>IGST</th>
                                                        <th>CGST</th>
                                                        <th>SGST</th>
                                                        <th>Cess</th>
                                                        <th>Total</th>
                                                    </thead>
                                                    <tbody>
                                                        {gstInfo && gstInfo.gst_ledger && gstInfo.gst_ledger.summary && gstInfo.gst_ledger.summary.length > 0 ?
                                                            gstInfo.gst_ledger.summary.map((gst_ledger, index) => {
                                                                if (gst_ledger.key === 'credit') {
                                                                    return (<tr className="trtdcontentblue">
                                                                        <td className="text-right">{gst_ledger.igst_amt}</td>
                                                                        <td className="text-right">{gst_ledger.cgst_amt}</td>
                                                                        <td className="text-right">{gst_ledger.sgst_amt}</td>
                                                                        <td className="text-right">{gst_ledger.cess_amt}</td>
                                                                        <td className="text-right">{gst_ledger.total_amt}</td>
                                                                    </tr>)
                                                                }
                                                            }) :
                                                            <tr className="trtdcontentblue">
                                                                <td colSpan="5" className="text-center">Data Not Found</td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </Carousel.Item>


                            <Carousel.Item className="item">
                                {this.state.firstTime ?
                                    <h1 className="topcont">
                                        We are fetching your GSTIN data from GSTN Portal and preparing Snapshot for
                                        first time use.<br /> Meanwhile have a look at your GSTIN Information
                                    </h1>
                                    :
                                    <h1 className="topcont">
                                        Here is a quick look at your GSTIN Information.
                                    </h1>
                                }

                                <img src={GIF_animation} alt="Bootstrap Touch Slider" className="slide-image" />
                                <div className="bs-slider-overlay authoverclrscreen"></div>

                                <div className="slide-text slide_style_right">
                                    <div className="animation_1">
                                        <h1 className="leftpadd" data-animation="animated fadeInUp ">
                                            <span className="bussuserbox"><FontAwesomeIcon icon={faInfoCircle} /></span> All GSTINs linked to this PAN
                                        </h1>
                                        <p data-animation="animated">
                                            <ol className="authenlist fadeInUp" data-animation="animated fadeInUp">
                                                {gstInfo && gstInfo.gst_linked_pan && gstInfo.gst_linked_pan.map((gst_linked_pan, index) => {
                                                    return (<li> {gst_linked_pan}</li>)
                                                })}
                                            </ol>
                                        </p>
                                    </div>

                                </div>
                            </Carousel.Item>


                            <Carousel.Item className="item">
                                {this.state.firstTime ?
                                    <h1 className="topcont">
                                        We are fetching your GSTIN data from GSTN Portal and preparing Snapshot for
                                        first time use.<br /> Meanwhile have a look at your GSTIN Information
                                    </h1>
                                    :
                                    <h1 className="topcont">
                                        Here is a quick look at your GSTIN Information.
                                    </h1>
                                }

                                <img src={GIF_animation} alt="Bootstrap Touch Slider" className="slide-image" />
                                <div className="bs-slider-overlay authoverclrscreen"></div>

                                <div className="slide-text slide_style_right">

                                    <div className="animation_1">
                                        <h1 className="leftpadd textblodfs" data-animation="animated fadeInUp ">
                                            <span className="bussuserbox"><FontAwesomeIcon icon={faIdCard} /></span> Authentication
                                        </h1>
                                        <p data-animation="animated fadeInUp">
                                            <ol className="authenlist" data-animation="animated fadeInUp">
                                                <li>
                                                    Your <span className="textblodfs">Aadhaar</span> is
                                                    {gstInfo && gstInfo.authentication && gstInfo.authentication.aadhar_status ? <span className="textblodfs"> Authenticated</span> : <span className="textblodfs"> Unauthenticated</span>}

                                                </li>
                                                <li>
                                                    Your <span className="textblodfs">e-KYC</span> is
                                                    {gstInfo && gstInfo.authentication && gstInfo.authentication.kyc_status ? <span className="textblodfs"> Verified </span> : <span className="textblodfs"> Unverified </span>}

                                                </li>
                                            </ol>
                                        </p>
                                    </div>

                                </div>

                                {(gstInfo?.bankaccounts ?? []).length > 0 &&
                                    <div className="slide-text slide_style_right addbank_toppadd">
                                        <div className="animation_1">
                                            <h1 className="leftpadd textblodfs" data-animation="animated fadeInUp ">
                                                <span className="bussuserbox addbankiconbox"> <img src={Bank_icon} alt="Eye" width="17" /> </span>
                                                Bank Account Details
                                            </h1>
                                            <p data-animation="animated fadeInUp">
                                                <ol className="authenlist" data-animation="animated fadeInUp">
                                                    {gstInfo.bankaccounts.map((details, key) => {
                                                        return (<li>{details.bknm} <span className="textblodfs pl-2 text-right">{details.acno}</span></li>)
                                                    })}
                                                </ol>
                                            </p>
                                        </div>
                                    </div>
                                }


                            </Carousel.Item>

                            <Carousel.Item className="item">
                                {this.state.firstTime ?
                                    <h1 className="topcont">
                                        We are fetching your GSTIN data from GSTN Portal and preparing Snapshot for
                                        first time use.<br /> Meanwhile have a look at your GSTIN Information
                                    </h1>
                                    :
                                    <h1 className="topcont">
                                        Here is a quick look at your GSTIN Information.
                                    </h1>
                                }

                                <img src={GIF_animation} alt="Bootstrap Touch Slider" className="slide-image" />
                                <div className="bs-slider-overlay"></div>

                                <div className="slide-text slide_style_right Electronicsect">

                                    <div className="animation_1">
                                        <h1 className="tablefilingscreen" data-animation="animated fadeInUp">
                                            Dealing In Goods and Services
                                        </h1>
                                        <div className="tbourbor" data-animation="animated fadeInUp">
                                            <div
                                                className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                                <table className="table table-striped finlingsttb">
                                                    <tbody>
                                                        <tr className="topbor0 trtdcontentblue">
                                                            <th className="text-center tbrightsidebor" style={{ width: '109px' }}>Goods / Services</th>
                                                            <th className="text-center tbrightsidebor" style={{ width: '70px' }}>HSN</th>
                                                            <th className="text-center" style={{ width: '200x' }}>Description</th>
                                                        </tr>
                                                        {gstInfo && gstInfo.goods_services && gstInfo.goods_services && gstInfo.goods_services.length > 0 ?
                                                            gstInfo.goods_services.map((goods_services, index) => {
                                                                return (<tr className="trtdcontentblue">
                                                                    <td className="text-left tbrightsidebor">{goods_services.text}</td>
                                                                    <td className="text-center tbrightsidebor">{goods_services.hsn}</td>
                                                                    <td className="overflowtooltiphabitupop" title={goods_services.description}>{goods_services.description}</td>
                                                                </tr>)
                                                            }) :
                                                            <tr className="trtdcontentblue">
                                                                <td colSpan="3" className="text-center">Data Not Found</td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </Carousel.Item>
                        </Carousel>

                        {this.state.loading < 100 ?
                            <div className="progress_bar">
                                <ProgressBar
                                    value={this.state.loading}
                                    style={{ width: '100%' }}
                                    labelPlacement="center"
                                    label={(props) => (<strong> Loading... {this.state.loading}%</strong>
                                    )}
                                />
                            </div>
                            :
                            <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                                <Button variant="default" className="GOtodashbtn" onClick={() => { this.hidePopup() }}>Close</Button>
                            </div>
                        }

                    </div>

                </Modal.Body>
            </Modal >
        );
    }
}
export default GstInfoAnimation;
