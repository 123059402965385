import { BASE_URL, LB_BASE_URL } from "../app/constants";
import { getAuthToken } from "../utils/AuthToken";
import { getRandomInt } from "../utils/UtilityFunctions";

export const loginAxiosConfig = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

export const getConfig = () => ({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getAuthToken(true)}`,
  }
});

export const getConnectorConfig = { crossdomain: true };

export function getLoadBalancedApiBaseUrl() {
  if (!Array.isArray(LB_BASE_URL) || LB_BASE_URL.length === 0) {
    return BASE_URL;
  }

  let urls = [];
  LB_BASE_URL.forEach((item) => {
    for (let i = 0; i < item.wt; i++) urls.push(item.url);
  });

  const idx = getRandomInt(1, urls.length);
  return urls[idx - 1];
}