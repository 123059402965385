import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { BASE_PATH } from '../../app/constants';
import { useSelector } from 'react-redux';
import { selectHeaderData } from '../header/headerSlice';



const SubscribePopup = (props) => {
    const headerData = useSelector(selectHeaderData);

    const subscribeNow = () => {
        props.handleClose();
        props.history.push(`${BASE_PATH}plans`);
    }

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName="yourtrialexpirpoup"
                keyboard={false}
                className="yourtrialexpirpoup"
            >
                <Modal.Header closeButton className="yourtrialexpirhead">
                    <Modal.Title className="trialexpttle">Your Subscription has Expired</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <p className="youtrialexptextfs">Your subscription has expired. Please subscribe to a paid plan to enjoy
                                uninterrupted services. </p>
                        </div>
                        <div className="col-md-12 text-center pt-2 pb-1">

                            <button type="button" onClick={() => subscribeNow()} class="btn btn-default trialexppaynowbtn"> {parseInt(headerData?.is_next_sub_renewal ?? 0) === 1 ? "Renew Now" : "Subscribe Now"} </button>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default SubscribePopup;
