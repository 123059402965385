import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import {
  faCheck, faFrownOpen
} from '@fortawesome/free-solid-svg-icons';
import "./PurchaseImportStatus.css";

const PurchaseImportStatus = (props) => {
  const {
    show, onClose, data, onReImport, purchaseImportText, handleSucessProcees
  } = props;

  let showSuccess = false;
  data.forEach((item) => {
    if (item.imported_count > 0) {
      showSuccess = true;
    }
  });

  return(
    <Modal
      show={show}
      onHide={onClose}
      animation
      className="messageimportmod"
    >
      <Modal.Header className="messgimportheader">
        <h4 className="modal-title">Import Status</h4>
        <button type="button" className="close" onClick={() => onClose()}>
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body className="py-1 px-0">
        <div className="col-md-12">
					<p className="ctareimptext">{purchaseImportText}</p>
					<div className="card smspopupcard">
						<div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
               <table className="table table-striped messageafterimporttb mb-0">
								  <thead>
									<tr>
										<th width="120">Section</th>
										<th width="65">Invoices in Excel  </th>
										<th width="65" className='pl-1'> Imported</th>
									</tr>
								</thead>
								 <tbody>
                 <tr>
                    <td colSpan={3} className='py-0 px-0'>
                    <table className="importst_innertbheigtfixed">
                      <tbody>
                        {data.map((item) => {
                          return (
                            <tr>
                              <td width="120" className="importstatus_tboverflowtextbox">
                              <span data-toggle="tooltip" data-placement="top" title={item.section}>{item.section}</span>
                              </td>
                              <td width="65" className="text-center">{item.found_count} </td>
                              <td width="65" className={showSuccess ? "text-right succicongreenclr pr-3" : "text-center sadicon pr-2"}> {item.imported_count} 
                                <FontAwesomeIcon icon={showSuccess ? faCheck : faFrownOpen}/>
                              </td>
                            </tr>
                         
                            );
                          })}
                          </tbody>
                        </table>
                      </td>
                     </tr>         
                  </tbody>   
							 </table>
						</div>
					</div>
				</div>
        <div className="col-md-12 text-center py-2">
					<button type="button" className="btn btn-default reimportbtn" onClick={() => onReImport()}>
						Re-Import
          </button>
          <button type="button" className="btn btn-default okaybtn" onClick={() => handleSucessProcees()}>
            Okay
          </button>
				</div>
      </Modal.Body>
      
        
    
    </Modal>
  );
}

export default PurchaseImportStatus;
