/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectHeaderData } from '../header/headerSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faCircle, faCalendarMinus } from '@fortawesome/free-solid-svg-icons';
import "./index.css";
import Nonotificationimg from "../../images/no_notification_img.png";
import { BASE_PATH, MONTH_NUMBER_TO_TEXT, PROCESS_TYPES } from '../../app/constants';
import { selectTaskData } from '../TaskQueue/TaskQueueSlice';
import { updateTask, removeTask } from '../../utils/taskQueueHelper';
import { withRouter } from 'react-router-dom';
import moment from 'moment';


const Notifications = ({ history }) => {

  const ref = useRef(null);

  const taskData = useSelector(selectTaskData);
  const headerData = useSelector(selectHeaderData);

  const [showNotifications, setShowNotifications] = useState(false);


  const openTask = (task) => {
    const url = `${BASE_PATH}gstr1/books/save-data?company_id=${task.company_id}&year=${task.return_year}&month=${task.return_month}`;
    const newTask = { ...task, 'is_opened': true };
    if (task.process_status !== 'IN_PROGRESS') {
      removeTask(headerData.mobile_number, newTask);
    } else {
      updateTask(headerData.mobile_number, newTask);
    }
    setShowNotifications(false);
    history.push(url);
    toggleNotifications();
  }

  let haveUnOpenedTask = false;
  taskData.forEach((task) => {
    if (!task.is_opened && task.process_status !== 'IN_PROGRESS') {
      haveUnOpenedTask = true;
    }
  })

  const toggleNotifications = () => {
    if (showNotifications) {
      document.getElementById("gstrbellicon_rightMenu").style.width = "0";
    } else {
      document.getElementById("gstrbellicon_rightMenu").style.width = "400px";
    }
    setShowNotifications(!showNotifications);
  }

  let totalNotifications = taskData.filter((task) => task.process_status !== 'IN_PROGRESS').length;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        if (event.target.className !== 'header_rightside_bellicon' &&
          event.target.className !== 'header_rightside_bellicon_dotredclr_icon') {
          document.getElementById("gstrbellicon_rightMenu").style.width = "0";
          setShowNotifications(false);
        }
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return (
    <>
      <div className="animtrans_box">
        <button className="bellicon_innerbox pulse" onClick={() => toggleNotifications(!showNotifications)} id="bellbox_animatebutton">
          <span className="header_rightside_bellicon">
            <FontAwesomeIcon icon={faBell} />
          </span>
          {haveUnOpenedTask && (
            <span className="header_rightside_bellicon_dotredclr_icon">
              <FontAwesomeIcon icon={faCircle} />
            </span>
          )}
        </button>
      </div>

      <div ref={ref} className="bellicon_panelbox" id="gstrbellicon_rightMenu">
        <div className="gstr_notifict__box">
          <span className="gstr_notifict_titletextfs">Notifications </span>
          <span onClick={() => toggleNotifications(false)} className="headervelliconbox_closebtn text-right"> &times;</span>
        </div>
        <div className="bellicon_panelbox_innerbody">
          <div className="bellicon_contentbox" style={{ width: "400px" }}>
            <ul className="px-0">
              {taskData.map((task) => {

                /* Skip In_PROGRESS status tasks*/
                if (task.process_status === 'IN_PROGRESS') return null;

                let last_updated_unit = 'day(s)';
                let last_updated = moment().diff(moment(task.last_updated_at), 'days');
                if (last_updated === 0) {
                  last_updated = moment().diff(moment(task.last_updated_at), 'hours');
                  last_updated_unit = 'hour(s)';
                }
                if (last_updated === 0) {
                  last_updated = moment().diff(moment(task.last_updated_at), 'minutes');
                  last_updated_unit = 'minute(s)';
                }
                if (last_updated === 0) {
                  last_updated = moment().diff(moment(task.last_updated_at), 'seconds');
                  last_updated_unit = 'second(s)';
                }
                return (
                  <li style={{ width: '100%' }} key={task.uniqueId} className={`${task.is_opened ? 'libgclr_white' : 'libgclr_lightyellow'} ulli_bootombor_grayclr`} onClick={() => openTask(task)}>
                    <div>
                      <span className="belliconpanel_textoverflow pl-0">
                        <span data-toggle="tooltip" data-placement="top" title={task.company_name}>
                          {task.company_name}
                        </span>
                      </span>
                      <span className="px-1">| </span> {task.gst_number} <span className="px-1">|</span>{PROCESS_TYPES[task.process_type]}<span className="px-1">|</span>{MONTH_NUMBER_TO_TEXT[task.return_month]} {task.return_year}
                    </div>
                    {task.process_status === 'STUCK' ? (
                      <p className="vellicontextfs">
                        Check the Upload Status of Data for <span class="monthtextblod">{MONTH_NUMBER_TO_TEXT[task.return_month]} {task.return_year}</span>.<br />
                      </p>
                    ) : task.process_status === 'SUCCESS' ? (
                      <p className="vellicontextfs">

                        {PROCESS_TYPES[task.process_type]} upload attempted at {task.process_info.save_start_at} for <span class="monthtextblod">{MONTH_NUMBER_TO_TEXT[task.return_month]} {task.return_year}</span> is processed successfully.<br />
                      </p>
                    ) : task.process_status === 'ERROR' ? (
                      <p className="vellicontextfs">
                        {PROCESS_TYPES[task.process_type]} upload attempted at {task.process_info.save_start_at} for <span class="monthtextblod">{MONTH_NUMBER_TO_TEXT[task.return_month]} {task.return_year}</span> has failed.<br />
                      </p>
                    ) : task.process_info ? (
                      <p className="vellicontextfs">
                        {PROCESS_TYPES[task.process_type]} upload attempted at {task.process_info['save_start_at']}
                      </p>
                    ) : null}

                    <p className="rightside_contentst">
                      <span className="clend_iconfsclr pr-1">
                        <FontAwesomeIcon icon={faCalendarMinus} />
                      </span> {last_updated === 0 && last_updated_unit === 'second(s)' ? 'Now' : `${last_updated} ${last_updated_unit} ago`}
                    </p>
                  </li>
                );
              })}
            </ul>
            {totalNotifications === 0 && (
              <div className="nonotification_imgbox text-center">
                <p className="nonotificat_boldtext">
                  No notifications yet!
                </p>
                <img src={Nonotificationimg} width="180" alt="" />

                <p className="nonotificat_textgrayclrfs">
                  "We will notify you when something arrives!"
                </p>
              </div>
            )}
          </div>
        </div>

      </div>

    </>
  );
}

export default withRouter(Notifications);