import { useEffect, useState, useRef } from 'react';

const useIntersectionObserver = (options) => {
    const [isIntersecting, setIntersecting] = useState(false);
    const ref = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setIntersecting(entry.isIntersecting);
        }, options);

        const currentRef = ref.current; // Store ref.current in a variable

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) { // Use the stored value for cleanup
                observer.unobserve(currentRef);
            }
        };
    }, [options]); // No need to include ref in the dependency array

    return [ref, isIntersecting];
};

export default useIntersectionObserver;
