import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import NumericInput from "../../components/NumericInput";
import { selectHeaderData } from "../header/headerSlice";
import { verify2ADataV2 } from "../../api/2adataAPI";
import UpdateCredentialsV2 from "./UpdateCredentialsV2";
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import OtpVerifiyForCaptcha from "../../components/profile/OtpVerifiyForCaptcha";
import { getCaptchaFromConnector, verifyGSTPortalLogin, verifyGSTPortalLoginWithOTP, startSyncAction } from "../../api/localConnectorAPI";
import { getCompanyDetailsByID } from "../../api/companyAPI";
import { CONNECTOR_ERRORS_MAPPING, HEADER_CONNECTOR_DIGEST_KEY } from "../../app/constants";
import { updateCompanyDetails } from "../../api/companyAPI";

const SyncDataByConnector = ({ history, ...props }) => {
  const {
    company_id,
    show,
    handleClose,
    refreshSupplierList,
    syncStatusFailureMsg,
    syncStatusRemindLater,
    companyGSTNumber,
    port,
   //setParentError,
  } = props;

  const headerData = useSelector(selectHeaderData);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [captchaLoading, setCaptchaLoading] = useState(false);
  const [captchaImg, setCaptchaImg] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [openSetPassword, setOpenSetPassword] = useState(false);
  const [companyGstNumber, setCompanyGstNumber] = useState("");
  const [gstportalUsername, setGstportalUsername] = useState("");
  const [gstportalPassword, setGstportalPasword] = useState("");
  const [getCaptchaData, setGetCaptchaData] = useState({});
  const [loadingFirst, setLoadingFirst] = useState(true);
  const [otpRequired, setOtpRequired] = useState(false)
  const [captchaRef, setCaptchaRef] = useState(null);

  const getCredentials = () => {
    async function fetchEverything() {
      try {
        const result = await getCompanyDetailsByID(company_id);
        setGstportalUsername(result?.data?.gstportal_username);
        setGstportalPasword(result?.data?.gstportal_password);
        setOpenSetPassword(!result?.data?.gstportal_isverified);
        setCompanyGstNumber(result?.data?.gst_number)
        getCaptcha();
      } catch (err) {
        setError(err.message);
      }
    }
    fetchEverything();
  }

  const getCaptcha = () => {
    async function fetchEverything() {
      try {
        const result = await getCaptchaFromConnector(port);
        setCaptchaImg(result.data.captcha_image);
        setCaptchaRef(result.data.captcha_ref);
        setGetCaptchaData(result.data);
        setCaptcha("");
      } catch (err) {
        console.error('error: ', err);
        if (err?.message?.[0] !== undefined) {
          setError(CONNECTOR_ERRORS_MAPPING[err?.message[0]] ?? CONNECTOR_ERRORS_MAPPING['traces.unknown_error']);
        } else {
          setError(CONNECTOR_ERRORS_MAPPING[err?.message] ?? CONNECTOR_ERRORS_MAPPING['traces.unknown_error']);
        }
      } finally {
        setCaptchaLoading(false);
        setCaptcha('');
        setLoadingFirst(false);
      }
    }
    setCaptchaLoading(true);
    fetchEverything();
  };

  const loadCaptcha = () => {
    setError(null);
    setCaptchaLoading(true);
    getCaptcha();
  };

  const verifyCatpcha = (props) => {
    if (!props?.otp) {
      if (captcha.length === 0) {
        setError("Please enter captcha!");
        return;
      }
      if (captcha.length < 6) {
        setError("Please enter valid captcha!");
        return;
      }
    }
    const data = {
      captcha_ref: captchaRef,
      gstportal_username: gstportalUsername,
      gstportal_password: gstportalPassword,
      company_gst_number: companyGstNumber,
      ...(props?.otp ? { otp: props.otp } : { captcha_text: captcha }),
      //captcha_text: captcha,
    };

    async function fetchEverything() {
      async function fetch2AData() {
        const result = props?.otp ? await verifyGSTPortalLoginWithOTP(port, data) : await verifyGSTPortalLogin(port, data);
        //Start GST portal data syncing using connector  
        if (result.data.status === 1) {
          startSyncing();
        } else {
          handleClose(false);
        }
        
      }
      try {
        await fetch2AData();
        setError(null);
        setOtpRequired(false)

      } catch (err) {

        if (err?.status === 423) {
          setOtpRequired(true)
        } else {
          console.log('Error', err);
          setError(CONNECTOR_ERRORS_MAPPING[err?.message] ?? CONNECTOR_ERRORS_MAPPING['gst.unknown_error']);
          setCaptcha("");
          getCaptcha();
          if(err?.message === 'gst.invalid_credentials'){
            updateCompanyData(); 
          }
        }

      } finally {
        setLoading(false);
      }
    }
    setLoading(true);
    fetchEverything();
  };

  const updateCompanyData = async () => {
    const data = {
      company_id: company_id,
      gstportal_isverified: false
    };    
    try {
      await updateCompanyDetails(data);
      setTimeout(() => {window.location.reload()},500)
    } catch (err) {
      console.error('Error in updating: ', err);
    }
  }

  const startSyncing = () => {
    async function fetchEverything() {

      try {
        const result = await verify2ADataV2({ captcha_ref: captchaRef, hard_refresh: 1, company_gst_number: companyGstNumber });
        const responseData = result.data ?? null;
        const responseHeader = result.headers ?? null;

        if (Number(responseData.status) === 1) {
          const connectorRequest = {
            "data": responseData.data
          };
          let extraHeader = {};
          extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;
          try {
            const response = await startSyncAction(port, connectorRequest, extraHeader);
            if (Number(response?.status ?? 0) === 1) {
              let return_filling_status_data = localStorage.getItem(
                "return-filling-status-data-v4"
              );
              return_filling_status_data = return_filling_status_data
                ? JSON.parse(return_filling_status_data)
                : [];
              let current_filling_return_data = return_filling_status_data.filter(
                (return_data, index) => {
                  return return_data.gst_number !== headerData.gst_number;
                }
              );
              localStorage.setItem(
                "return-filling-status-data-v4",
                JSON.stringify(current_filling_return_data)
              );
  
              handleClose(false);
              refreshSupplierList('Syncing process started successfully', true);
            }
          } catch (err) {
            console.log('Error', err);
            setError(CONNECTOR_ERRORS_MAPPING[err?.message] ?? CONNECTOR_ERRORS_MAPPING['gst.unknown_error']);
          }

        }
       
      }catch (err) {
        console.log('Error', err);
        setError(err?.data?.errors[0])
        setCaptcha("")
      }
    }
    fetchEverything()
  }

  const handleRemindLater = () => {
    localStorage.setItem(
      `remind_later_ts${companyGSTNumber}`,
      moment().add(1, "hours").unix()
    );
    handleClose(false);
  };

  const handleCloseOtp = () => {
    setOtpRequired(false);
    getCaptcha()
  }

  useEffect(() => {
    getCredentials();
    document.getElementById("captcha").focus();
    if (syncStatusFailureMsg !== "") {
      setError(syncStatusFailureMsg);
    }
    //eslint-disable-next-line
  }, [company_id]);

  return (
    <>
      {loadingFirst ? <LoadingIndicator /> : null}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="bookVerify bookVerify_modal"
      >
        {openSetPassword ? (
          <UpdateCredentialsV2
            company_id={company_id}
            headerData={headerData}
            gstportalUsername={gstportalUsername}
            companyGstNumber={companyGstNumber}
            getCaptchaData={getCaptchaData}
            getCaptcha={getCaptcha}
            handleClose={handleClose}
            refreshSupplierList={refreshSupplierList}
            verifyCaptch={true}
            port={port}
          />
        ) : (
          <>
            <Modal.Header closeButton className="bookVerify">
              <Modal.Title>Refresh Portal Data</Modal.Title>
            </Modal.Header>
            <Modal.Body className="py-0 px-0">
              <div className="container mt-2">
                <div className="card bookVerifyCard">
                  <div className="row mt-0">
                    <div className="form-group col-md-12 mb-0">
                      <div className="row">
                        <div className="form-group col-md-6">
                          <div className="captcha_img">
                            {!captchaLoading ? (
                              <img
                                src={`data:image/png;base64, ${captchaImg}`}
                                alt=""
                              />
                            ) : (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}
                          </div>
                        </div>
                        <div
                          className="form-group col-md-6"
                          style={{ marginTop: "8px" }}
                        >
                          <NumericInput
                            className="form-control"
                            id="captcha"
                            placeholder="Enter Captcha"
                            value={captcha}
                            maxLength={6}
                            onChange={(event) => setCaptcha(event.target.value)}
                            autoComplete="new-password"
                          />
                        </div>
                      </div>
                      <div className="form-group col-md-12 mb-0">
                        <p className="mb-1">
                          <small style={{ fontSize: '12px' }}>
                            Can't read the captcha?
                            <Button
                              variant="link"
                              onClick={() => loadCaptcha()}
                              style={{ margin: "-9px", fontSize: 12, verticalAlign: 'baseline' }}
                            >
                              Click here
                            </Button>
                            to refresh
                          </small>
                        </p>
                        <p className="mb-0"
                          style={{
                            fontSize: "12px",
                            fontWeight: "400",
                            color: "#797979",
                          }}
                        >
                          Please do not login at GSTN portal during this
                          process.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="bookVerify">
              {error ? <Alert variant="danger">{error}</Alert> : null}
              {syncStatusRemindLater ? (
                <Button
                  className="light-cancel-btn"
                  onClick={handleRemindLater}
                >
                  Remind Later
                </Button>
              ) : (
                <Button className="light-cancel-btn" onClick={handleClose}>
                  Close
                </Button>
              )}

              <Button
                className="bookVerifyBtn"
                id="zps_proceed_portal"
                onClick={verifyCatpcha}
                disabled={loading}
              >
                {loading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Loading...
                  </>
                ) : (
                  "Proceed"
                )}
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>

      {otpRequired && <OtpVerifiyForCaptcha handleClose={handleCloseOtp} callFunc={verifyCatpcha} error={error} setError={setError} />}

    </>
  );
};

export default SyncDataByConnector;