import React, { Suspense, lazy } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@progress/kendo-theme-default/dist/all.css';
import './features/listOfCompanies/Listofcompanies.css';
import './features/2AReco/V2/supplierReco/SuppilerReco.css';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import Header from './features/header/Header';
import PublicHeader from './features/header/PublicHeader';
import { BASE_PATH, IS_MAINTENANCE_MODE_ENABLED } from './app/constants';
import PrivateRoute from './routes/PrivateRoute';
import ScrollToTop from './components/ScrollToTop';
import AuthenticationRoute from './routes/AuthenticationRoute';
import LoadingIndicator from './components/loadingIndicator/LoadingIndicator';
import LoginScreen from './features/authentication/LoginScreen';
import SignUpScreen from './features/authentication/SignUpScreen';
import ForgetPass from './features/authentication/ForgetPass';
import ResetPass from './features/authentication/ResetPass';
import NotFound from './components/NotFound';
import Maintenance from './Maintenance';
import TaskQueue from './features/TaskQueue';
import RecoDashboard from './features/recoDashboard/RecoDashboard';
import multiLinking from './features/MultiLinking/MultiLinking';




export const LOGIN_PATHS = [
  `${BASE_PATH}login`,
  `${BASE_PATH}signup`,
  `${BASE_PATH}forgetpass`,
  `${BASE_PATH}resetpass`,
  `${BASE_PATH}maintenance`,
];

export const PUBLIC_PATHS = [
  `${BASE_PATH}pub-auth/sync-2a-2b-mail`,
  `${BASE_PATH}pub-auth/tally/client-access`,
];
// Dynamically loading routes

// Company
const AllListofcompanies = lazy(() => import('./features/listOfCompanies/AllListofcompanies'));
const RecentListofcompanies = lazy(() => import('./features/listOfCompanies/RecentListofcompanies'));
const UpdateCompany = lazy(() => import('./features/company/UpdateCompany'));
const GstinLogin = lazy(() => import('./features/company/GstinLogin'));
const BulkImportList = lazy(() => import('./features/listOfCompanies/BulkImportList'));

const PanLevelReports = lazy(() => import('./features/listOfCompanies/PanLevelReports'));
const Returnsummery = lazy(() => import('./features/listOfCompanies/Returnsummery'));
const BItemisedITC = lazy(() => import('./features/listOfCompanies/BItemisedITC'));
const Cashcreditelectronicstatement = lazy(() => import('./features/listOfCompanies/Cashcreditelectronicstatement'));
const ComparisonofGSTR13Bandbooks = lazy(() => import('./features/listOfCompanies/ComparisonofGSTR13Bandbooks'));
const Reconciliation = lazy(() => import('./features/listOfCompanies/Reconciliation'));
const ITCcomparision = lazy(() => import('./features/listOfCompanies/ITCcomparision'));

// Profile and User Management
const Profile = lazy(() => import('./features/profile/Profile'));
const Subscription = lazy(() => import('./features/profile/Subscription'));
const Plans = lazy(() => import('./features/Plans/Plans'));
const UserList = lazy(() => import('./features/userManagement/UserList'));
const UserProfile = lazy(() => import('./features/userManagement/UserProfile'));

// E-invoice
const UserListing = lazy(() => import('./features/eInvoice/UserListing'))
const EditUser = lazy(() => import('./features/eInvoice/EditUser'))
const CreateUserEinvoice = lazy(() => import('./features/eInvoice/CreateUser'))
const GenrateEinvoice = lazy(() => import('./features/eInvoice/GenerateEinvoice'))

// Return Register
const ReturnRegister = lazy(() => import('./features/returnRegister/ReturnRegister'));
const MonthWise = lazy(() => import('./features/returnRegister/MonthWise'));

// Orders and Notices
const OrdersAndNotices = lazy(() => import('./features/ordersAndNotices/OrdersAndNotices'));

// Return Dashboard
const ReturnDashboard = lazy(() => import('./features/returnDashboard/ReturnDashboard'));

// GSTR 1 (Books and Portal)
const GSTR1_Books = lazy(() => import('./features/gstr1/books/GSTR1_Books'));
const GSTR1_Books_detail = lazy(() => import('./features/gstr1/books/detail/GSTR'));
const GSTR1_Books_SaveDataOnPortal = lazy(() => import('./features/gstr1/books/saveDataOnPortal/SaveDataOnPortal'));
const GSTR1_Portal = lazy(() => import('./features/gstr1/portal/GSTR1_Portal'));
const GSTR1_Portal_detail = lazy(() => import('./features/gstr1/portal/detail/GSTR'));
const GSTR1_Portal_SaveDataOnPortal = lazy(() => import('./features/gstr1/portal/saveDataOnPortal/SaveDataOnPortal'));
const GSTR1_FileOnPortal = lazy(() => import('./features/gstr1/books/submitGSTR1/SubmitGSTR1'));

// GSTR 1A (Books and Portal)
const GSTR1A_Books = lazy(() => import('./features/gstr1a/books/GSTR1A_Books'));
const GSTR1A_Books_detail = lazy(() => import('./features/gstr1a/books/detail/GSTR'));
const GSTR1A_Books_SaveDataOnPortal = lazy(() => import('./features/gstr1a/books/saveDataOnPortal/SaveDataOnPortal'));
const GSTR1A_Portal = lazy(() => import('./features/gstr1a/portal/GSTR1A_Portal'));
const GSTR1A_Portal_detail = lazy(() => import('./features/gstr1a/portal/detail/GSTR'));
const GSTR1A_Portal_SaveDataOnPortal = lazy(() => import('./features/gstr1a/portal/saveDataOnPortal/SaveDataOnPortal'));
const GSTR1A_FileOnPortal = lazy(() => import('./features/gstr1a/books/submitGSTR1A/SubmitGSTR1A'));

// IFF (Books and Portal)
const IFF_Books = lazy(() => import('./features/iff/books/IFF_Books'));
const IFF_Books_detail = lazy(() => import('./features/iff/books/detail/IFF'));
const IFF_Books_SaveDataOnPortal = lazy(() => import('./features/iff/books/saveDataOnPortal/SaveDataOnPortal'));
const IFF_Portal = lazy(() => import('./features/iff/portal/IFF_Portal'));
const IFF_Portal_detail = lazy(() => import('./features/iff/portal/detail/IFF'));
const IFF_Portal_SaveDataOnPortal = lazy(() => import('./features/iff/portal/saveDataOnPortal/SaveDataOnPortal'));
const IFF_FileOnPortal = lazy(() => import('./features/gstr1/books/submitGSTR1/SubmitGSTR1'));

// R1RecoR1SectionReco
const R1vsBooksRecoSummary = lazy(() => import('./features/R1Reco/R1vsBooksRecoSummary'));
const R1SectionRecoDOCS = lazy(() => import('./features/R1Reco/R1SectionRecoDOCS'));
const R1SectionRecoSummary = lazy(() => import('./features/R1Reco/R1SectionRecoSummary'));
const R1PartyWiseSummary = lazy(() => import('./features/R1Reco/R1PartyWiseSummary'));

// GST 2A/2B Reco
const SupplierReco2B = lazy(() => import('./features/2BReco/V1/supplierReco/SuppilerReco'));
const InvoiceReco2B = lazy(() => import('./features/2BReco/V1/invoiceReco/InvoiceReco'));
const SuppilerReco = lazy(() => import('./features/2AReco/V2/supplierReco/SuppilerReco'));
const InvoiceReco = lazy(() => import('./features/2AReco/V2/invoiceReco/InvoiceReco'));
const MonthlyWiseRecoChartTable = lazy(() => import('./features/2AReco/V2/supplierReco/MonthlyWiseRecoChartTable'));
const SuppilerEmail = lazy(() => import('./features/2AReco/V2/supplierReco/email/SuppilerEmail'));
const ScheduleEmail = lazy(() => import('./features/2BReco/V1/email/ScheduleEmail'));
const EmailReview = lazy(() => import('./features/2BReco/V1/email/EmailReview'));
const EmailPreviewTally = lazy(() => import('./features/2BReco/V1/email/EmailPreviewTally'));
const Sync2A2BMail = lazy(() => import('./features/sync2A2BMail/Sync2A2BMail'));
const GSTR2BITCClaim = lazy(() => import('./features/itcClaim/gstr2B/ITCClaim'));
const GSTR2BPartyWiseITCClaim = lazy(() => import('./features/itcClaim/gstr2B/PartyWiseITCClaim'));

//Rate 2A/2B Reco
const RateReco2B = lazy(() => import('./features/2BReco/V1/rateReco/RateReco'));
const InvoiceRateReco2B = lazy(() => import('./features/2BReco/V1/invoiceReco/InvoiceRateReco'));
const RateReco = lazy(() => import('./features/2AReco/V2/rateReco/RateReco'));
const InvoiceRateReco = lazy(() => import('./features/2AReco/V2/invoiceReco/InvoiceRateReco'));

// GSTR 3B
const OffsetLiability = lazy(() => import('./features/gstr3B/offsetLiability/OffsetLiability'));
const InwardOutwardSupply = lazy(() => import('./features/gstr3B/inwardOutwardSupply/InwardOutwardSupply'));
const Review3B = lazy(() => import('./features/gstr3B/review3B/Review3B'));
const ConsolidatedGSTR3B = lazy(() => import('./features/gstr3B/review3B/ConsolidatedGSTR3B'));
const EligibleITC = lazy(() => import('./features/gstr3B/eligibleITC/EligibleITC'));
const Save3B = lazy(() => import('./features/gstr3B/save3B/Save3B'));

// GSTR 9
const Snapshots = lazy(() => import('./features/gstr9/snapshots/Snapshots'));
const Sales = lazy(() => import('./features/gstr9/sales/Sales'));
const ITC = lazy(() => import('./features/gstr9/itc/ITC'));
const TaxPaid = lazy(() => import('./features/gstr9/taxPaid/TaxPaid'));
const FyDetails = lazy(() => import('./features/gstr9/fyDetails/FyDetails'));
const Others = lazy(() => import('./features/gstr9/others/Others'));
const Review = lazy(() => import('./features/gstr9/review/Review'));
const Save9 = lazy(() => import('./features/gstr9/save/Save'));

// GSTR 9C
const RecoTurnover = lazy(() => import('./features/gstr9c/reco-turnover/RecoTurnover'));
const RecoITC = lazy(() => import('./features/gstr9c/reco-itc/RecoITC'));
const RecoTaxPaid = lazy(() => import('./features/gstr9c/reco-tax-paid/RecoTaxPaid'));
const AdditionalLiability = lazy(() => import('./features/gstr9c/additional-liability/AdditionalLiability'));
const Gstr9cReview = lazy(() => import('./features/gstr9c/review/Review'));
const Save9C = lazy(() => import('./features/gstr9c/save/Save'));
const EligibleITCType = lazy(() => import('./features/gstr3B/eligibleITC/EligibleITCType'));

//Request Tally
const PublicPageImportBookTally = lazy(() => import('./features/returnDashboard/PublicPageImportBookTally'));

//Form 3CA-CD/3CB-CD Annexure
const ListAllAnnexures = lazy(() => import('./features/3ca-3cb-cd-annexures/ListAllAnnexures'));
const GstInsBranchesList = lazy(() => import('./features/3ca-3cb-cd-annexures/GstInsBranchesList'));
const FormAnnexures = lazy(() => import('./features/3ca-3cb-cd-annexures/FormAnnexures'));



function App() {
  const location = useLocation();
  if (IS_MAINTENANCE_MODE_ENABLED) {
    return (
      <Switch>
        <Route path={`${BASE_PATH}maintenance`} component={Maintenance} />
        <Redirect to={`${BASE_PATH}maintenance`} />
      </Switch>
    );
  }

  return (
    <main>
      <ScrollToTop />
      {!PUBLIC_PATHS.includes(location.pathname) ? <><Header /> <TaskQueue /></> : <PublicHeader />}

      <Suspense fallback={<LoadingIndicator />}>
        <Switch>
          <PrivateRoute path={`${BASE_PATH}client/all`} component={AllListofcompanies} exact />
          <PrivateRoute path={BASE_PATH} component={RecentListofcompanies} exact />
          <PrivateRoute path={`${BASE_PATH}gstin-login`} component={GstinLogin} />
          <PrivateRoute path={`${BASE_PATH}einvoice-login`} component={CreateUserEinvoice} />
          <PrivateRoute path={`${BASE_PATH}update-company`} component={UpdateCompany} />
          <PrivateRoute path={`${BASE_PATH}client-master-bulkimport`} component={BulkImportList} />

          <PrivateRoute path={`${BASE_PATH}profile`} component={Profile} exact />
          <PrivateRoute path={`${BASE_PATH}subscription`} component={Subscription} exact />
          <PrivateRoute path={`${BASE_PATH}plans`} component={Plans} exact />
          <PrivateRoute path={`${BASE_PATH}user-management`} component={UserList} exact />
          <PrivateRoute path={`${BASE_PATH}user-profile`} component={UserProfile} />

          <PrivateRoute path={`${BASE_PATH}return-register/client-wise`} component={ReturnRegister} />
          <PrivateRoute path={`${BASE_PATH}return-register/month-wise`} component={MonthWise} />

          <PrivateRoute path={`${BASE_PATH}orders-and-notices`} component={OrdersAndNotices} />

          <PrivateRoute path={`${BASE_PATH}company/dashboard`} component={ReturnDashboard} />

          <PrivateRoute path={`${BASE_PATH}gstr1/books/summary`} component={GSTR1_Books} />
          <PrivateRoute path={`${BASE_PATH}gstr1/books/detail`} component={GSTR1_Books_detail} />
          <PrivateRoute path={`${BASE_PATH}gstr1/books/save-data`} component={GSTR1_Books_SaveDataOnPortal} />
          <PrivateRoute path={`${BASE_PATH}gstr1/portal/summary`} component={GSTR1_Portal} />
          <PrivateRoute path={`${BASE_PATH}gstr1/portal/detail`} component={GSTR1_Portal_detail} />
          <PrivateRoute path={`${BASE_PATH}gstr1/portal/save-data`} component={GSTR1_Portal_SaveDataOnPortal} />
          <PrivateRoute path={`${BASE_PATH}gstr1/file-on-portal`} component={GSTR1_FileOnPortal} />

          <PrivateRoute path={`${BASE_PATH}gstr1a/books/summary`} component={GSTR1A_Books} />
          <PrivateRoute path={`${BASE_PATH}gstr1a/books/detail`} component={GSTR1A_Books_detail} />
          <PrivateRoute path={`${BASE_PATH}gstr1a/books/save-data`} component={GSTR1A_Books_SaveDataOnPortal} />
          <PrivateRoute path={`${BASE_PATH}gstr1a/portal/summary`} component={GSTR1A_Portal} />
          <PrivateRoute path={`${BASE_PATH}gstr1a/portal/detail`} component={GSTR1A_Portal_detail} />
          <PrivateRoute path={`${BASE_PATH}gstr1a/portal/save-data`} component={GSTR1A_Portal_SaveDataOnPortal} />
          <PrivateRoute path={`${BASE_PATH}gstr1a/file-on-portal`} component={GSTR1A_FileOnPortal} />

          <PrivateRoute path={`${BASE_PATH}iff/books/summary`} component={IFF_Books} />
          <PrivateRoute path={`${BASE_PATH}iff/books/detail`} component={IFF_Books_detail} />
          <PrivateRoute path={`${BASE_PATH}iff/books/save-data`} component={IFF_Books_SaveDataOnPortal} />
          <PrivateRoute path={`${BASE_PATH}iff/portal/summary`} component={IFF_Portal} />
          <PrivateRoute path={`${BASE_PATH}iff/portal/detail`} component={IFF_Portal_detail} />
          <PrivateRoute path={`${BASE_PATH}iff/portal/save-data`} component={IFF_Portal_SaveDataOnPortal} />
          <PrivateRoute path={`${BASE_PATH}iff/file-on-portal`} component={IFF_FileOnPortal} />

          <PrivateRoute path={`${BASE_PATH}gstr1-reco/summary`} component={R1vsBooksRecoSummary} />
          <PrivateRoute path={`${BASE_PATH}gstr1-reco/detail/docs`} component={R1SectionRecoDOCS} />
          <PrivateRoute path={`${BASE_PATH}gstr1-reco/detail/summary`} component={R1SectionRecoSummary} />
          <PrivateRoute path={`${BASE_PATH}gstr1-reco/party/summary`} component={R1PartyWiseSummary} />

          <PrivateRoute path={`${BASE_PATH}supplier-reco/2b`} component={SupplierReco2B} />
          <PrivateRoute path={`${BASE_PATH}invoice-reco/2b`} component={InvoiceReco2B} />
          <PrivateRoute path={`${BASE_PATH}supplier-reco`} component={SuppilerReco} />
          <PrivateRoute path={`${BASE_PATH}invoice-reco`} component={InvoiceReco} />
          <PrivateRoute path={`${BASE_PATH}month-reco`} component={MonthlyWiseRecoChartTable} />
          <PrivateRoute path={`${BASE_PATH}supplier-email`} component={SuppilerEmail} />
          <PrivateRoute path={`${BASE_PATH}schedule-2a-2b-reco-email`} component={ScheduleEmail} />
          <PrivateRoute path={`${BASE_PATH}email-review`} component={EmailReview} />
          <PrivateRoute path={`${BASE_PATH}email-preview-tally`} component={EmailPreviewTally} />
          <PrivateRoute path={`${BASE_PATH}claim-itc`} component={GSTR2BITCClaim} />
          <PrivateRoute path={`${BASE_PATH}party-wise-claim-itc`} component={GSTR2BPartyWiseITCClaim} />

          <PrivateRoute path={`${BASE_PATH}gstr3b/offset-liability`} component={OffsetLiability} />
          <PrivateRoute path={`${BASE_PATH}gstr3b/inward-outward-supply`} component={InwardOutwardSupply} />
          <PrivateRoute path={`${BASE_PATH}gstr3b/review`} component={Review3B} />
          <PrivateRoute path={`${BASE_PATH}gstr3b/consolidated`} component={ConsolidatedGSTR3B} />
          <PrivateRoute path={`${BASE_PATH}gstr3b/eligible-itc`} component={EligibleITC} />
          <PrivateRoute path={`${BASE_PATH}gstr3b/file-3b`} component={Save3B} />

          <PrivateRoute path={`${BASE_PATH}gstr9/snapshots`} component={Snapshots} />
          <PrivateRoute path={`${BASE_PATH}gstr9/sales`} component={Sales} />
          <PrivateRoute path={`${BASE_PATH}gstr9/itc`} component={ITC} />
          <PrivateRoute path={`${BASE_PATH}gstr9/tax-paid`} component={TaxPaid} />
          <PrivateRoute path={`${BASE_PATH}gstr9/fy-details`} component={FyDetails} />
          <PrivateRoute path={`${BASE_PATH}gstr9/others`} component={Others} />
          <PrivateRoute path={`${BASE_PATH}gstr9/review`} component={Review} />
          <PrivateRoute path={`${BASE_PATH}gstr9/save`} component={Save9} />

          <PrivateRoute path={`${BASE_PATH}gstr9c/reco-turnover`} component={RecoTurnover} />
          <PrivateRoute path={`${BASE_PATH}gstr9c/reco-itc`} component={RecoITC} />
          <PrivateRoute path={`${BASE_PATH}gstr9c/reco-tax-paid`} component={RecoTaxPaid} />
          <PrivateRoute path={`${BASE_PATH}gstr9c/additional-liability`} component={AdditionalLiability} />
          <PrivateRoute path={`${BASE_PATH}gstr9c/review`} component={Gstr9cReview} />
          <PrivateRoute path={`${BASE_PATH}gstr9c/save`} component={Save9C} />
          <PrivateRoute path={`${BASE_PATH}gstr3b/itc-type`} component={EligibleITCType} />

          <PrivateRoute path={`${BASE_PATH}pan-level-reports`} component={PanLevelReports} />
          <PrivateRoute path={`${BASE_PATH}Return_summery`} component={Returnsummery} />
          <PrivateRoute path={`${BASE_PATH}BItemisedITC`} component={BItemisedITC} />
          <PrivateRoute path={`${BASE_PATH}Cashcreditelectronicstatement`} component={Cashcreditelectronicstatement} />
          <PrivateRoute path={`${BASE_PATH}ComparisonofGSTR13Bandbooks`} component={ComparisonofGSTR13Bandbooks} />
          <PrivateRoute path={`${BASE_PATH}ITCcomparision`} component={ITCcomparision} />
          <PrivateRoute path={`${BASE_PATH}Reconciliation`} component={Reconciliation} />

          <PrivateRoute path={`${BASE_PATH}3ca-3cb-3cd-annexures/list-all`} component={ListAllAnnexures} />
          <PrivateRoute path={`${BASE_PATH}3ca-3cb-3cd-annexures/list-branches`} component={GstInsBranchesList} />
          <PrivateRoute exact path={`${BASE_PATH}3ca-3cb-3cd-annexures/:type`} component={FormAnnexures} />

          <PrivateRoute exact path={`${BASE_PATH}e-invoice`} component={UserListing} />
          <PrivateRoute exact path={`${BASE_PATH}edit-user-invoice`} component={EditUser} />
          <PrivateRoute exact path={`${BASE_PATH}genrate-e-invoice`} component={GenrateEinvoice} />

          <AuthenticationRoute path={`${BASE_PATH}login`} component={LoginScreen} />
          <AuthenticationRoute path={`${BASE_PATH}signup`} component={SignUpScreen} />
          <AuthenticationRoute path={`${BASE_PATH}forgetpass`} component={ForgetPass} />
          <AuthenticationRoute path={`${BASE_PATH}resetpass`} component={ResetPass} />


          <Route path={`${BASE_PATH}maintenance`}>
            {!IS_MAINTENANCE_MODE_ENABLED ? <Redirect to={BASE_PATH} /> : <Maintenance />}
          </Route>


          <Route path={`${BASE_PATH}pub-auth/sync-2a-2b-mail`} component={Sync2A2BMail} />
          <Route path={`${BASE_PATH}pub-auth/tally/client-access`} component={PublicPageImportBookTally} />
          <Route path={`${BASE_PATH}reco-dashboard`} component={RecoDashboard} />
          <Route path={`${BASE_PATH}multi-linking`} component={multiLinking} />
          
          <PrivateRoute path={`${BASE_PATH}rate-reco/2b`} component={RateReco2B} />
          <PrivateRoute path={`${BASE_PATH}invoice-rate-reco/2b`} component={InvoiceRateReco2B} />
          <PrivateRoute path={`${BASE_PATH}rate-reco`} component={RateReco} />
          <PrivateRoute path={`${BASE_PATH}invoice-rate-reco`} component={InvoiceRateReco} />

          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </main>
  );
}

export default App;
