import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BASE_PATH } from '../../app/constants';
import plus from '../../images/bulk-import.png';
import infoIcon from '../../images/icons/Inst_icon.png'
import { Skeleton } from '@mui/material';
import "./CustomCell.scss";
import { formatAccountsNumber } from '../../utils/UtilityFunctions';

export class CustomCell extends React.Component {

  sortChange = () => {
    let new_state = this.props.dataState;
    let dir = 'asc';
    if (this.props.dataState && this.props.dataState.sort) {
      if (this.props.dataState.sort[0] && this.props.dataState.sort[0].field === this.props.field) {
        dir = this.props.dataState.sort[0].dir === 'asc' ? 'desc' : '';
      }
    }
    if (this.props.field && new_state) {
      new_state.sort = dir ? [{ field: this.props.field, dir: dir }] : false;
      this.props.dataStateChange(new_state);
    }

  }

  render() {
    return (
      <div onClick={() => {
        if (!this.props?.sortPos) {
          this.sortChange()
        }
      }}
        style={this.props.style ? this.props.style : {}} className={this.props.className ? "title_field " + this.props.className : "title_field"} >
        <span title={`${this.props?.tooltip ?? ""}`}>
          {this.props.titleIcon ? <FontAwesomeIcon icon={this.props.titleIcon} /> : null}
          <span className={`child_sortclick ${this?.props?.sortClss ?? ""}`} onClick={() => {
            if (this.props.sortPos) {
              this.sortChange()
            }
          }}>
            {this.props.title}
          </span>
          {this.props.icon ? <span>(<FontAwesomeIcon icon={this.props.icon} />) </span> : null}
          {this?.props?.addNewCompany ? this.props?.headerData?.plan_valid ?
            <span
              className="filteriocnsizeplus"
              style={{ position: 'relative' }}
              onClick={() => {
                this.props.history.push(`${BASE_PATH}gstin-login`);
              }}
            >
              <img src={plus} title="Add New Company" alt="" />
            </span>
            :
            <span
              className="filteriocnsizeplus planexpiredalert"
              style={{ position: 'relative' }}

            >
              <img src={plus} title="Add New Company" alt="" />
            </span> : null}
        </span>
        {this.props.dataState && this.props.field && this.props.dataState.sort && this.props.dataState.sort[0] && this.props.dataState.sort[0].field === this.props.field ?
          <span className={`k-icon k-i-sort-${this.props.dataState.sort[0].dir}-sm active_sort`}></span>
          : null}
      </div >
    );
  }
};

export class CustomInfoIcon extends React.Component {

  render() {
    return (
      <div style={this.props.style ? this.props.style : {}} className={this.props.className ? "title_field " + this.props.className : "title_field"} >
        <span>
          {this.props.titleIcon ? <input type="checkbox" style={{ verticalAlign: 'middle' }} onChange={(e) => e.target.checked ? this.props.checkSelect() : this.props.checkUnSelect()} /> : null}&nbsp;
          {this.props.title}&nbsp;
          {this?.props?.addInfo ?
            <>
              <span
                className="filteriocnsizeplus"
                style={{ position: 'relative' }}
              >
                <img src={infoIcon} title="" alt="Info Icon" width={16} onClick={this.props.infoPopup} />
              </span>
            </>
            :
            null}
        </span>
      </div >
    );
  }
}

export class CustomTitleCell extends React.Component {

  sortChange = () => {
    let new_state = this.props.dataState;
    let dir = 'asc';
    if (this.props.dataState && this.props.dataState.sort) {
      if (this.props.dataState.sort[0] && this.props.dataState.sort[0].field === this.props.field) {
        dir = this.props.dataState.sort[0].dir === 'asc' ? 'desc' : '';
      }
    }
    if (this.props.field && new_state) {
      new_state.sort = dir ? [{ field: this.props.field, dir: dir }] : false;
      this.props.dataStateChange(new_state);
    }

  }

  render() {
    return (<>
      <div onClick={(e) => e.stopPropagation()} style={this.props.style ? this.props.style : {}} className={this.props.className ? "title_field " + this.props.className : "title_field"} >

        <span className='cell-column-title' style={this.props.TitleStyle ? this.props.TitleStyle : {}} onClick={this.sortChange} title={this.props.addTooltip}>{this.props.title}</span>

        {this.props.dataState && this.props.field && this.props.dataState.sort && this.props.dataState.sort[0] && this.props.dataState.sort[0].field === this.props.field ?
          <span className={`k-icon k-i-sort-${this.props.dataState.sort[0].dir}-sm active_sort`}></span>
          : null}

      </div>
    </>
    );
  }
}

export class CustomCellTable extends React.Component {

  sortChange = () => {
    let new_state = this.props.dataState;
    let dir = 'asc';
    if (this.props.dataState && this.props.dataState.sort) {
      if (this.props.dataState.sort[0] && this.props.dataState.sort[0].field === this.props.field) {
        dir = this.props.dataState.sort[0].dir === 'asc' ? 'desc' : '';
      }
    }
    if (this.props.field && new_state) {
      new_state.sort = dir ? [{ field: this.props.field, dir: dir }] : false;
      this.props.dataStateChange(new_state);
    }

  }

  render() {
    return (
      <div
        style={this.props.style ? this.props.style : {}} className={this.props.className ? "title_field " + this.props.className : "title_field"} >
        <span title={`${this.props?.tooltip ?? ""}`}>
            {this.props.renderTitle(this.sortChange)}
        </span>
        {this.props.dataState && this.props.field && this.props.dataState.sort && this.props.dataState.sort[0] && this.props.dataState.sort[0].field === this.props.field ?
          <span className={`k-icon k-i-sort-${this.props.dataState.sort[0].dir}-sm active_sort`}></span>
          : null}
      </div >
    );
  }
}

export class CustomDataCell extends React.Component {

  sortChange = () => {
    let new_state = this.props.dataState;
    let dir = 'asc';
    if (this.props.dataState && this.props.dataState.sort) {
      if (this.props.dataState.sort[0] && this.props.dataState.sort[0].field === this.props.field) {
        dir = this.props.dataState.sort[0].dir === 'asc' ? 'desc' : '';
      }
    }
    if (this.props.field && new_state) {
      new_state.sort = dir ? [{ field: this.props.field, dir: dir }] : false;
      this.props.dataStateChange(new_state);
    }

  }

  render() {
    return (<>
      <div onClick={(e) => e.stopPropagation()} style={this.props.style ? this.props.style : {}} className={this.props.className ? "title_field " + this.props.className : "title_field"} >

        <span className='cell-column-title' style={this.props.TitleStyle ? this.props.TitleStyle : {}} onClick={this.sortChange} title={this.props.addTooltip}>{this.props.title}</span>
        {(this.props.showButton === undefined || this.props.showButton === true) &&
          <span title={this.props.addTooltip} onClick={this.props.handleClick}>
            <span className="create_deductor listofdticon"> &nbsp;<img title={this.props.addTooltipPlus} src={plus} alt="" /></span>
          </span>
        }

        {this.props.subtextData ?
          <div className={`${this.props.headClass ? this.props.headClass : 'text-cetner'}`}>
            {
              this.props.summaryColumnSkelton
                ? <Skeleton style={{ display: 'inline-block' }} animation="wave" variant="text" width={"80px"} height={"20px"} />
                : <span onClick={(e) => e.stopPropagation()} style={{cursor: 'default'}} title={
                  this.props?.subtextData?.subTextTitle
                    ? this.props?.subtextData?.subTextTitle
                    : (this.props.numberFormating ?? false) === true
                      ? this.props.title + ': ' + formatAccountsNumber(this.props.subtextData.subText)
                      : this.props.title + ': ' + this.props.subtextData.subText
                } className="b2btbtrthtextclr" >
                  {(this.props.numberFormating ?? false) === true ? formatAccountsNumber(this.props.subtextData.subText) : this.props.subtextData.subText}</span>
            }

          </div>
          : null}

        {this.props.dataState && this.props.field && this.props.dataState.sort && this.props.dataState.sort[0] && this.props.dataState.sort[0].field === this.props.field ?
          <span className={`k-icon k-i-sort-${this.props.dataState.sort[0].dir}-sm active_sort`}></span>
          : null}
          
      </div>
    </>
    );
  }
}