import React from 'react';
import { Skeleton } from '@mui/material';

const SkeltonCustomGrid = (props) => {
    const { column = 12, rowNo = 5 } = props;

    return (

        <tbody>
            {[...Array(rowNo)].map((_, rowIndex) => (
                <tr key={rowIndex}>
                    {[...Array(column)].map((_, colIndex) => (
                        <td
                            key={colIndex}
                            style={{
                                padding: '5px', // Adjusted padding
                                verticalAlign: 'top',
                                borderTop: '1px solid #dee2e6',
                                textAlign: 'center',
                            }}
                        >
                            <Skeleton animation="wave" variant="text" width="100%" height="40px" />
                        </td>
                    ))}
                </tr>
            ))}
        </tbody>
    );
};

export default SkeltonCustomGrid;
