/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { BASE_PATH } from '../app/constants';
import { isTokenValid } from '../utils/UtilityFunctions';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      isTokenValid()
        ? <Component {...props} />
        : <Redirect to={`${BASE_PATH}login`} />
    )}
  />
);

export default PrivateRoute;
