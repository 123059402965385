import * as React from 'react';
import {
    //GridColumnMenuSort,
    GridColumnMenuFilter
} from '@progress/kendo-react-grid';
import { CustomFilterUI } from './CustomFilterUI';
import { SourceTypeFilterUI } from './SourceTypeFilterUI';

export class ColumnMenu extends React.Component {
    
    render() {
        return (
            <div>
                {/*<GridColumnMenuSort {...this.props} expanded={true} />*/}
                <GridColumnMenuFilter  
                    {...this.props} 
                    expanded={true} 
                    hideSecondFilter={this.props.hideSecondFilter ? this.props.hideSecondFilter : false} 
                    filterUI={(props) => 
                        this.props.sourceTypeFilter 
                        ? <SourceTypeFilterUI filterList={this.props.filterList} column={this.props.column} {...props}/>
                        : <CustomFilterUI filterList={this.props.filterList} column={this.props.column} {...props} operatorLabel={this.props?.operatorLabel ?? null} />
                    } />
            </div>
        );
    }
}