import axios from 'axios';
import { BASE_URL, BASE_URL_GO } from '../app/constants';
import {
  handleSuccess, handleErrorWithCode
} from './responseHandler';
import { getConfig } from './common';

export async function getReturnFilingSummary(url_params) {
  const url = `${BASE_URL}/api/v1/returns/filing-summary?${url_params}`;
  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getGSTInfo(url_params) {
  const url = `${BASE_URL}/api/v1/report/gst-info?${url_params}`;
  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getMultiMonthGstr1Summary(company_id, return_financial_year) {
  const url_params = `company_id=${company_id}&return_financial_year=${parseInt(return_financial_year)}`;
  const url = `${BASE_URL}/api/v1/report/gstr1/multi-month-summary?${url_params}`;
  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getMultiMonthGstr2ASummary(company_id, return_financial_year) {
  const url_params = `company_id=${company_id}&return_financial_year=${parseInt(return_financial_year)}`;
  const url = `${BASE_URL_GO}/api/v1/report/gstr2a/multi-month-summary?${url_params}`;
  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }

}

export async function getLedgerSummaryData(url_params) {
  const url = `${BASE_URL}/api/v1/report/ledger-summary?${url_params}`;
  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getTaxLiabilityAndITCData(url_params) {
  const url = `${BASE_URL}/api/v1/gstportal/credit-vs-liability?${url_params}`;
  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getReturnFillingStatus(company_id) {
  const url = `${BASE_URL_GO}/api/v1/report/gst-return-filing-status?company_id=${company_id}`;

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}


export async function getMonthlyWiseRecoData(url_params) {
  const url = `${BASE_URL}/api/v1/2areco/month-wise?${url_params}`;
  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getLedgerBreakup(company_id) {
  const url = `${BASE_URL_GO}/api/v1/ledger/breakup?company_id=${company_id}`;

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getTcsTdsUnclaimed(company_id) {
  const url = `${BASE_URL_GO}/api/v1/gstr2x/details?company_id=${company_id}`;

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}


export async function getCashLedgerStatement(url_params) {
  const url = `${BASE_URL_GO}/api/v1/ledger/statement/cash?${url_params}`;
  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getCashLedgerFormPMT09Statement(url_params) {
  const url = `${BASE_URL_GO}/api/v1/ledger/statement/pmt09?${url_params}`;
  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}



export async function getCreditLedgerStatement(url_params) {
  const url = `${BASE_URL_GO}/api/v1/ledger/statement/credit?${url_params}`;
  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getNonReturnLiabilityLedgerStatement(url_params) {
  const url = `${BASE_URL}/api/v1/ledger/statement/non-return-liability?${url_params}`;
  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);

  } catch (err) {
    throw handleErrorWithCode(err);
  }
}


export async function getRcmLiabilityLedgerStatement(url_params) {
  const url = `${BASE_URL_GO}/api/v1/ledger/statement/rcm-liability?${url_params}`;
  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);

  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function exportLedgerAndDownload(params) {
  const url = `${BASE_URL_GO}/api/v1/ledger/export${params}`;

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw result.data.message;
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}


export async function getReturnRegisterData(data) {
  const url = `${BASE_URL_GO}/api/v1/return-register/client-wise`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}


export async function getMonthWiseReturnRegisterData(data) {
  const url = `${BASE_URL_GO}/api/v1/return-register/month-wise`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getRRDataReturnWise(data) {
  const url = `${BASE_URL}/api/v1/return-register/return-wise`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}



export async function exportDataGstr1(params) {
  const url = `${BASE_URL_GO}/api/v1/gstr1/monthly-export?${params}`;

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw result.data.message;
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function exportDataGstr1A(params) {
  const url = `${BASE_URL_GO}/api/v1/gstr1a/monthly-export?${params}`;

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw result.data.message;
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getElectCrReversalandReclaimLeStatement(url_params) {
  const url = `${BASE_URL_GO}/api/v1/ledger/statement/reversal?${url_params}`;
  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);

  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getNegativeLiabilityLedgerStatement(url_params) {
  const url = `${BASE_URL_GO}/api/v1/ledger/statement/negative-liability?${url_params}`;
  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);

  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function exportReturnRegister() {
  const url = `${BASE_URL_GO}/api/v1/return-register/return-wise/export`;

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw result.data.message;
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}