/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';

const nPattern = new RegExp(/^-?[0-9]*\.?[0-9]*$/);

const NumericInput = (props) => {
  const { value, onChange, onBlur = null, ...rest } = props;
  return (
    <input
      className={`form-control`}
      value={value}
      onChange={(e) => {
        if (nPattern.test(e.target.value)) {
          onChange(e);
        }
      }}
      {...rest}
      onBlur={onBlur}
    />
  );
};

export default NumericInput;
