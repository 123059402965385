import React, { useEffect, useState } from "react";
import './ReturnDashboard.css';
import moment from "moment";
import { sendTailyMail, tallyMappingSyncStatus, tallyUnMapCompany } from "../../api/booksAPI";
import { IsEmail, IsPhone } from "../../utils/UtilityFunctions";
import ConfirmPopup from "../../components/ConfirmPopup";


const ImportBookTallyClientPC = (props) => {
    const { companyId, initialMappedInfo, setPcOption, setInitialMappedInfo, setSuccess, setError } = props
    const [sendMailBox, setSendMailBox] = useState(false);
    const [mappedInfo, setMappedInfo] = useState({});
    const [clientName, setClientName] = useState("")
    const [clientMail, setClientMail] = useState("")
    const [clientMobile, setClientMobile] = useState("")
    const [unMappedCompany, setUnMappedCompany] = useState(false);
    const [confirmPopup, setConfirmPopup] = useState(false);
    const [confirmText, setConfirmText] = useState("");
    const [mailError, setMailError] = useState(null);
    const [mobileError, setMobileError] = useState(null);
    const [nameError, setNameError] = useState(null);

    const fetchLastStatus = (companyId) => {
        async function fetchStatus(companyId) {
            try {
                const result = await tallyMappingSyncStatus(companyId);
                if (result?.data) {
                    setMappedInfo(result?.data)
                    setInitialMappedInfo(result?.data)
                    setClientName(result?.data?.tally_client_name ?? "")
                    setClientMail(result?.data?.tally_client_email ?? "")
                    setClientMobile(result?.data?.tally_client_mobile_no ?? "")
                    setSendMailBox(false)
                    if (result?.data?.mapping_status === 0) {
                        setPcOption(true)
                    } else if (result?.data?.mapping_status === 1) {
                        setPcOption(false)
                    }
                }
            } catch (err) {
                setError(err.message)
            }
        }
        fetchStatus(companyId)

    }

    const sendMailToClient = () => {
        setMailError(null)
        setMobileError(null)
        setMailError(null)
        if (!IsEmail(clientMail) || !IsPhone(clientMobile) || clientName?.length > 75 || clientName?.length === 0 || clientMobile?.length === 0 || clientMail?.length === 0) {
            if (!IsEmail(clientMail)) {
                setMailError("Email is not Correct")
            }
            if (!IsPhone(clientMobile)) {
                setMobileError("Mobile no is not Correct")
            }
            if (clientName?.length > 75) {
                setNameError("Max 75 character allowed")
            }
            if (clientName?.length === 0) {
                setNameError("Name is Required")
            }
            if (clientMobile?.length === 0) {
                setMobileError("Mobile no is Required")
            }
            if (clientMail?.length === 0) {
                setMailError("Mail is Required")
            }
            return
        }

        async function sendMail() {
            const payload = {
                tally_client_name: clientName,
                tally_client_email: clientMail,
                tally_client_mobile_no: clientMobile,
                company_id: companyId
            }
            try {
                const result = await sendTailyMail(payload)
                if (result?.data) {
                    setSuccess("E-Mail has been sent successfully");
                    fetchLastStatus(companyId)
                }
            } catch (err) {
                setError(err.message);
            }
        }
        sendMail()
    }
    const unMappingTallyFromExpressGst = () => {
        async function unMapData() {
            const payload = {
                tally_company_id: initialMappedInfo?.tally_company_id,
                company_id: companyId
            }
            try {
                const result = await tallyUnMapCompany(payload)
                if (result?.data) {
                    setSuccess("Unmapped succesfully!!");
                    setUnMappedCompany(false);
                    fetchLastStatus(companyId)
                }
            } catch (err) {
                setError(err.message);
            }
        }
        unMapData()
    };

    useEffect(() => {
        setMappedInfo(initialMappedInfo)
        setClientName(initialMappedInfo?.tally_client_name ?? "")
        setClientMail(initialMappedInfo?.tally_client_email ?? "")
        setClientMobile(initialMappedInfo?.tally_client_mobile_no ?? "")
        if (!initialMappedInfo?.tally_client_last_email_sent_at && initialMappedInfo?.mapping_status === 0) {
            setSendMailBox(true)
        }
    }, [initialMappedInfo])

    return (
        <>

            {confirmPopup &&
                <ConfirmPopup
                    show={confirmPopup}
                    handleClose={() => setConfirmPopup(false)}
                    handleProceed={() => {
                        if (unMappedCompany) {
                            unMappingTallyFromExpressGst()
                        }
                        setConfirmPopup(false);
                    }}
                    confirmText={confirmText}
                    confirmButtonText={"Proceed"}
                    textClass="tally_text"
                />
            }


            {(mappedInfo && mappedInfo?.mapping_status === 1 && mappedInfo?.last_mapped_by_type === "CLIENT" && !sendMailBox) ? <>
                <div className="pt-1 pb-2 modal-body">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tab-content" id="nav-tabContent">
                                <div className="tab-pane fade active show" id="impbooksdata_tabbox_syncnow_01" role="tabpanel" aria-labelledby="nav-home-tab">
                                    <div className="row pt-2">
                                        <div className="col-lg-12">
                                            <div className="card tallyconfg_card">
                                                <p class="tally_autosync_lastsyn_textfs mb-1">
                                                    Last Synced On :<b> <span class="pr-1">{mappedInfo?.last_sync_at ? moment((mappedInfo?.last_sync_at).slice(0, 10)).format('DD/MM/YYYY') : ""}</span></b>
                                                </p>
                                                <p className="tally_config_textfs mb-1">
                                                    The Tally data is set to sync from <b>{mappedInfo?.tally_client_name} , {mappedInfo?.tally_client_email}, {mappedInfo?.tally_client_mobile_no}, {mappedInfo?.tally_name}</b>  from  Client PC &  {mappedInfo?.tally_port}  Manually,
                                                    if you wish to sync updated data Click on ask client for updated data.
                                                </p>


                                                <p className="tally_config_textfs">
                                                    If wish to map any other tally company click on unmap and Choose the appropriate
                                                    option from  Configure with Tally.
                                                </p>

                                                <p className="text-center">
                                                    <button className="btn btn-default askclientforupdate_btn mr-2" onClick={() => setSendMailBox(true)}>
                                                        Ask Client for Updated Data
                                                    </button>
                                                    <button className="btn btn-default askclientforupdate_btn" onClick={() => {
                                                        setUnMappedCompany(true);
                                                        setConfirmPopup(true);
                                                        setConfirmText('If you are disconnecting this Tally Profile, the further sync will stop happening, however the data already synced would not be impacted. If you want to delete the relevant data also, you can do so by using delete options for Sales / Purchase individually, provided in individual Client / GSTIN.');
                                                    }



                                                    }>
                                                        Unmap
                                                    </button>
                                                </p>


                                            </div>

                                        </div>

                                        <div className="col-md-12 pt-1">
                                            <div className="card tallyconfg_card">
                                                <p className="tally_config_Ginfofs mb-0">
                                                    General Instructions:
                                                </p>
                                                <p className="tally_config_textfs mb-1">
                                                    1.  Choose appropriate option from Configure with Tally and
                                                    ExpressGST will scan your Entire network and Tally instances running thereof.

                                                </p>
                                                <p className="tally_config_textfs ">
                                                    2. Please ensure the below points in the entire network-
                                                </p>
                                                <ul className="importbooksdt_tally_listbox py-0 mb-0">
                                                    <li>
                                                        <span className="pr-0 tally_listbox_checkicon_clrblue">
                                                            <i className="fa fa-check" aria-hidden="true"></i>
                                                        </span>
                                                        Respective Networked / LAN PC is switched on
                                                    </li>
                                                    <li>
                                                        <span className="pr-0 tally_listbox_checkicon_clrblue">
                                                            <i className="fa fa-check" aria-hidden="true"></i>
                                                        </span>
                                                        Connector is installed on  the PC,
                                                    </li>
                                                    <li>
                                                        <span className="pr-0 tally_listbox_checkicon_clrblue">
                                                            <i className="fa fa-check" aria-hidden="true"></i>
                                                        </span>
                                                        Tally Instance is up and running on PC
                                                    </li>
                                                    <li>
                                                        <span className="pr-0 tally_listbox_checkicon_clrblue">
                                                            <i className="fa fa-check" aria-hidden="true"></i>
                                                        </span>
                                                        Tally Company is open from which you want to sync the data.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

            </> : null
            }



            {
                (mappedInfo && mappedInfo?.mapping_status === 0 && mappedInfo?.tally_client_last_email_sent_at && !sendMailBox) ? <>
                    <div className="row py-2">
                        <div className="col-md-12">
                            <div className="card tallyconfg_card">
                                <p className="tally_config_Ginfofs mb-0">
                                    General Instructions:
                                </p>
                                <p className="tally_config_textfs mb-1">
                                    1.  Choose appropriate option from Configure with Tally and ExpressGST will scan your Entire network and Tally instances running thereof.

                                </p>
                                <p className="tally_config_textfs mb-0">
                                    2. Please ensure the below points you want to scan from:
                                </p>
                                <ul className="importbooksdt_tally_listbox py-0 mb-0">
                                    <li>
                                        <span className="pr-0 tally_listbox_checkicon_clrblue">
                                            <i className="fa fa-check" aria-hidden="true"></i>
                                        </span>
                                        Respective Networked / LAN PC is switched on
                                    </li>
                                    <li>
                                        <span className="pr-0 tally_listbox_checkicon_clrblue">
                                            <i className="fa fa-check" aria-hidden="true"></i>
                                        </span>
                                        Connector is installed on the PC,
                                    </li>
                                    <li>
                                        <span className="pr-0 tally_listbox_checkicon_clrblue">
                                            <i className="fa fa-check" aria-hidden="true"></i>
                                        </span>
                                        Tally Instance is up and running on PC
                                    </li>
                                    <li>
                                        <span className="pr-0 tally_listbox_checkicon_clrblue">
                                            <i className="fa fa-check" aria-hidden="true"></i>
                                        </span>
                                        Tally Company is open from which you want to sync the data.
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-md-12 pt-2">
                            <div className="card tallyconfg_card">
                                <p className="tally_config_textfs mb-1">
                                    You have sent the Tally syncing request via email  to <b>{mappedInfo?.tally_client_name} , {mappedInfo?.tally_client_email}, {mappedInfo?.tally_client_mobile_no} </b>. Data syncing from the client's
                                    PC will commence once they click on take action in the email sent. Do you want to Re-send Email?
                                </p>

                                <div className="text-center">
                                    <button className="btn btn-default resend_mailtextfs zps_tally_resendmail" onClick={() => setSendMailBox(true)}>
                                        Resend Mail
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </> : null
            }



            {
                sendMailBox &&
                <div className="col-md-12 pt-2 pb-2">
                    <div className="card px-2 py-2">
                        <form className="form-row tallysendreq_form">
                            <div className="form-group col-sm-12">
                                <input type="text" className="form-control tallyinput_fs" placeholder="Enter Client Name" value={clientName} maxLength={75} onChange={(e) => setClientName(e.target.value)} />
                                {nameError && <span style={{ color: "red" }}>{nameError}</span>}
                            </div>
                            <div className="form-group col-sm-12">
                                <input type="email" className="form-control tallyinput_fs" placeholder="Enter Client Email Address" maxLength={75} value={clientMail} onChange={(e) => setClientMail(e.target.value)} />
                                {mailError && <span style={{ color: "red" }}>{mailError}</span>}
                            </div>
                            <div className="form-group col-sm-12">
                                <input type="text" className="form-control tallyinput_fs" placeholder="Enter Client Mobile No." maxLength={10} value={clientMobile} onChange={(e) => setClientMobile(e.target.value)} />
                                {mobileError && <span style={{ color: "red" }}>{mobileError}</span>}
                            </div>
                        </form>
                        <div className="row pt-2 pb-2">
                            <div className="col-md-12 text-center">
                                <button className="btn btn-default tally_mapbtnbgclr" onClick={() => sendMailToClient()}>Send Request to Client</button>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </>

    )
};

export default ImportBookTallyClientPC;