/* eslint-disable no-useless-catch */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { BASE_URL, BASE_URL_GO } from '../app/constants';
import { handleSuccess, handleErrorWithCode } from './responseHandler';
import { getConfig } from './common';
import { getAuthToken } from '../utils/AuthToken';

export async function getInvoiceB2BList(data, abortController) {
  const url = `${BASE_URL}/api/v2/gst2a/reco/invoice-wise/b2b`;

  try {
    const configData = getConfig();
    if (abortController !== undefined) {
      configData.signal = abortController.signal;
    }

    const result = await axios.post(url, data, configData);
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getInvoiceIMPList(data, abortController) {
  const url = `${BASE_URL}/api/v2/gst2a/reco/invoice-wise/imp`;

  try {
    const configData = getConfig();
    if (abortController !== undefined) {
      configData.signal = abortController.signal;
    }

    const result = await axios.post(url, data, configData);
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }

}

// export async function downloadBookTemplate() {
//   const url = `${BASE_URL}/api/v1/gst2a/excel-template`;
//   try {
//     axios({
//       url: url,
//       method: 'GET',
//       responseType: 'blob', // important
//     }).then((response) => {
//       const url = window.URL.createObjectURL(new Blob([response.data]));
//       const link = document.createElement('a');
//       link.href = url;
//       link.setAttribute('download', 'GSTR2_Excel_Workbook_Template.xlsx');
//       document.body.appendChild(link);
//       link.click();
//     });
//   } catch (err) {
//     throw handleErrorWithCode(err);
//   }
// }


export async function fetchSuggestedInvoicesForBooks(type, data) {
  const url = `${BASE_URL}/api/v1/2areco/${type}/books/suggest-match`;

  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }

}

export async function fetchSuggestedInvoicesFor2A(type, data) {
  const url = `${BASE_URL}/api/v1/2areco/${type}/2a/suggest-match`;

  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }

}

export async function unlink2ARecoInvoice(type, data) {
  const url = `${BASE_URL}/api/v1/2areco/${type}/unlink`;

  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }

}

export async function unlink2ARecoInvoiceV2(type, data) {
  const url = `${BASE_URL_GO}/api/v1/gst2a2b/reco/${type}/unlink`;

  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }

}

export async function delUploadedBooksData(data) {
  const url = `${BASE_URL_GO}/api/v1/gst2a/imported-books`;

  try {
    const result = await axios.delete(url, {
      data: data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAuthToken(true)}`,
      }
    },);
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }

}


export async function link2ARecoInvoice(type, data) {
  const url = `${BASE_URL}/api/v1/2areco/${type}/link`;

  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }

}

export async function updateMatchRemarks(data, ref_type) {
  const url = `${BASE_URL}/api/v1/2areco/${ref_type}/match-remarks`;

  try {
    const result = await axios.put(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function updateMatchRemarksV2(data) {
  const url = `${BASE_URL_GO}/api/v1/gst2a2b/reco/match-remarks`;

  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getInvoiceB2BList2B(data, abortController) {
  const url = `${BASE_URL}/api/v1/2breco/invoice-wise/b2b`;

  try {
    const configData = getConfig();

    if (abortController !== undefined) {
      configData.signal = abortController.signal;
    }
    const result = await axios.post(url, data, configData);
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getInvoiceIMPList2B(data, abortController) {
  const url = `${BASE_URL}/api/v1/2breco/invoice-wise/imp`;

  try {
    const configData = getConfig();
    if (abortController !== undefined) {
      configData.signal = abortController.signal;
    }
    const result = await axios.post(url, data, configData);
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }

}

export async function getRcm2A2BConflictList(data) {

  const url = `${BASE_URL_GO}/api/v1/gst2a2b/reco/rcm-conflict`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }

}

export async function getRcm2AConflictList(data) {

  const url = `${BASE_URL}/api/v1/2areco/rcm-conflict`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }

}

export async function getRcm2BConflictList(data) {

  const url = `${BASE_URL}/api/v1/2breco/rcm-conflict`;

  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }

}

export async function getItcConflictList(data) {

  const url = `${BASE_URL_GO}/api/v1/gst2a2b/reco/itc-eligibility-conflict`;

  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }

}

export async function getIn2ANotIn2BConflictList(data) {

  const url = `${BASE_URL_GO}/api/v1/gst2a2b/reco/in-2a-not-in-2b`;

  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }

}

export async function getPos2A2BConflictList(data) {

  const url = `${BASE_URL_GO}/api/v1/gst2a2b/reco/pos-conflict`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }

}

export async function getPos2AConflictList(data) {

  const url = `${BASE_URL}/api/v1/2areco/pos-conflict`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }

}

export async function getPos2BConflictList(data) {

  const url = `${BASE_URL}/api/v1/2breco/pos-conflict`;

  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }

}

export async function getInvoiceRecoB2BList2B(data, abortController) {
  const url = `${BASE_URL_GO}/api/v1/gst2a2b/reco/invoice-wise/b2b`;

  try {
    const configData = getConfig();

    if (abortController !== undefined) {
      configData.signal = abortController.signal;
    }
    const result = await axios.post(url, data, configData);
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getInvoiceRecoB2BListSummary2B(data, abortController) {
  const url = `${BASE_URL_GO}/api/v1/gst2a2b/reco/invoice-wise/b2b/summary`;

  try {
    const configData = getConfig();

    if (abortController !== undefined) {
      configData.signal = abortController.signal;
    }
    const result = await axios.post(url, data, configData);
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getInvoiceRecoB2BListImpg(data, abortController) {
  const url = `${BASE_URL_GO}/api/v1/gst2a2b/reco/invoice-wise/impg`;

  try {
    const configData = getConfig();

    if (abortController !== undefined) {
      configData.signal = abortController.signal;
    }
    const result = await axios.post(url, data, configData);
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getInvoiceRecoB2BListSummaryImpg(data, abortController) {
  const url = `${BASE_URL_GO}/api/v1/gst2a2b/reco/invoice-wise/impg/summary`;

  try {
    const configData = getConfig();

    if (abortController !== undefined) {
      configData.signal = abortController.signal;
    }
    const result = await axios.post(url, data, configData);
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function link2ARecoInvoiceV2(type, data) {
  const url = `${BASE_URL_GO}/api/v1/gst2a2b/reco/${type}/link`;

  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getItcClaimStatus(data) {
  const url = `${BASE_URL_GO}/api/v1/claim-itc/itc-claim-status`;

  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}