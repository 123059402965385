/* eslint-disable array-callback-return */
import { createSlice } from '@reduxjs/toolkit';

export const taskQueueSlice = createSlice({
  name: 'taskQueue',
  initialState: [],
  reducers: {
    updateTaskQueue: (state, action) => {
      return [ ...Object.values(action.payload) ];
    }
  },
});

export const { updateTaskQueue } = taskQueueSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectTaskData = state => state.taskQueue;

export default taskQueueSlice.reducer;
