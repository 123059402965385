import React, { useState, useEffect } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { CustomCell } from '../../../components/grid/Cell';
import { ColumnMenu } from '../../../components/grid/ColumnMenu';
import { process } from "@progress/kendo-data-query";
import { deepClone } from '../../../utils/UtilityFunctions';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { formatAccountsNumber } from '../../../utils/UtilityFunctions';
import { getPeriodString } from '../../../utils/UtilityFunctions';
import '../../2AReco/V2/invoiceReco/InvoiceReco.css';
import moment from 'moment';

const DEFAULT_SUMMARY = {
    doc_no: 0,
    doc_type: 0,
    doc_amt: 0,
    taxable_amt: 0,
    cgst_amt: 0,
    sgst_amt: 0,
    igst_amt: 0,
    cess_amt: 0,
}

export default function MultiInvoiceDetailPopupBooks(props) {

    const { books, matchStatusColor, matchStatus, tabel_col } = props;

    const [data, setData] = useState([]);
    const [dataState, setDataState] = useState({});
    const [initialData, setInitialData] = useState([]);
    const [summary, setSummary] = useState(deepClone(DEFAULT_SUMMARY));

    const resetSummary = (data) => {

        const invoiceType = new Set();
        const summaryObj = deepClone(DEFAULT_SUMMARY);
        data?.forEach((item) => {
            if (item.doc_no) {
                summaryObj['doc_no'] += 1;
            }
            if (item.doc_type && !invoiceType.has(item.doc_type)) {
                invoiceType.add(item.doc_type);
            }

            const taxableAmt = Number(item?.taxable_amt ?? 0);
            const taxAmt = Number(item?.cgst_amt ?? 0);
            const DocAmt = Number(item?.document_amt ?? 0);
            const sgst = Number(item?.sgst_amt ?? 0);
            const igst = Number(item?.igst_amt ?? 0);
            const cess = Number(item?.cess_amt ?? 0);

            if (item.doc_type === "C" || item.doc_type === "CN") {
                summaryObj['taxable_amt'] -= taxableAmt;
                summaryObj['cgst_amt'] -= taxAmt;
                summaryObj['doc_amt'] -= DocAmt;
                summaryObj['sgst_amt'] -= sgst;
                summaryObj['igst_amt'] -= igst
                summaryObj['cess_amt'] -= cess;
            } else {
                summaryObj['taxable_amt'] += taxableAmt;
                summaryObj['cgst_amt'] += taxAmt;
                summaryObj['doc_amt'] += DocAmt;
                summaryObj['sgst_amt'] += sgst;
                summaryObj['igst_amt'] += igst;
                summaryObj['cess_amt'] += cess;
            }
        });

        summaryObj['doc_type'] = invoiceType.size;
        setSummary(summaryObj);
    }

    const convert = (data) => {
        return data?.map(item => ({
            ...item,
            doc_date_filter: moment(item.boe_date ?? item.doc_date, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ssZ'),
        }));
    }

    useEffect(() => {
        const convertedData = convert(books);
        setData(convertedData);
        setInitialData(convertedData);
        resetSummary(books);
    }, [books]);

    const dataStateChange = (dataState) => {
        const filterState = deepClone(dataState);

        if (filterState?.filter?.filters?.length > 0) {
            filterState.filter.filters.forEach((item) => {
                if (item?.filters?.length > 0) {
                    item.filters.forEach((itemObj) => {
                        if (["gte", "lte", 'lt', 'gt', "neq", "eq"].includes(itemObj?.operator)) {
                            if (itemObj.field !== "doc_date_filter") {
                                itemObj.value = Number(itemObj.value);
                            } else {
                                //itemObj.value = Number(itemObj.value);
                                const date = moment(itemObj.value).format('DD/MM/YYYY');
                                itemObj.value = moment(date, 'DD/MM/YYYY').format();
                            }
                        }
                        else if (["eq"].includes(itemObj?.operator) && Array.isArray(itemObj.value)) {
                            if (!["doc_date_filter"].includes(itemObj.field)) {
                                itemObj.value = itemObj.value?.[0] ?? "";
                            }
                        } else if (["in"].includes(itemObj?.operator) && Array.isArray(itemObj.value)) {
                            item.logic = "or"
                            item.filters = itemObj?.value?.map(keyValue => ({
                                field: itemObj.field,
                                operator: 'eq',
                                value: keyValue
                            }));
                        }
                    });
                }
            });
        }

        const dataProcess = process(initialData, filterState);
        setDataState(dataState);
        setData(dataProcess.data ?? []);
        resetSummary(dataProcess.data ?? []);
    }

    const isColumnActive = (field) => {
        return dataState.filter?.filters.some(filter => filter.filters[0].field === field) ?? false;
    };

    const renderPeriodText = (type, data) => {
        if (!data || data.length === 0) return '-';
        const month = data?.books_month;
        const year = data?.books_year;
        return month && year ? getPeriodString(month, year) : '-';
    }


    const renderSubTextTitle = (title, subText, useNumberFormat = false) => {
        const titleText = `${title + ': ' + subText}`;
        return (
            <div>
                {title} {' '}
                {subText !== '' && (
                    <div className="text-center" onClick={(e) => e.stopPropagation()} style={{ cursor: 'default' }}>
                        <span title={titleText} className="b2btbtrthtextclr">
                            {!useNumberFormat ? subText : formatAccountsNumber(subText, false, true, titleText)}
                        </span>
                    </div>
                )}
            </div>
        );
    }


    if (tabel_col === 'imp') {
        return (
            <div className="row">
                <div className="col-md-12 mt-0 pt-0">
                    <div className="card suppborbox">
                        <div className="table-responsive">
                            <Tooltip anchorElement="pointer" position="top">
                                <Grid
                                    data={data}
                                    className="table-striped supplierraisereqtb ratewiesreco remove_sortclass table-responsive d-flex"
                                    {...dataState}
                                    onDataStateChange={(event) => dataStateChange(event.dataState)}
                                    filterOperators={{
                                        text: [{ text: 'grid.filterContainsOperator', operator: 'contains' }],
                                        numeric: [
                                            { text: 'grid.filterGteOperator', operator: 'gte' },
                                            { text: 'grid.filterLteOperator', operator: 'lte' },
                                            { text: 'grid.filterLtOperator', operator: 'lt' },
                                            { text: 'grid.filterGtOperator', operator: 'gt' },
                                            { text: 'grid.filterEqOperator', operator: 'eq' },
                                            { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                        ],
                                        textWithEmpty: [
                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                            { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                        ],
                                        date: [
                                            { text: 'grid.filterGteOperator', operator: 'gte' },
                                            { text: 'grid.filterLteOperator', operator: 'lte' },
                                            { text: 'grid.filterLtOperator', operator: 'lt' },
                                            { text: 'grid.filterGtOperator', operator: 'gt' },
                                            { text: 'grid.filterEqOperator', operator: 'eq' },
                                        ],
                                    }}
                                    style={{ maxHeight: '360px', overflowX: 'auto', overflowY: 'hidden' }}
                                    sortable
                                >
                                    <Column title={<CustomCell title="Books" className="inline_text_center" style={{ fontSize: '1rem', paddingLeft: '190px' }} />} style={{ width: '100%' }}>

                                        <Column
                                            width="90"
                                            field="doc_date_filter"
                                            headerClassName={isColumnActive('doc_date_filter') ? 'active' : ''}
                                            title={<CustomCell title={tabel_col === "imp" ? "Boe Dt" : "Doc Dt"}
                                                field="doc_date_filter" dataStateChange={dataStateChange} dataState={dataState} />}
                                            cell={(event) => (
                                                <td className="text-center">
                                                    {tabel_col === "imp"
                                                        ? event.dataItem.boe_date
                                                        : (event.dataItem.doc_date ?? "-")}
                                                </td>
                                            )}
                                            columnMenu={(props) => <ColumnMenu {...props} />}
                                            filter="date"
                                        />
                                        <Column
                                            width="100"
                                            field="doc_no"
                                            headerClassName={isColumnActive('doc_no') ? 'active' : ''}
                                            title={
                                                <CustomCell
                                                    title={renderSubTextTitle(
                                                        tabel_col === "imp" ? "BoE No" : "Doc No",
                                                        books.length
                                                    )}
                                                    field="doc_no"
                                                    dataStateChange={dataStateChange}
                                                    dataState={dataState}
                                                />
                                            }
                                            cell={(event) => (
                                                <td className="text-center" title={event.dataItem.doc_no}>
                                                    {tabel_col === "imp"
                                                        ? event.dataItem.boe_no
                                                        : (event.dataItem.doc_no ?? "-")}
                                                </td>
                                            )}
                                            columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                            filter="text"
                                        />
                                        <Column
                                            width="100"
                                            field="doc_type"
                                            headerClassName={isColumnActive('doc_type') ? 'active' : ''}
                                            title={<CustomCell title={renderSubTextTitle('Doc Type', summary?.doc_type)} field="doc_type" dataStateChange={dataStateChange} dataState={dataState} />}
                                            cell={(event) => (
                                                <td className="text-center">
                                                    {event.dataItem?.doc_type === "C"
                                                        ? 'CN'
                                                        : event.dataItem?.doc_type === "D"
                                                            ? 'DN'
                                                            : event?.dataItem?.doc_type || "-"}
                                                </td>
                                            )}
                                            columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                            filter="text"
                                        />
                                        <Column
                                            width="100"
                                            field="doc_amt"
                                            headerClassName={isColumnActive('doc_amt') ? 'active' : ''}
                                            title={<CustomCell title={renderSubTextTitle('Doc Amt', summary.doc_amt, true)} field="doc_amt" dataStateChange={dataStateChange} dataState={dataState} />}
                                            cell={(event) => (
                                                <td className="text-right" title={formatAccountsNumber(event.dataItem.document_amt)}>
                                                    {formatAccountsNumber(event.dataItem.document_amt ?? 0)}
                                                </td>
                                            )}
                                            columnMenu={(props) => <ColumnMenu {...props} />}
                                            filter="numeric"
                                        />
                                        <Column
                                            width="100"
                                            field="taxable_amt"
                                            headerClassName={isColumnActive('taxable_amt') ? 'active' : ''}
                                            title={<CustomCell title={renderSubTextTitle('Taxable Val', summary.taxable_amt, true)} field="taxable_amt" dataStateChange={dataStateChange} dataState={dataState} />}
                                            cell={(event) => (
                                                <td className="text-right" title={formatAccountsNumber(event.dataItem.taxable_amt)}>
                                                    {formatAccountsNumber(event.dataItem.taxable_amt ?? 0)}
                                                </td>
                                            )}
                                            columnMenu={(props) => <ColumnMenu {...props} />}
                                            filter="numeric"
                                        />
                                        
                                        <Column
                                            width="100"
                                            field="igst_amt"
                                            headerClassName={isColumnActive('igst_amt') ? 'active' : ''}
                                            title={<CustomCell title={renderSubTextTitle('IGST', summary.igst_amt, true)} field="igst_amt" dataStateChange={dataStateChange} dataState={dataState} />}
                                            cell={(event) => (
                                                <td className="text-right" title={formatAccountsNumber(event.dataItem.igst_amt)}>
                                                    {formatAccountsNumber(event.dataItem.igst_amt ?? 0)}
                                                </td>
                                            )}
                                            columnMenu={(props) => <ColumnMenu {...props} />}
                                            filter="numeric"
                                        />
                                        <Column
                                            width="100"
                                            field="cess_amt"
                                            headerClassName={isColumnActive('cess_amt') ? 'active' : ''}
                                            title={<CustomCell title={renderSubTextTitle('Cess', summary.cess_amt, true)} field="cess_amt" dataStateChange={dataStateChange} dataState={dataState} />}
                                            cell={(event) => (
                                                <td className="text-right" title={formatAccountsNumber(event.dataItem.cess_amt)}>
                                                    {formatAccountsNumber(event.dataItem.cess_amt ?? 0)}
                                                </td>
                                            )}
                                            columnMenu={(props) => <ColumnMenu {...props} />}
                                            filter="numeric"
                                        />
                                        <Column
                                            width="80"
                                            field="rcm"
                                            headerClassName={isColumnActive('rcm') ? 'active' : ''}
                                            title={<CustomCell title="RCM" field="rcm" dataStateChange={dataStateChange} dataState={dataState} />}
                                            cell={(event) => (
                                                <td className="text-center">
                                                    {event.dataItem.rcm ?? '-'}
                                                </td>
                                            )}
                                            columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                            filter="text"
                                        />

                                        <Column
                                            width="80"
                                            field="itc_eligibility"
                                            headerClassName={isColumnActive('itc_eligibility') ? 'active' : ''}
                                            title={<CustomCell title="ITC Avail." field="itc_eligibility" dataStateChange={dataStateChange} dataState={dataState} />}
                                            cell={(event) => (
                                                <td className="text-center" title={event.dataItem.itc_eligibility}>
                                                    {event.dataItem.itc_eligibility ?? '-'}
                                                </td>
                                            )}
                                            columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                            filter="text"
                                        />
                                    </Column>
                                    <Column title={<CustomCell
                                        title={matchStatus}
                                        className="inline_text_center"
                                        style={{ fontSize: '0.9rem', color: matchStatusColor }}
                                    />}
                                        style={{ width: '100%' }}
                                    >

                                        <Column
                                            width="100"
                                            title="Books Prd"
                                            sortable={false}
                                            headerClassName="cursor-default"
                                            cell={(event) => (
                                                <td className="text-center">
                                                    {renderPeriodText('books', event.dataItem ?? "-")}
                                                </td>
                                            )}
                                        />
                                    </Column>
                                </Grid>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <div className="row">
            <div className="col-md-12 mt-0 pt-0">
                <div className="card suppborbox">
                    <div className="table-responsive">
                        <Tooltip anchorElement="pointer" position="top">
                            <Grid
                                data={data}
                                className="table-striped supplierraisereqtb ratewiesreco remove_sortclass table-responsive d-flex"
                                {...dataState}
                                onDataStateChange={(event) => dataStateChange(event.dataState)}
                                filterOperators={{
                                    text: [{ text: 'grid.filterContainsOperator', operator: 'contains' }],
                                    numeric: [
                                        { text: 'grid.filterGteOperator', operator: 'gte' },
                                        { text: 'grid.filterLteOperator', operator: 'lte' },
                                        { text: 'grid.filterLtOperator', operator: 'lt' },
                                        { text: 'grid.filterGtOperator', operator: 'gt' },
                                        { text: 'grid.filterEqOperator', operator: 'eq' },
                                        { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                    ],
                                    textWithEmpty: [
                                        { text: 'grid.filterContainsOperator', operator: 'contains' },
                                        { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                    ],
                                    date: [
                                        { text: 'grid.filterGteOperator', operator: 'gte' },
                                        { text: 'grid.filterLteOperator', operator: 'lte' },
                                        { text: 'grid.filterLtOperator', operator: 'lt' },
                                        { text: 'grid.filterGtOperator', operator: 'gt' },
                                        { text: 'grid.filterEqOperator', operator: 'eq' },
                                    ],
                                }}
                                style={{ maxHeight: '360px', overflowX: 'auto', overflowY: 'hidden' }}
                                sortable
                            >
                                <Column title={<CustomCell title="Books" className="inline_text_center" style={{ fontSize: '1rem', paddingLeft: '190px' }} />} style={{ width: '100%' }}>

                                    <Column
                                        width="90"
                                        field="doc_date_filter"
                                        headerClassName={isColumnActive('doc_date_filter') ? 'active' : ''}
                                        title={<CustomCell title={tabel_col === "imp" ? "Boe Dt" : "Doc Dt"}
                                            field="doc_date_filter" dataStateChange={dataStateChange} dataState={dataState} />}
                                        cell={(event) => (
                                            <td className="text-center">
                                                {tabel_col === "imp"
                                                    ? event.dataItem.boe_date
                                                    : (event.dataItem.doc_date ?? "-")}
                                            </td>
                                        )}
                                        columnMenu={(props) => <ColumnMenu {...props} />}
                                        filter="date"
                                    />
                                    <Column
                                        width="100"
                                        field="doc_no"
                                        headerClassName={isColumnActive('doc_no') ? 'active' : ''}
                                        title={
                                            <CustomCell
                                                title={renderSubTextTitle(
                                                    tabel_col === "imp" ? "BoE No" : "Doc No",
                                                    books.length
                                                )}
                                                field="doc_no"
                                                dataStateChange={dataStateChange}
                                                dataState={dataState}
                                            />
                                        }
                                        cell={(event) => (
                                            <td className="text-center" title={event.dataItem.doc_no}>
                                                {tabel_col === "imp"
                                                    ? event.dataItem.boe_no
                                                    : (event.dataItem.doc_no ?? "-")}
                                            </td>
                                        )}
                                        columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                        filter="text"
                                    />
                                    <Column
                                        width="100"
                                        field="doc_type"
                                        headerClassName={isColumnActive('doc_type') ? 'active' : ''}
                                        title={<CustomCell title={renderSubTextTitle('Doc Type', summary?.doc_type)} field="doc_type" dataStateChange={dataStateChange} dataState={dataState} />}
                                        cell={(event) => (
                                            <td className="text-center">
                                                {event.dataItem?.doc_type === "C"
                                                    ? 'CN'
                                                    : event.dataItem?.doc_type === "D"
                                                        ? 'DN'
                                                        : event?.dataItem?.doc_type || "-"}
                                            </td>
                                        )}
                                        columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                        filter="text"
                                    />
                                    <Column
                                        width="100"
                                        field="doc_amt"
                                        headerClassName={isColumnActive('doc_amt') ? 'active' : ''}
                                        title={<CustomCell title={renderSubTextTitle('Doc Amt', summary.doc_amt, true)} field="doc_amt" dataStateChange={dataStateChange} dataState={dataState} />}
                                        cell={(event) => (
                                            <td className="text-right" title={formatAccountsNumber(event.dataItem.document_amt)}>
                                                {formatAccountsNumber(event.dataItem.document_amt ?? 0)}
                                            </td>
                                        )}
                                        columnMenu={(props) => <ColumnMenu {...props} />}
                                        filter="numeric"
                                    />
                                    <Column
                                        width="100"
                                        field="taxable_amt"
                                        headerClassName={isColumnActive('taxable_amt') ? 'active' : ''}
                                        title={<CustomCell title={renderSubTextTitle('Taxable Val', summary.taxable_amt, true)} field="taxable_amt" dataStateChange={dataStateChange} dataState={dataState} />}
                                        cell={(event) => (
                                            <td className="text-right" title={formatAccountsNumber(event.dataItem.taxable_amt)}>
                                                {formatAccountsNumber(event.dataItem.taxable_amt ?? 0)}
                                            </td>
                                        )}
                                        columnMenu={(props) => <ColumnMenu {...props} />}
                                        filter="numeric"
                                    />
                                    <Column
                                        width="100"
                                        field="cgst_amt"
                                        headerClassName={isColumnActive('cgst_amt') ? 'active' : ''}
                                        title={<CustomCell title={renderSubTextTitle('CGST', summary.cgst_amt, true)} field="cgst_amt" dataStateChange={dataStateChange} dataState={dataState} />}
                                        cell={(event) => (
                                            <td className="text-right" title={formatAccountsNumber(event.dataItem.cgst_amt)}>
                                                {formatAccountsNumber(event.dataItem.cgst_amt ?? 0)}
                                            </td>
                                        )}
                                        columnMenu={(props) => <ColumnMenu {...props} />}
                                        filter="numeric"
                                    />
                                    <Column
                                        width="100"
                                        field="sgst_amt"
                                        headerClassName={isColumnActive('sgst_amt') ? 'active' : ''}
                                        title={<CustomCell title={renderSubTextTitle('SGST', summary.sgst_amt, true)} field="sgst_amt" dataStateChange={dataStateChange} dataState={dataState} />}
                                        cell={(event) => (
                                            <td className="text-right" title={formatAccountsNumber(event.dataItem.sgst_amt)}>
                                                {formatAccountsNumber(event.dataItem.sgst_amt ?? 0)}
                                            </td>
                                        )}
                                        columnMenu={(props) => <ColumnMenu {...props} />}
                                        filter="numeric"
                                    />
                                    <Column
                                        width="100"
                                        field="igst_amt"
                                        headerClassName={isColumnActive('igst_amt') ? 'active' : ''}
                                        title={<CustomCell title={renderSubTextTitle('IGST', summary.igst_amt, true)} field="igst_amt" dataStateChange={dataStateChange} dataState={dataState} />}
                                        cell={(event) => (
                                            <td className="text-right" title={formatAccountsNumber(event.dataItem.igst_amt)}>
                                                {formatAccountsNumber(event.dataItem.igst_amt ?? 0)}
                                            </td>
                                        )}
                                        columnMenu={(props) => <ColumnMenu {...props} />}
                                        filter="numeric"
                                    />
                                    <Column
                                        width="100"
                                        field="cess_amt"
                                        headerClassName={isColumnActive('cess_amt') ? 'active' : ''}
                                        title={<CustomCell title={renderSubTextTitle('Cess', summary.cess_amt, true)} field="cess_amt" dataStateChange={dataStateChange} dataState={dataState} />}
                                        cell={(event) => (
                                            <td className="text-right" title={formatAccountsNumber(event.dataItem.cess_amt)}>
                                                {formatAccountsNumber(event.dataItem.cess_amt ?? 0)}
                                            </td>
                                        )}
                                        columnMenu={(props) => <ColumnMenu {...props} />}
                                        filter="numeric"
                                    />
                                    <Column
                                        width="80"
                                        field="rcm"
                                        headerClassName={isColumnActive('rcm') ? 'active' : ''}
                                        title={<CustomCell title="RCM" field="rcm" dataStateChange={dataStateChange} dataState={dataState} />}
                                        cell={(event) => (
                                            <td className="text-center">
                                                {event.dataItem.rcm ?? '-'}
                                            </td>
                                        )}
                                        columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                        filter="text"
                                    />

                                    <Column
                                        width="80"
                                        field="itc_eligibility"
                                        headerClassName={isColumnActive('itc_eligibility') ? 'active' : ''}
                                        title={<CustomCell title="ITC Avail." field="itc_eligibility" dataStateChange={dataStateChange} dataState={dataState} />}
                                        cell={(event) => (
                                            <td className="text-center" title={event.dataItem.itc_eligibility}>
                                                {event.dataItem.itc_eligibility ?? '-'}
                                            </td>
                                        )}
                                        columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                        filter="text"
                                    />
                                </Column>
                                <Column title={<CustomCell
                                    title={matchStatus}
                                    className="inline_text_center"
                                    style={{ fontSize: '0.9rem', color: matchStatusColor }}
                                />}
                                    style={{ width: '100%' }}
                                >

                                    <Column
                                        width="100"
                                        title="Books Prd"
                                        sortable={false}
                                        headerClassName="cursor-default"
                                        cell={(event) => (
                                            <td className="text-center">
                                                {renderPeriodText('books', event.dataItem ?? "-")}
                                            </td>
                                        )}
                                    />
                                </Column>
                            </Grid>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>
    );
}
