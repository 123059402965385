import axios from 'axios';
import { handleSuccess, handleErrorWithCode } from './responseHandler';
import { getConfig } from './common';
import { BASE_URL_GO } from '../app/constants';


export async function multiLinkingModel(data) {
    const url = `${BASE_URL_GO}/api/v1/gst2a2b/reco/suppliers`;
    try {
        const result = await axios.post(url, data, getConfig());
        if (Number(result.data.status) !== 1) {
            throw (result.data.message);
        }
        return handleSuccess(result.data);
    } catch (err) {
        throw handleErrorWithCode(err);
    }
}

export async function BooksData(data) {
    if (!data.gst_number || !data.return_periods) return;
    const url = `${BASE_URL_GO}/api/v1/gst2a2b/reco/b2bcdn/books/list`;
    try {
        const result = await axios.post(url, data, getConfig());
        if (Number(result.data.status) !== 1) {
            throw (result.data.message);
        }
        return handleSuccess(result.data);
    } catch (err) {
        throw handleErrorWithCode(err);
    }
}
export async function GstrPortal(data) {
    const url = `${BASE_URL_GO}/api/v1/gst2a2b/reco/b2bcdn/portal/list`;
    try {
        const result = await axios.post(url, data, getConfig());
        if (Number(result.data.status) !== 1) {
            throw (result.data.message);
        }
        return handleSuccess(result.data);
    } catch (err) {
        throw handleErrorWithCode(err);
    }
}

export async function ImpgBooksData(data) {
    if (data.return_periods.length <= 0) return;
    const url = `${BASE_URL_GO}/api/v1/gst2a2b/reco/imp/books/list`;
    try {
        const result = await axios.post(url, data, getConfig());
        if (Number(result.data.status) !== 1) {
            throw (result.data.message);
        }
        return handleSuccess(result.data);
    } catch (err) {
        throw handleErrorWithCode(err);
    }
}

export async function ImpgGstrData(data) {
    if (data.return_periods.length <= 0) return;
    const url = `${BASE_URL_GO}/api/v1/gst2a2b/reco/imp/portal/list`;
    try {
        const result = await axios.post(url, data, getConfig());
        if (Number(result.data.status) !== 1) {
            throw (result.data.message);
        }
        return handleSuccess(result.data);
    } catch (err) {
        throw handleErrorWithCode(err);
    }
}

export async function linkB2b(data) {
    const url = `${BASE_URL_GO}/api/v1/gst2a2b/reco/b2bcdn/link`;
    try {
        const result = await axios.post(url, data, getConfig());
        if (Number(result.data.status) !== 1) {
            throw (result.data.message);
        }
        return result.data;
    } catch (err) {
        throw handleErrorWithCode(err);
    }
}

export async function linkImp(data) {
    const url = `${BASE_URL_GO}/api/v1/gst2a2b/reco/imp/link`;
    try {
        const result = await axios.post(url, data, getConfig());
        if (Number(result.data.status) !== 1) {
            throw (result.data.message);
        }
        return handleSuccess(result.data);
    } catch (err) {
        throw handleErrorWithCode(err);
    }
}

export async function unLinkB2b(data) {
    const url = `${BASE_URL_GO}/api/v1/gst2a2b/reco/b2bcdn/unlink`;
    try {
        const result = await axios.post(url, data, getConfig());
        if (Number(result.data.status) !== 1) {
            throw (result.data.message);
        }
        return handleSuccess(result.data);
    } catch (err) {
        throw handleErrorWithCode(err);
    }
}

export async function unLinkImp(data) {
    const url = `${BASE_URL_GO}/api/v1/gst2a2b/reco/imp/unlink`;
    try {
        const result = await axios.post(url, data, getConfig());
        if (Number(result.data.status) !== 1) {
            throw (result.data.message);
        }
        return handleSuccess(result.data);
    } catch (err) {
        throw handleErrorWithCode(err);
    }
}

export async function itsClaim(data) {
    const url = `${BASE_URL_GO}/api/v1/claim-itc/itc-claim-status`;
    try {
        const result = await axios.post(url, data, getConfig());
        if (Number(result.data.status) !== 1) {
            throw (result.data.message);
        }
        return handleSuccess(result.data);
    } catch (err) {
        throw handleErrorWithCode(err);
    }
}